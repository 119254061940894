import React from 'react';
import ReactQuill from 'react-quill';
import { Paperclip } from 'react-feather';
import './quillTextInput.css';
import 'react-quill/dist/quill.snow.css';
import ValidationUI from "../../ValidationUI";

const Quill = ReactQuill.Quill;
const Font = Quill.import('formats/font');
Font.whitelist = ['', 'Arial', 'Georgia', 'Helvetica', 'Tahoma', 'Times', 'Trebuchet', 'Verdana'];
Quill.register(Font, true);

// Custom SVG for the upload attachment button
const paperclipIcon = `
<svg width="16" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
</svg>`;
// Add the SVG to the Quill toolbar icons
const icons = ReactQuill.Quill.import('ui/icons');
icons['attachment'] = paperclipIcon;


/*
const sendIcon = `
<svg width="16" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <line x1="22" y1="2" x2="11" y2="13"></line>
    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
</svg>`;
icons['sendMessage'] = sendIcon;
 */

class MessageInput extends React.Component {
    constructor(props) {
        super(props);
        this.quillRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        //this.handleSend = this.handleSend.bind(this); // Bind handleSend
    }

    handleChange(html) {
        this.props.onChange(html);
    }

    fileHandle() {
        const input = document.querySelector('#fileInput');
        if (input) {
            input.click();
        }
    }

    /*
    handleSend() {
        if (this.props.handleSend) {
            this.props.handleSend(); // Call the handleSend function from props
        }
    }

     */

    static formats = [
        //'font', 'size',
        'bold', 'italic', 'underline',
        'color', 'background', 'list', 'bullet', 'link'
    ];

    render() {
        const { value, touched, error, path, required, t, readOnly, disabled, placeholder, onFileSelect, handleSend } = this.props;

        const modules = {
            toolbar: {
                container: [
                    //[{ 'font': Font.whitelist }],
                    //[{ size: [] }],
                    ['bold', 'italic', 'underline', { 'color': [] }, { 'background': [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'clean'],
                    [
                        'link',
                        {'attachment': {'class': 'ql-attachment'}},
                        //'sendMessage'
                    ],
                ],
                handlers: {
                    attachment: this.fileHandle,
                    //sendMessage: this.handleSend,
                }
            },
            clipboard: { matchVisual: false }
        };

        if (readOnly) {
            return (
                <div className="ql-container ql-snow ql-disabled" style={{ border: 'none' }}>
                    <div className="ql-editor" data-gramm="false" contentEditable="false">
                        <div dangerouslySetInnerHTML={{ __html: value }} />
                    </div>
                </div>
            );
        }

        return (
            <div className='quillContainer'>
                <input
                    type="file"
                    id="fileInput"
                    /*encType='multipart/form-data'*/
                    style={{ display: 'none' }}
                    multiple
                    onChange={onFileSelect}
                />
                <div style={{ padding: '0px' }}>
                    <ReactQuill
                        ref={this.quillRef}
                        theme={'snow'}
                        readOnly={disabled}
                        onChange={this.handleChange}
                        value={value || ""}
                        modules={modules}
                        formats={MessageInput.formats}
                        placeholder={placeholder}
                    />
                </div>
                {touched === 'true' && (error && <ValidationUI error={error} />)}
                <style jsx>{`
                    .quillContainer{
                    width: 100%;
                    height: 100%
                }
                    .ql-container, .ql-snow{
                    background-color: white;
                    flex: 1
                }

                    .ql-toolbar{
                    background-color: white !important;
                    height: 35px
                }

                    .ql-container {
                    height: auto !important;
                    min-height: 60px !important;
                    max-height: 60px !important;
                    border-bottom-left-radius: 0.5em;
                    border-bottom-right-radius: 0.5em;
                    border: 1px solid #ccc;
                    border-top: 0 !important;
                    overflow: hidden;
                    overflow-y: auto;
                }
                `}</style>
            </div>
        );
    }
}

export default MessageInput;