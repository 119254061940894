async function findData(context, callback) {

    const alertCollectionName = global.app.S.Alert.collectionName
    const habilitationCollectionName = global.app.S.Habilitation.collectionName
    const alertPromise = global.app.S.StaticWorkflow.collection.aggregate([
        {
            $match: {
                'step': { $nin: ['risk', 'noRisk'] }
            }
        },
        {
            $lookup: {
                from: habilitationCollectionName,
                    localField: 'habilitation',
                    foreignField: '_id',
                    as: 'hab'
            }
        },
        {
            $match: {
                'hab.user': new global.ObjectID(context.user.id)
            }
        },
        {
            $lookup: {
                from: alertCollectionName,
                    localField: 'alert',
                    foreignField: '_id',
                    as: 'alertDetails'
            }
        },
        {
            $match: {
                'alertDetails.workflow.step': { $nin: ['risk', 'noRisk'] }
            }
        },
        {
            $group: {
                _id: '$alert'
            }
        }
    ]).toArray()

    const [alerts] = await Promise.all([alertPromise])

    return [
        {
            id: "000000000000000000000001",
            data: alerts.length
        }
    ]
}

export const entity = {
    name: 'CurrentUserAlertsInProgress',
    dashboard: true,
    fields: ["id", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
