const _ = require('lodash')
const { basicContext } = require('../../../utils/contextUtils')
const { validateAge } = require('../utils')

async function findData(context) {
    const user = await global.app.R.CUser.get({kpUser: new global.ObjectID(context.user.id)},
        {
            fieldPath: ['rightHolders.id', 'rightHolders.kinship', 'rightHolders.dependent', 'status.id'],
            group: context.group
        })

    const exercise = await global.app.R.Exercise.collection.findOne({ exerciseStatus: 'ongoing' })

    if(!exercise) {
        return [
            {
                id: "000000000000000000000001",
                title: {
                    text: 'balanceByUserTitle',
                    data: {}
                },
                subTitle: {
                    text: 'balanceByUserSubtitle',
                },
                data: []
            }
        ]
    }

    const exerciseStartDate = exercise.startDate
    const exerciseEndDate = exercise.endDate
    const entryDate = user.entryDate
    const releaseDate = user.releaseDate

    if(releaseDate) {
        releaseDate.setDate( releaseDate.getDate() - 1)
    }

    const start = entryDate
        ? entryDate < exerciseStartDate ? exerciseStartDate : entryDate
        : exerciseStartDate

    const end = !releaseDate || user.status.id === 'retired'
        ? exerciseEndDate
        : releaseDate < exerciseEndDate
            ? releaseDate
            : exerciseEndDate

    const monthsDiff = (date1, date2) => {
        const years = date2.getFullYear() - date1.getFullYear()
        return (years * 12) + (date2.getMonth() - date1.getMonth() + 1) ;
    }

    const presenceRatio = (entryDate && entryDate > exerciseEndDate) || (releaseDate && releaseDate < exerciseStartDate)
        ? 0
        : monthsDiff(start, end) / monthsDiff(exerciseStartDate, exerciseEndDate)

    const userSplit = user['split'] || 0

    const limitForTheExerciseBeforeSplit = user.status.id === 'external'
        ? 0
        : user.rightHolders
            .filter(rh => rh.kinship.id === 'child' && rh.dependent)
            .filter( rh => validateAge(rh.birthDate, exercise.startDate, 25))
            .length
        * exercise.variableDot + exercise.fixedDot

    const getProratedValue = value => ((value * (100 - userSplit)) / 100) * presenceRatio

    const limitForTheExercise = getProratedValue(limitForTheExerciseBeforeSplit)

    const benefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        query: {exercise: new global.ObjectID(exercise._id)}

    })


    const refunds = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['refund', 'status'],
        query: {
            user: new global.ObjectID(user.id),
            benefit: {$in: benefits.map(o => new global.ObjectID(o.id))},
            status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] }

        }
    })

    const totalExerciseRefund = refunds
        .reduce((acc, refundRequest) => acc + parseFloat(refundRequest.refund), 0)
    const totalExercisePaidRefund = refunds
        .filter(refund => refund.status === 'paid')
        .reduce((acc, refundRequest) => acc + parseFloat(refundRequest.refund), 0)

    const balanceByBenefit = benefits.map(benefit => {
        const childrenNumber = user.rightHolders
            .filter(rh => rh.kinship.id === 'child' && rh.dependent)
            .filter( rh => validateAge(rh.birthDate, exercise.startDate, benefit.ageLimit))
            .length

        const fixedAndVariable = benefit.childrenPresenceCondition && !childrenNumber
            ?  0
            : (childrenNumber * benefit.limit.variable ) + benefit.limit.fixed

        const fixAndValueAfterProrating = getProratedValue(fixedAndVariable)

        const limitForTheBenefit = user.status.id === 'external'
            ? 0
            : benefit.limit.maximum && fixAndValueAfterProrating >= benefit.limit.maximum
                ? benefit.limit.maximum
                : fixAndValueAfterProrating
        const benefitRefundRequests = refunds.filter(refund => refund.benefit.toString() === benefit.id)

        const totalRefund = benefitRefundRequests
            .reduce((acc, refundRequest) => acc + parseFloat(refundRequest.refund), 0)
        const paidRefund = benefitRefundRequests
            .filter(refund => refund.status === 'paid')
            .reduce((acc, refundRequest) => acc + parseFloat(refundRequest.refund), 0)

        return {
            axis: benefit.name,
            refundLimit:  _.round(limitForTheBenefit, 2),
            postedRefund: _.round(totalRefund, 2),
            refundBalance: _.round(limitForTheBenefit - totalRefund, 2),
            paidRefund: _.round(paidRefund, 2)
        }
    })

    return [
        {
            id: "000000000000000000000001",
            title: {
                text: 'balanceByUserTitle',
                data: {
                    year: exercise.code
                }
            },
            subTitle: {
                text: 'balanceByUserSubtitle',
                data: {
                    max: _.round(limitForTheExercise, 2),
                    balance: _.round(limitForTheExercise - totalExerciseRefund, 2),
                    posted: _.round(totalExerciseRefund, 2),
                    paid: _.round(totalExercisePaidRefund, 2)
                }
            },
            data: balanceByBenefit
        }
    ]
}
export const entity = {
    name: 'BalanceByUser',
    dashboard: true,
    fields: ["id", "title", "subTitle", "data"],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
