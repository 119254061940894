import moment from 'moment'
import _ from 'lodash'
import {getHabilitations} from '../utils/habilitations'
import {basicContext} from "../../../utils/contextUtils"
import {groupIdForMongoQuery, sortObjectForMongoQuery} from "../engines/alert/mongoPipeline"

async function findData(context) {

    const [storeHabilitations, orgHabilitations] = await getHabilitations(context)
    const stores = storeHabilitations.map(hab => _.get(hab, 'store._id'))
    const organizations = orgHabilitations.map(hab => _.get(hab, 'organization._id'))

    const habQuery = stores.length || organizations.length
        ? {
            $or: [
                {store: {$in : stores}},
                {organizations: {$in: organizations}}
            ],
        }
        : {}
    const groupQuery = { group: new global.ObjectID(context.group.id) }

    const alertConfigurationId = _.get(context, 'clientContext.alertConfiguration')
    const groupAxisId = _.get(context, 'clientContext.groupAxis')
    const timeWindowId = _.get(context, 'clientContext.timeWindow')

    const generalSettings = await global.app.S.GeneralSettings.collection.findOne({group: global.ObjectID(context.group.id)})
    const alertConfiguration = await global.app.S.AlertConfiguration.get(
        alertConfigurationId,
        {
            ...basicContext(context),
            fieldPath: ['id', 'timeWindow.id', 'dashboardThemeKeysObjects', "groupAxes.sortObjectForMongo", "groupAxes.groupIdObjectForMongo",]
        }
    )
    const groupAxis = await global.app.S.GroupAxis.get(groupAxisId, {group: context.group, fieldPath: ['id', 'code']})
    const timeWindow = global.app.S.TimeWindow.objects.find(object => object.id === timeWindowId)

    const fiscalYearStartDate = `${generalSettings.monthNumber}-${generalSettings.month}`
    const fiscalYearStart = moment.utc(fiscalYearStartDate, 'DD-MM')


    const endDate = moment.utc().subtract(generalSettings.delay, 'days')
    const endDatePlusOne = moment.utc().subtract(generalSettings.delay, "days").add(1, "days")
    const startDate = generalSettings && timeWindow.id === '00000000000000000000000d'
        ? endDatePlusOne.isSameOrAfter(fiscalYearStart)
            ? fiscalYearStart
            : fiscalYearStart.subtract(1, 'years')
        : moment().subtract(generalSettings.delay, 'days').subtract(timeWindow.quantity, timeWindow.period)


    const dashboardDates = [startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY')]

    const datesQuery = {
        'date.gte': { $gte: startDate.toDate() },
        'date.lt': { $lte: endDatePlusOne.toDate() }
    }

    const alertCollection = global.app.S.Alert.collection
    const storeCollectionName = global.app.S.Store.collectionName

    const sumObject = alertConfiguration.dashboardThemeKeysObjects.reduce((acc, themeKeyObject) => {
        return {
            ...acc,
            [themeKeyObject.prefix]: {$sum: `$${themeKeyObject.path}`}
        }
    }, {})

    const projectSumObject = Object.keys(sumObject).reduce((acc, themeKey) => {
        return {
            ...acc,
            [themeKey]: 1
        }
    }, {})

    const storeIntermediaryPipeline = groupAxis.code === 'store'
        ? [
            {
                $lookup: {
                    from: storeCollectionName,
                    localField: '_id',
                    foreignField: '_id',
                    as: 'store'
                }
            },
            {
                $unwind: '$store'
            },
            {
                $project: {
                    _id: '$store.code',
                    number: 1,
                    ...projectSumObject
                }
            }
        ]
        : []

    const fiscalYearPipeline = ['00000000000000000000000d', '000000000000000000000002'].includes(alertConfiguration.timeWindow.id)
        ? [
            {
                $sort: {
                    ...sortObjectForMongoQuery(alertConfiguration.groupAxes),
                    alertCreationTime: -1
                }
            },
            {
                $group: {
                    _id: groupIdForMongoQuery(alertConfiguration.groupAxes),
                    firstElement: { $first: "$$ROOT" }
                }
            },
            {
                $replaceRoot: { newRoot: "$firstElement" }
            }
        ]
        : []

    const storeAlertQuery = alertCollection
        .aggregate([
            {
                $match: {
                    ...datesQuery,
                    alertConfiguration: new global.ObjectID(alertConfiguration.id),
                    ...habQuery,
                    ...groupQuery
                    //store: {$in: stores},
                    //'theme-Vente': {$exists: true}
                }
            },
            ...fiscalYearPipeline,
            {
                $group: {
                    _id: `$${groupAxis.code}`,
                    number: {$sum: 1},
                    ...sumObject
                }
            },
            {
                $sort: { number: -1 }
            },
            {
                $limit: 10
            },
            ...storeIntermediaryPipeline
        ])
        .toArray()

    const [storeAlerts] = await Promise.all([
        storeAlertQuery
    ])

    const totalAlertsNumber = storeAlerts.reduce((acc, alertsGroup) => acc + alertsGroup.number, 0)



    const data = storeAlerts.map( o => ({
        [groupAxis.code]: o._id,
        number: o.number,
        ...alertConfiguration.dashboardThemeKeysObjects.reduce( (acc, themeKeyObject) => {
            return {
                ...acc,
                [themeKeyObject.id]: o[themeKeyObject.prefix]
            }
        }, {})
    }))

    return [
        {
            id: _.get(context, 'clientContext.elementId'),
            total: totalAlertsNumber,
            subTitle: {
                text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                font: '11px',
                color: '#888383'
            },
            data: data.reverse()
        }
    ]
}

export const entity = {
    name: 'CustomDashboardElement',
    dashboard: true,
    fields: [
        "id",
        "total",
        "subTitle",
        "data",
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        });

    }
}
