const _ = require('lodash')

async function findData() {
    return [
        {
            id: '000000000000000000000006',
            link: 'Vote_CSE.mp4',
            poster: 'Vote_CSE.png'
        }
    ]
}
export const entity = {
    name: 'VotingVideo',
    dashboard: true,
    fields: ['id', 'link', 'poster'],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData()
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        })
    }
}
