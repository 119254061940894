const path = require("path");
const _ = require("lodash");
const {decrypt} = require("../../../utils/crypto");
const mediaDirPath = path.join(global.appRoot, global.isProd ? "buildServer/server/models/fncs/mails/media" : "src/server/models/fncs/mails/media");

const defaultMail = {
    from: '"myCSEManCo" <assistance@mycsemanco.com>',
    replyTo: "assistance@mycsemanco.com",
    verbose: {
        general: true,
    }
}

export const sendMail = function (token, user, isExternal, context, done) {
    const language = _.get(user, 'language.id') || user.language || 'fr'
    const link = global.isProd
        ? `https://${context.host}/reset/${token}`
        : `http://localhost:3000/reset/${token}`

    const manual = !isExternal
        ? [{path: mediaDirPath + '/2023-09-22_18_11_09_2023-09-22-CSE-Manuel Utilisateur.pdf'}]
        : []

    const mail = _.defaults({
        to: user.mail && decrypt(user.mail),
        context: {
            lastname : user.lastname,
            civility : context.tl(user.civility.id, language),
            link : link
        },
        subject: {template: `myCSEManCo : ${context.tl('passwordDefinition', language)}`},
        templateDir: path.join(
            global.appRoot,
            global.isProd
                ? 'buildServer/server/models/fncs/mails/templates/'
                : 'src/server/models/fncs/mails/templates',
            language
        ),
        content: isExternal && 'external'
            ? 'externalUserInitialization.html'
            : 'userInitialization.html',
        attachments: [
            ...manual,
            {
                path: mediaDirPath + '/myCSEManCo_Iconisation sur Android_20200608.pdf'
            },
            {
                path: mediaDirPath + '/myCSEManCo_Iconisation sur iOS_20200608.pdf'
            }
        ]
    }, defaultMail);
    global.mailer.sendMail(mail, error => done(error, 'emailSent'))
}
