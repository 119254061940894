import _ from "lodash";
import async from "async";
import Errors  from "../../utils/Errors"
import { setEditDefaultObject } from '../../../apps/KpModule/actions';
import { compose } from '../../utils/functional';
import {getDataListList, getEditDefaultData} from "../../../apps/KpModule/selectors";
import { change } from 'redux-form'
import {translateName} from "../../../utils/i18n";

function getAlertConfigurationFromDataList(rawObject, dataList) {
    if (rawObject && rawObject.id) return dataList.list.byId[rawObject.id];
}

/*
* Checks if array is positive and sequential
* - true for [1, 2, 3]
* - false for [1, 3] or [-1, 2] ...
* */
const positiveAndSequentialArray = arr => arr[0] === 1 && arr[arr.length-1] === arr.length;

/*
* FieldPath for listing habFunctions and have enough information to filter
* if it corresponds to an alertConfiguration
* */
const habFunctionFieldPath = [
    "id",
    "name",
    "organizationAxisJoin.id",
    "organizationAxisJoin.joinedEntity"
];

/*
* Generate one object of the shape HabFunctionConfig from a list of WorkflowConfig.
* WorkflowConfig exist only if a previous configuration was saved.
* */
const habFunctionConfigFromWorflowConfigs = workflowConfigsByHabFunction => habFunction => {
    const workflowConfigs = workflowConfigsByHabFunction[habFunction.id] || [];
    const configsByProfile = _.groupBy(workflowConfigs, "profile");
    const profileSelected = profile => !!configsByProfile[profile]

    return {
        id: habFunction.id,
        habFunction,
        manager_active: profileSelected("manager"),
        validator_active: profileSelected("validator"),
        validator_order: _.get(configsByProfile, "validator[0].order", 0),
        controller_active: profileSelected("controller")
    }
}

function groupConfigsByHabFunctions(habFunctions, workflowConfigs) {
    const generateConfig = habFunctionConfigFromWorflowConfigs(
        _.groupBy(workflowConfigs, "habFunction.id")
    );

    return habFunctions.map(generateConfig);
}

function getNewWorkflowConfigs({alertConfiguration, previousWorkflowConfigs, habFunctionConfigs}) {
    const workflowConfigsByHabFunction = _.groupBy(previousWorkflowConfigs, "habFunction.id")

    const transform = ({ habFunction, manager_active, validator_active, validator_order, controller_active }) => {
        const workflowConfigs = workflowConfigsByHabFunction[habFunction.id] || []
        const configsByProfile = _.groupBy(workflowConfigs, "profile");
        const baseWorkflowConfig = profile => {
            const previousConfig = _.get(configsByProfile, `${profile}[0]`, {})
            return {
                ...previousConfig,
                alertConfiguration,
                order: 0,
                habFunction,
                profile
            }
        };

        return _.compact([
            manager_active && baseWorkflowConfig("manager"),
            validator_active && {...baseWorkflowConfig("validator"), order: validator_order},
            controller_active && baseWorkflowConfig("controller")
        ])
    }

    return _.flatMap(
        habFunctionConfigs,
        transform
    )
}

/*
* Extract a string like "HabFunction1, HabFunction2 → HabFunction3"
* for a set of workflowConfigs containing the properties habFunction.name and order.
*  - the symbol ", " connects habFunctions with the same order
*  - the symbol " → " connects groups with different orders
*  - everything is in ascending order
* */

function getOrderedStringForWorkflowConfigs(workflowConfigs, context) {
    const workflowConfigsByOrder = _.groupBy(workflowConfigs, "order");

    const orders = _.orderBy(Object.keys(workflowConfigsByOrder));

    return orders.map(
        order =>
            _.map(
                _.get(workflowConfigsByOrder, order),
                "habFunction.name"
            ).map(name => {
                return context.translateName(name)
            }).join(", ")
    ).join(" → ")
}

/*
* Checks if the mesh of an habFunction corresponds to the alert configuration.
* habFunctions of type CentralAxis can act on all alerts
* habFunctions of type StoreAxis can only act on storeAxis and subStoreAxis alerts
* habFunctions of type OrganizationAxis can only act on store and subStoreAxis, and the corresponding groupAxis alerts
* */
const habFunctionCorresponds = alertConfiguration => habFunction => {
    if (!alertConfiguration) return false;

    switch (habFunction.organizationAxisJoin.joinedEntity) {
        case "CentralAxis":
            return true;
        case "StoreAxis":
            return alertConfiguration.groupAxes.some(
                groupAxis => _.includes(["StoreAxis", "SubStoreAxis"], groupAxis.joinedEntity)
            );
        case "OrganizationAxis":
            return alertConfiguration.groupAxes.some(
                groupAxis => _.includes(["StoreAxis", "SubStoreAxis"], groupAxis.joinedEntity) || groupAxis.id === habFunction.organizationAxisJoin.id
            );
        default:
            return false;
    }
}

const validateHabFunctionConfigs = (habFunctionConfigs, context) => {
    // validate that those profiles are active
    const nonExistingProfiles = ["manager", "validator"]
        .map(profile => ({profile, exist: habFunctionConfigs.some(config => config[`${profile}_active`])}))
        .filter(profile => !profile.exist)

    if(nonExistingProfiles.length) {
        return new Errors.ValidationError(context.tc("profileIsMandatory", {profileName: context.t(nonExistingProfiles[0].profile)}));
    }

    // check that orders for validator profile make sense
    const orders = _(habFunctionConfigs)
        .filter("validator_active")
        .map("validator_order")
        .uniq()
        .orderBy()
        .value();

    if (!positiveAndSequentialArray(orders)) {
        return new Errors.ValidationError(context.tc("profileOrderSequential", {profileName: context.t("validator")}));
    }
}

/*
* WorkflowConfigsByAlert is an Entity containing workflowConfigurations for a given AlertConfiguration.
* There is a known issue coming from the ORM abstraction, in the save getter. We cannot get the previous
* workflowConfigs, so each time the entity is saved it removes previous linked configurations and creates new ones.
* The behaviour is indeed coherent if no other link to WorkflowConfig is persisted.
* */
const WorkflowConfigsByAlert = {
    name: "WorkflowConfigsByAlert",
    fields: [
        {
            type:"AlertConfiguration",
            unique: true,
            notEmpty: true
        },
        {
            path: "reason",
            type: "Reason",
            link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}},
            notEmpty: true
        },
        {path: "dormant", type: "boolean"},
        {
            type: "HabFunctionConfig",
            lazy: true,
            fieldPath: [
                "workflowConfigs.id",
                "workflowConfigs.alertConfiguration.id",
                "workflowConfigs.habFunction.id"
            ],
            list: true,
            $f: (workflowConfigsByAlert, context, callback) => {
                global.app.S.HabFunction.find(
                    {
                        group: context.group,
                        fieldPath: habFunctionFieldPath
                    },
                    (error, habFunctions) => {
                        if (error) return callback(error);
                        callback(
                            null,
                            groupConfigsByHabFunctions(habFunctions, workflowConfigsByAlert.workflowConfigs)
                        );
                    }
                )
            },
            $s: (habFunctionConfigs, workflowConfigsByAlert, context, callback) => {
                workflowConfigsByAlert.workflowConfigs = getNewWorkflowConfigs({
                    alertConfiguration: workflowConfigsByAlert.alertConfiguration,
                    previousWorkflowConfigs: workflowConfigsByAlert.workflowConfigs || [],
                    habFunctionConfigs
                })

                callback();
            }
        },
        {
            path: "workflowConfigs",
            type: "WorkflowConfig",
            link: {
                type: "OTM",
                onParent: true,
                onChild: false,
            }
        },
        {
            path: 'habFunctionNamesByProfile',
            lazy: true,
            fieldPath: [
                "workflowConfigs.id",
                "workflowConfigs.habFunction.id",
                "workflowConfigs.habFunction.name"
            ],
            $f: function(workflowConfig, context, callback) {
                return callback(
                    null,
                    _(workflowConfig.workflowConfigs)
                        .groupBy("profile")
                        .mapValues(workflowConfigs => getOrderedStringForWorkflowConfigs(workflowConfigs, context))
                        .value()
                )
            }
        },
        {
            path: 'managerHabFunctions',
            lazy: true,
            fieldPath: ['habFunctionNamesByProfile'],
            f: function() {
                return this.habFunctionNamesByProfile["manager"];
            }
        },
        {
            path: 'validatorHabFunctions',
            lazy: true,
            fieldPath: ['habFunctionNamesByProfile'],
            f: function() {
                return this.habFunctionNamesByProfile["validator"];
            }
        },
        {
            path: 'controllerHabFunctions',
            lazy: true,
            fieldPath: ['habFunctionNamesByProfile'],
            f: function() {
                return this.habFunctionNamesByProfile["controller"];
            }
        }
    ],
    validateDelete: function (object, context, callback) {
        async.parallel([
            callback =>  global.app.S.StaticWorkflow.collection.deleteMany({
                alertConfiguration: object.alertConfiguration,
                group: global.ObjectID(context.group.id)
            }, callback),
            callback =>  global.app.S.StatusUser.collection.deleteMany({
                alertConfiguration: object.alertConfiguration,
                group: global.ObjectID(context.group.id)
            }, callback),
            callback =>  global.app.S.Alert.collection.updateMany(
                {
                    alertConfiguration: object.alertConfiguration,
                    group: global.ObjectID(context.group.id)
                },
                { $unset: {workflow: ''} },
                callback
            ),
        ], e => {
            if(e) return callback(e)
            return callback(null, object)
        })
    },
    validateSave: (object, oldObject, context, callback) => callback(
        validateHabFunctionConfigs(object.habFunctionConfigs, context)
    ),
    afterSave: async (object, oldObject, context, callback) => {
        const automaticReason = await global.app.S.Reason.collection.findOne({
            group: new global.ObjectID(context.group.id),
            automatic: true
        })

        try {
            await global.app.S.WorkflowConfig.collection.updateMany(
                {_id: {$in : object.workflowConfigs.map(workflowConfig => global.ObjectID(workflowConfig.id))}},
                {$set: {dormant: object.dormant}},
            )

            await global.app.S.AlertConfiguration.collection.updateOne(
                {_id: global.ObjectID(object.alertConfiguration.id)},
                {
                    $set: {
                        reason: automaticReason
                            ? [automaticReason._id, ...object.reason.map(reason => global.ObjectID(reason.id))]
                            : object.reason.map(reason => global.ObjectID(reason.id))
                    }
                }
            )
        } catch (e) {
            callback(e)
        } finally {
            callback()
        }
    }
};

const HabFunctionConfig = {
    name: "HabFunctionConfig",
    type: "mongoInternal",
    fields: [
        "HabFunction",
        {path: "manager_active", type: "boolean"},
        {path: "validator_active", type: "boolean"},
        {path: "validator_order", type: "integer"},
        {path: "controller_active", type: "boolean"}
    ]
};

const WorkflowConfig = {
    name: "WorkflowConfig",
    fields: [
        'profile',
        { path: 'order', type: 'integer' },
        'AlertConfiguration',
        'HabFunction',
        { path: 'dormant', type: 'boolean', nullable: true}
    ]
};

const WorkflowConfigModule = {
    object: "WorkflowConfigsByAlert",
    tKey: "mTitle_workflowConfig",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: "alertConfiguration", display: "tName"},
            {path: "managerHabFunctions", tKey: "manager"},
            {path: "validatorHabFunctions", tKey: "validator"},
            {path: "controllerHabFunctions", tKey: "controller"},
            {path: "dormant", tKey: "suspended"}
        ],
        form: {
            fields: [
                {
                    path: "alertConfiguration",
                    display: "tName",
                    fieldPath: ["id", "tName", "groupAxes.id", "groupAxes.joinedEntity"],
                    editable: false,
                    //filters: ["hasStoreAxis"]
                },
                {path: "reason", display: 'name', translateName: true, filters: ["manual"]},
                {
                    path: "habFunctionConfigs",
                    type: "dtObjects",
                    applyBoard: true,
                    tKey: "workflow",
                    fields: [
                        {path: "habFunction", tKey: "workflowConfiguration", translateName: true},
                        {path: "manager_active", tKey: "manager", type: "checkbox"},
                        {path: "validator_active", tKey: "validator", type: "checkbox"},
                        {path: "validator_order", tKey: "validationOrder", type: "editText"},
                        {path: "controller_active", tKey: "controller", type: "checkbox"}
                    ]
                },
                {path: "dormant", tKey: "suspend", default: false},
            ],
            dataLists: ["workflowConfigsByAlert-habFunction"],
            onLoad: ({ store }) => {
                const state = store.getState();
                const habFunctions = getDataListList(
                    state,
                    'workflowConfigsByAlert-habFunction'
                );


                const habFunctionConfigs = habFunctions.map(
                    habFunctionConfigFromWorflowConfigs({})
                );

                const defaultData = getEditDefaultData(state)

                store.dispatch(
                    setEditDefaultObject({
                        ...defaultData,
                        habFunctionConfigs
                    })
                );
            }
        }
    },
    stateSubscriptions: [{
        statePath: 'form.editObject.values.alertConfiguration',
        subscription: (newValue, previousValue, { store, formInitialize, formDestroy }) => {
            // we are not interested in the changes made by redux-form library
            if (!formInitialize && !formDestroy) {
                try {
                    const state = store.getState();

                    // habFunctionConfigs are all possible habFunctionConfigs
                    const habFunctionConfigs = _.get(state, "edit.defaultObject.habFunctionConfigs");

                    // recover a full alertConfiguration, because the newValue contains only id
                    const alertConfiguration = getAlertConfigurationFromDataList(
                        newValue,
                        state.dataLists.byId["m-S-workflowConfigsByAlert.WorkflowConfigsByAlert_alertConfiguration"]
                    )

                    // function to test if habFunctionConfig corresponds to the alertConfiguration
                    const correspondsToAlertConfiguration = compose([
                        habFunctionCorresponds(alertConfiguration),
                        _.partialRight(_.get, "habFunction")
                    ])

                    const filteredObjects = habFunctionConfigs.filter(
                        correspondsToAlertConfiguration
                    );

                    // old objects to recover information like checks
                    const oldObjects = _.get(state, "form.editObject.values.habFunctionConfigs", []);
                    const oldObjectsMap = oldObjects.reduce(
                        (acc, object) => ({
                            [_.get(object, "habFunction.id")]: object,
                            ...acc
                        }),
                        {}
                    );

                    // recover previous objects if some were already filled
                    const newObjects = filteredObjects.map(
                        alertObject => oldObjectsMap[alertObject.habFunction.id] || alertObject
                    );

                    store.dispatch(change('editObject', 'habFunctionConfigs', newObjects))

                } catch (error) {
                    console.log("error executing slp workflow subscription");
                    console.warn(error);
                }
            }
        }
    }],
    accesses: [
        {
            id: "workflowConfigsByAlert-habFunction",
            entity: "HabFunction",
            fieldPath: habFunctionFieldPath,
            filters: []
        }
    ]
};

export const entities = [
    WorkflowConfigsByAlert,
    HabFunctionConfig,
    WorkflowConfig
];

export const module_ = WorkflowConfigModule;
