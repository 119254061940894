export const entity = {
    name: "DashboardElement",
    type: "static",
    fields: [
        {path: "id", type: "string"},
        "name",
        "type",
        "url",
        "suffix",
        "link",
        "keys",
        "colors",
        "width",
        "sum",
        "sumColor",
        "absolute"
    ],
    objects: []
}
