import {toastr} from 'react-redux-toastr'
import viewerNormalizer from '../../../utils/viewerNormalizer'
import { translateName } from '../../../utils/i18n'
import {CALL_API, CALL_APIS_PARALLEL} from '../../../middleware/api'
import { VIEWER_INVALIDATE } from '../reducers/network'
import {getGroupModels, getAlerts, getUserData, getGroups} from '../selectors'
import history from '../../../history'
import _ from 'lodash'
import {getLanguage} from "../selectors";

export const invalidateViewer = () => ({
    type: VIEWER_INVALIDATE
})
export const SET_UI_DEVICE = 'SET_UI_DEVICE'

export const setUiDevice = device => ({
    type: 'SET_UI_DEVICE',
    device
})

export const SET_SIDEBAR_DOCKED = 'SET_SIDEBAR_DOCKED'

export const setSidebarDocked = value => ({
    type: SET_SIDEBAR_DOCKED,
    value
})

export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN'

export const setSidebarOpen = value => ({
    type: SET_SIDEBAR_OPEN,
    value
})

export const SET_CATEGORY_COLLAPSE = 'SET_CATEGORY_COLLAPSE'

export const setCategoryCollapse = (category, collapse) => ({
    type: SET_CATEGORY_COLLAPSE,
    category,
    collapse
})

export const SET_JOB_OPENED = 'SET_JOB_OPENED'

export const setJobOpened = value => ({
    type: SET_JOB_OPENED,
    value
})
export const SET_DOCS_OPENED = 'SET_DOCS_OPENED'

export const setDocsOpened = value => ({
    type: SET_DOCS_OPENED,
    value
})

export const SET_PROFILE_OPENED = 'SET_PROFILE_OPENED'

export const setProfileOpened = value => ({
    type: SET_PROFILE_OPENED,
    value
})

export const SET_LANGUAGES_OPENED = 'SET_LANGUAGES_OPENED'

export const setLanguagesOpened = value => ({
    type: 'SET_LANGUAGES_OPENED',
    value
})

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const setLanguage = language => ({
    type: SET_LANGUAGE,
    language
})

export const SET_THEME = 'SET_THEME'

export const setTheme = theme => ({
    type: SET_THEME,
    theme
})

export const SET_NOT_FOUND_PAGE = 'SET_NOT_FOUND_PAGE'

export const setNotFoundPage = value => ({
    type: SET_NOT_FOUND_PAGE,
    value
})

export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN'

export const redirectToLogin = () => ({
    type: REDIRECT_TO_LOGIN,
    meta: { unauthorized: true }
})

export const BEFORE_REDIRECTION = 'BEFORE_REDIRECTION'

export const beforeRedirection = path => ({
    type: BEFORE_REDIRECTION,
    path
})

export const CONFIG_REQUEST = 'CONFIG_REQUEST'
export const CONFIG_SUCCESS = 'CONFIG_SUCCESS'
export const CONFIG_FAILURE = 'CONFIG_FAILURE'

export const fetchConfig = () => ({
    [CALL_API]: {
        types: [CONFIG_REQUEST, CONFIG_SUCCESS, CONFIG_FAILURE],
        method: 'GET',
        url: `/clientConfig`,
    }
})

export const PLATFORM_ALERTS_REQUEST = 'PLATFORM_ALERTS_REQUEST'
export const PLATFORM_ALERTS_SUCCESS = 'PLATFORM_ALERTS_SUCCESS'
export const PLATFORM_ALERTS_FAILURE = 'PLATFORM_ALERTS_FAILURE'

export const fetchPlatformAlerts = (modelId, profiles) => (dispatch, getState) => {
    dispatch({
        [CALL_API]: {
            types: [PLATFORM_ALERTS_REQUEST, PLATFORM_ALERTS_SUCCESS, PLATFORM_ALERTS_FAILURE],
            method: 'GET',
            url: `/platformAlerts`,
            params: {modelId, profiles}
        }
    }).then(
        () => {
            const state = getState()
            const language = getLanguage(state)
            const alerts = getAlerts(state)

            alerts.forEach(alert => {
                if(alert.type === 'custom') {
                    toastr.info(
                        translateName(alert.message, language),
                        {
                            timeOut: 1000 * alert.length,
                            className: 'black-toastr-style'
                        }
                    )
                } else {
                    toastr[alert.type](
                        translateName(alert.message, language),
                        {
                            timeOut: 1000 * alert.length
                        }
                    )
                }
            })
        }
    )
}

export const PLATFORM_DOCUMENTS_REQUEST = 'PLATFORM_DOCUMENTS_REQUEST'
export const PLATFORM_DOCUMENTS_SUCCESS = 'PLATFORM_DOCUMENTS_SUCCESS'
export const PLATFORM_DOCUMENTS_FAILURE = 'PLATFORM_DOCUMENTS_FAILURE'

export const fetchPlatformDocuments = (modelId, profiles, language) => ({
    [CALL_API]: {
        types: [PLATFORM_DOCUMENTS_REQUEST, PLATFORM_DOCUMENTS_SUCCESS, PLATFORM_DOCUMENTS_FAILURE],
        method: 'GET',
        url: `/platformDocuments`,
        params: {modelId, profiles, language}
    }
})

export const PLATFORM_MEDIA_REQUEST = 'PLATFORM_MEDIA_REQUEST'
export const PLATFORM_MEDIA_SUCCESS = 'PLATFORM_MEDIA_SUCCESS'
export const PLATFORM_MEDIA_FAILURE = 'PLATFORM_MEDIA_FAILURE'

export const fetchPlatformMediaElements = (modelId, groupId, profiles) => ({
    [CALL_API]: {
        types: [PLATFORM_MEDIA_REQUEST, PLATFORM_MEDIA_SUCCESS, PLATFORM_MEDIA_FAILURE],
        method: 'GET',
        url: `/platformMedia`,
        params: {modelId, profiles, groupId}
    }
})

export const VIEWER_REQUEST = 'VIEWER_REQUEST'
export const VIEWER_SUCCESS = 'VIEWER_SUCCESS'
export const VIEWER_FAILURE = 'VIEWER_FAILURE'

export const fetchViewer = () => (dispatch, getState) => {
    dispatch({
        [CALL_API]: {
            types: [VIEWER_REQUEST, VIEWER_SUCCESS, VIEWER_FAILURE],
            method: 'GET',
            url: `/viewer`,
            transpose: viewerNormalizer
        }
    }).then(
        () => {
            const state = getState()
            const user = getUserData(state)
            const groupModels = getGroupModels(state)
            const groups = getGroups(state)
            const groupModelId = _.head(Object.keys(groupModels))
            const modelId = groupModels[groupModelId].model

            if (groupModelId && (history.location.pathname === '/business')) {
                history.push(`/business/${groupModelId}`)
                const groupModel = groupModels[groupModelId]
                const group = groups[groupModel.group]

                dispatch(setTheme(
                    {
                        ..._.pick(groupModel, ['backgroundColor', 'menuButtonsColor', 'selectedColor', 'kpLogo']),
                        logo: group.logo
                    }
                ))

                const profiles = groupModel.profiles
                dispatch(fetchPlatformDocuments(modelId, profiles, _.get(user, 'language.id') || 'fr'))
                dispatch(fetchPlatformAlerts(modelId, profiles))
            }

            const pathname = history.location.pathname

            if(pathname.startsWith('/business/')) {
                const gmId = pathname.split('/')[2]
                if(groupModels[gmId]) {
                    const groupModel = groupModels[gmId]
                    const group = groups[groupModel.group]

                    dispatch(setTheme({
                        ..._.pick(groupModel, ['backgroundColor', 'menuButtonsColor', 'selectedColor', 'kpLogo']),
                        logo: group.logo
                    }))

                    const modelId = groupModel.model
                    const profiles = groupModel.profiles
                    dispatch(fetchPlatformDocuments(modelId, profiles, _.get(user, 'language.id') || 'fr'))
                    dispatch(fetchPlatformAlerts(modelId, profiles))
                }
            }
        }
    ).catch(
        error => console.log('fetch viewer error', error)
    )
}

export const DASHBOARD_DATA_REQUEST = 'DASHBOARD_DATA_REQUEST'
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS'
export const DASHBOARD_DATA_FAILURE = 'DASHBOARD_DATA_FAILURE'


export const fetchDashboardData = (dashboardElements, groupModelId, modelId) => (dispatch, getState) => {
    const state = getState()

    const actions = dashboardElements
        .map( dashboardElement => {
            const url = dashboardElement.url
            const dashboardElementsParams = dashboardElement.params || {}
            return {
                id: dashboardElement._id,
                accessId: `m-${modelId}-${url}-e`,
                [CALL_API]: {
                    types: [
                        DASHBOARD_DATA_REQUEST,
                        DASHBOARD_DATA_SUCCESS,
                        DASHBOARD_DATA_FAILURE
                    ],
                    method: 'GET',
                    url: `/api/${modelId.toLowerCase()}.${url.toLowerCase()}`,
                    params: {
                        accessId: `m-${modelId}-${url}-e`,
                        groupModelId,
                        language: getLanguage(state),
                        ...dashboardElementsParams
                    }
                }
            }
        })
    return dispatch({[CALL_APIS_PARALLEL]: actions})
}

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const login = user => (dispatch, getState) => {
    const state = getState()

    return dispatch({
        [CALL_API]: {
            types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
            method: 'POST',
            url: `/login`,
            params: {
                ...user,
                language: getLanguage(state)
            }
        }
    })
}

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE'

export const register = data => dispatch => {

    return dispatch({
        [CALL_API]: {
            types: [REGISTRATION_REQUEST, REGISTRATION_SUCCESS, REGISTRATION_FAILURE],
            method: 'POST',
            url: `/userRegistration`,
            params: data
        }
    })
}

export const LOGIN_CONFIRMATION_REQUEST = 'LOGIN_CONFIRMATION_REQUEST'
export const LOGIN_CONFIRMATION_SUCCESS = 'LOGIN_CONFIRMATION_SUCCESS'
export const LOGIN_CONFIRMATION_FAILURE = 'LOGIN_CONFIRMATION_FAILURE'

export const handleTwoFactorAuth = (mail, token) => (dispatch, getState) => {
    const state = getState()

    return dispatch({
        [CALL_API]: {
            types: [LOGIN_CONFIRMATION_REQUEST, LOGIN_CONFIRMATION_SUCCESS, LOGIN_CONFIRMATION_FAILURE],
            method: 'POST',
            url: '/loginConfirmation',
            params: {
                mail,
                token,
                browserId: localStorage.getItem('browserId'),
                language: getLanguage(state)
            }
        }
    })
}

export const LOGIN_CONFIRMATION_RESET_REQUEST = 'LOGIN_CONFIRMATION_RESET_REQUEST'
export const LOGIN_CONFIRMATION_RESET_SUCCESS = 'LOGIN_CONFIRMATION_RESET_SUCCESS'
export const LOGIN_CONFIRMATION_RESET_FAILURE = 'LOGIN_CONFIRMATION_RESET_FAILURE'

export const handleTwoFactorAuthReset = mail => (dispatch, getState) => {
    const state = getState()

    return dispatch({
        [CALL_API]: {
            types: [LOGIN_CONFIRMATION_RESET_REQUEST, LOGIN_CONFIRMATION_RESET_SUCCESS, LOGIN_CONFIRMATION_RESET_FAILURE],
            method: 'POST',
            url: `/loginConfirmationReset`,
            params: {
                mail,
                language: getLanguage(state)
            }
        }
    })
}

export const RESET_GET_REQUEST = 'RESET_GET_REQUEST'
export const RESET_GET_SUCCESS = 'RESET_GET_SUCCESS'
export const RESET_GET_FAILURE = 'RESET_GET_FAILURE'

export const resetGet = token => ({
    [CALL_API]: {
        types: [RESET_GET_REQUEST, RESET_GET_SUCCESS, RESET_GET_FAILURE],
        method: 'GET',
        url: `/passReset/${token}`
    }
})

export const RESET_POST_REQUEST = 'RESET_GET_REQUEST'
export const RESET_POST_SUCCESS = 'RESET_GET_SUCCESS'
export const RESET_POST_FAILURE = 'RESET_GET_FAILURE'

export const resetPost = (token, user) => ({
    [CALL_API]: {
        types: [RESET_POST_REQUEST, RESET_POST_SUCCESS, RESET_POST_FAILURE],
        method: 'POST',
        url: `/passReset/${token}`,
        params: user
    }
})

export const SEND_MAIL_REQUEST = 'SEND_MAIL_REQUEST'
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS'
export const SEND_MAIL_FAILURE = 'SEND_MAIL_FAILURE'

export const sendMail = (user, language) => ({
    [CALL_API]: {
        types: [SEND_MAIL_REQUEST, SEND_MAIL_SUCCESS, SEND_MAIL_FAILURE],
        method: 'POST',
        url: `/requestReset`,
        params: {user, language}
    }
})

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const logout = () => ({
    [CALL_API]: {
        types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
        method: 'GET',
        url: `/logout`
    }
})

export const logoutAndRedirect = () => dispatch => {
    return dispatch(logout()).then(() => dispatch(redirectToLogin()))
}
