import moment from 'moment'
import _ from 'lodash'
import {getHabilitations} from '../../utils/habilitations'

async function findData(context) {

    const [storeHabilitations, orgHabilitations] = await getHabilitations(context)
    const stores = storeHabilitations.map(hab => _.get(hab, 'store._id'))
    const organizations = orgHabilitations.map(hab => _.get(hab, 'organization._id'))

    const habQuery = stores.length || organizations.length
        ? {
            $or: [
                {store: {$in : stores}},
                {organizations: {$in: organizations}}
            ],
        }
        : {}
    const groupQuery = { group: new global.ObjectID(context.group.id) }

    const endDate = moment().subtract(2, 'days').format('DD-MM-YYYY')
    const endDatePlusOne = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const startDate = moment().subtract(1, 'days').subtract(3, 'months').format('DD-MM-YYYY')

    const dashboardDates = [startDate, endDate]
    const dates = [startDate, endDatePlusOne].map(date =>
        moment.utc(date.trim(), 'DD-MM-YYYY')
    )

    const datesQuery = {
        'date.gte': { $gte: dates[0].toDate() },
        'date.lt': { $lte: dates[1].toDate() }
    }

    const alertConfiguration = await global.app.S.AlertConfiguration.collection.findOne({code: 'hrzit_DA_storeNetDiscountLine100'})

    const alertCollection = global.app.S.Alert.collection
    const storeCollectionName = global.app.S.Store.collectionName
    const storeAlertQuery = alertCollection
        .aggregate([
            {
                $match: {
                    ...datesQuery,
                    alertConfiguration: alertConfiguration._id,
                    ...habQuery,
                    ...groupQuery
                    //store: {$in: stores},
                    //'theme-Vente': {$exists: true}
                }
            },
            {
                $group: {
                    _id: '$store',
                    number: {$sum: 1}
                }
            },
            {
                $sort: { number: -1 }
            },
            {
                $lookup: {
                    from: storeCollectionName,
                    localField: '_id',
                    foreignField: '_id',
                    as: 'store'
                }
            },
            {
                $unwind: '$store'
            },
        ])
        .toArray()

    const [storeAlerts] = await Promise.all([
        storeAlertQuery
    ])

    const totalAlertsNumber = storeAlerts.reduce((acc, alertsGroup) => acc + alertsGroup.number, 0)

    const top10StoreSales = _.slice(storeAlerts, 0, 10)


    const data = top10StoreSales.map( o => ({
        store: _.get(o, 'store.code'),
        number: o.number,
    }))

    return [
        {
            id: '000000000000000000000003',
            total: totalAlertsNumber,
            subTitle: {
                text: `${dashboardDates[0]} au ${dashboardDates[1]}`,
                font: '11px',
                color: '#888383'
            },
            data: data.reverse()
        }
    ]
}

export const entity = {
    name: 'TopStore100Disc',
    dashboard: true,
    fields: [
        "id",
        "total",
        "subTitle",
        "data",
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else
                findData(context)
                    .then(objects => callback(null, objects))
                    .catch(error => callback(error))
        });

    }
}
