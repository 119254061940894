import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ValidationUI from './ValidationUI'
import { Table, AutoSizer, Column } from './DataTable'
import _ from 'lodash'
import {Edit3} from 'react-feather'
import './Comment.css'
import { defaultT } from '../utils/i18n'
import {SortDirection} from 'react-virtualized/dist/commonjs/Table'
import { ObjectID } from 'bson'
import moment from 'moment'
import HoverPopover from './HoverPopover'
import {defaultTableRowRenderer} from 'react-virtualized';

const sort = (ids, getValueForId, sortDirection) =>
    _.orderBy(
        ids,
        getValueForId,
        sortDirection === SortDirection.ASC ? 'asc' : 'desc'
    )

class Comment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            sortBy: 'date',
            sortDirection: 'DESC'
        }
    }

    onChange(event) {
        this.setState({
            value: event.target.value
        })
    }

    handleCommentClick = (object) => {
        const displayRow = defaultTableRowRenderer(object)
        const message = <div>
                <p>{object.rowData.text}</p>
                <strong>{object.rowData.user?.name}</strong>
                <br />
                <strong>{object.rowData.date}</strong>
        </div>
        return (
            <HoverPopover
                key={object.rowData.id}
                id={'comment-popover'}
                message={message}
                placement={'top'}
            >
                {displayRow}
            </HoverPopover>
        )
    }

    render() {
        const {
            allIds = [], byId = {}, push, t = defaultT, user, editable, disabled, textWith,
            placeholder, noRowsMessage, tKeyText, tKeyUser, tKeyDate, validationMessage
        } = this.props

        // const objects = allIds.map(id => byId[id])

        const sortedIds = this.state.sortBy
            ? sort(allIds, id => byId[id][this.state.sortBy], this.state.sortDirection)
            : allIds

        const sortedObjects = sortedIds && sortedIds.map(id => byId[id])

        return (
            <div className="Comment-container">
                <div className="Comment-panel-body">
                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <Table
                                objects={sortedObjects}
                                sort={({ sortBy, sortDirection }) => {
                                    this.setState({
                                        sortBy,
                                        sortDirection
                                    })
                                }}
                                headerRowClassName="AccordionHeaderRow"
                                headerClassName="AccordionHeader"
                                rowClassName="AccordionRow"
                                sortBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                maxRows={7} width={width}
                                noRowsLabel={t(noRowsMessage || 'noRows')}
                                rowRenderer={this.handleCommentClick}
                            >
                                <Column
                                    flexGrow={1}
                                    width={textWith || 200}
                                    dataKey="text"
                                    label={t(tKeyText || 'comment', { capitalize: true })}
                                    cellDataGetter={({ dataKey, rowData }) =>
                                        rowData.automatic ? t(_.get(rowData, dataKey)) : _.get(rowData, dataKey)}
                                    className='DataTable-Table-row-column'
                                />
                                <Column
                                    flexGrow={1}
                                    width={200}
                                    dataKey="user.name"
                                    label={t(tKeyUser || 'user', { capitalize: true })}
                                    cellDataGetter={({ dataKey, rowData }) =>
                                        _.get(rowData, dataKey)}
                                    className='DataTable-Table-row-column'
                                />
                                <Column
                                    flexGrow={1}
                                    width={200}
                                    dataKey="date"
                                    label={t(tKeyDate || 'date', { capitalize: true })}
                                    className='DataTable-Table-row-column'
                                />
                            </Table>
                        )}
                    </AutoSizer>
                </div>
                {
                    editable && !disabled && <div className="Comment-comment">
                    <textarea
                        rows={2}
                        value={this.state.value}
                        placeholder={t(placeholder || 'writeAComment')}
                        onChange={event => {
                            this.onChange(event)
                        }}
                        className="form-control Comment-textarea remove-button-outline"
                    />
                        <button
                            className={
                                this.state.value === ''
                                    ? 'btn btn-default Comment-button'
                                    : 'btn btn-default Comment-button Comment-focus'
                            }
                            type="button"
                            disabled={this.state.value === ''}
                            onClick={() => {
                                const OID = new ObjectID()
                                push({
                                    id: OID.toString(),
                                    text: this.state.value,
                                    user,
                                    date: moment().format('YYYY-MM-DD HH:mm:ss')
                                })
                                this.setState({ value: '' })
                            }}
                        >
                            <Edit3 size={17} />
                        </button>
                    </div>
                }
                { this.state.value && <ValidationUI error={t(validationMessage || 'commentValidation')}/> }
            </div>
        )
    }
}

export default Comment

Comment.propTypes = {
    push: PropTypes.func.isRequired,
    t: PropTypes.func
}
