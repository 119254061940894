import {decrypt} from "../../../utils/crypto";

const path = require("path");
const _ = require("lodash");

const defaultMail = {
    from: '"myCSEManCo" <assistance@mycsemanco.com>',
    replyTo: "assistance@mycsemanco.com",
    content: 'userRefund.html',
    verbose: {
        general: true,
    }
}

const sendMail = function(users, orderLines, context) {

    const logMessage = () => {
        console.log('...Mailing')
    }
    _.uniqBy(users, user => user.mail).forEach(user => {
        const amount = orderLines
            .filter(line => user.id === line.userId)
            .reduce((acc, line) => acc + parseFloat(line.amount), 0)

        const language = user.language ? user.language.id : 'fr'
        const mail = _.defaults({
            to: user.mail && decrypt(user.mail),
            context: {
                firstName: user.firstName,
                lastName : user.lastname,
                civility : context.tl(user.civility.id, language),
                amount: amount && amount.toFixed(2)
            },
            subject: {template: `myCSEManCo : ${context.tl('yourReimbursement', language)}`},
            templateDir: path.join(
                global.appRoot,
                global.isProd
                    ? 'buildServer/server/models/fncs/mails/templates'
                    : 'src/server/models/fncs/mails/templates',
                language
            ),
        }, defaultMail);

        global.mailer.sendMail(mail, logMessage)
    })
}

export async function sendNotificationMail(orderLines, context) {
    const userIds = orderLines.map(line => line.userId)
    const users = await global.app.R.CUser.find({
        query: {
            _id: {$in: userIds.map(id => global.ObjectID(id))},
        },
        fieldPath: ['firstname', 'lastname', 'civility.name', 'language.id'],
        group: new global.ObjectID(context.group.id)
    })
    if (users.length !== 0) sendMail(users, orderLines, context)

}
