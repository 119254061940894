
// binding for redux-form
import PhoneNumberInput from "../../../../components/Form/PhoneNumberInputField";
import {Field} from "redux-form";
import React from "react";
import getFormValidations, {validateEuropeanPhoneNumber} from "./formValidations";

const Input = ({ input: {value, onChange}, meta:{touched, error}, path, required, t, language, disabled, editable}) =>  {
    return <PhoneNumberInput
        path={path}
        value={value}
        required={required}
        customOnChange={value => onChange("+" + value)}
        t={t}
        language={language}
        disabled={disabled || !editable}
        touched={touched}
        error={error}
    />
}

// the binding function has to be outside to avoid re-rendering
// https://github.com/erikras/redux-form/issues/1094

const FormPhoneNumberInputField = ({ field, objectMode, t, language }) => {
    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            validate={validations}
            path={field.path}
            required={field.required}
            t={t}
            language={language}
            component={Input}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
        />
    )
}

export default FormPhoneNumberInputField;
