import _ from 'lodash'
import {basicContext} from "../../utils/contextUtils";
import {fetchDtData, SAVE_OBJECT_SUCCESS} from "../../../apps/KpModule/actions/api";

export const jobs = () => []
export const entities = () =>[
    {
        name: 'Image',
        fields: [
            {path: 'name', notEmpty: true},
            'type',
            'User',
            {path: 'date', notEmpty: true}
        ]
    },
    {
        name: 'Video',
        fields: [
            {path: 'name', notEmpty: true},
            'type',
            'User',
            {path: 'poster', type: 'Image'},
            {path: 'date', notEmpty: true}
        ]
    },
    {
        name: "MediaBase",
        facets: [{name: 'files'}],
        fields: [
            {path: "title", unique: true},
            {path: "description"},
            {path: "createdAt", type: 'date', dateFormat: "YYYY-MM-DD HH:mm:ss", nullable: true},
            {path: "lastUpdate", type: 'date', dateFormat: "YYYY-MM-DD HH:mm:ss", nullable: true},
            {path: 'images', type: 'Image', link: {type: 'OTM', deleteType: 'cascade', onParent: true}},
            {path: 'videos', type: 'Video', link: {type: 'OTM', deleteType: 'cascade', onParent: true}},
            {type: "Profile", link: "MTM"},
            {path: "tags", type: "DocumentType", link: "MTM", nullable: true},
            {path: "order", type: "integer"},
            {path: "active", type: "boolean", nullable: true},
            {path: "fullWidth", type: "boolean", nullable: true},
            {path: "showDescription", type: "boolean", nullable: true},
            {path: "showTags", type: "boolean", nullable: true},
            {path: "documentConsultation", type: "boolean", nullable: true},
            {path: "includedInLatestNewsList", type: "boolean", nullable: true},
            {
                path: "hasVideo",
                fieldPath: ['videos.id'],
                f: function() {
                    return this.videos.length
                }
            },
            {
                path: "hasDocument",
                fieldPath: ['files.id'],
                f: function() {
                    return this.files.length
                }
            },
            /*
            "Language",
            {type: "Profile", link: "MTM"},
            {path: "visible", type: "boolean"},
             */
        ],
        get: function(id, context, callback) {
            this.prepareContext(context, 'R', (error, context) => {
                if (error) callback(error)
                else {
                    const module = context.clientContext && context.clientContext.moduleId
                    const isUserModule = module === `m-${context.model.id}-mediaBaseUser`

                    global.app[context.model.id].MediaBase.find({
                        ...basicContext(context),
                        fieldPath: [
                            'title', 'description',
                            'profiles',
                            'files.id', 'files.name', 'files.user.id', 'files.user.name', 'files.date', 'files.type',
                            'images.id', 'images.name', 'images.user.id', 'images.user.name', 'images.date', 'images.type',
                            'videos.id', 'videos.name', 'videos.user.id', 'videos.user.name', 'videos.date', 'videos.type', 'videos.poster.id', 'videos.poster.name', 'videos.poster.date',
                            'tags'
                        ],
                        query: {
                            _id: new global.ObjectID(id)
                        }
                    }, (e, elements) => {
                        if(e) return callback(e)
                        return callback(null,
                            isUserModule
                                ? {
                                    ...elements[0],
                                    media: [
                                        ...elements[0].videos.map(video => ({...video, type: 'video'})),
                                        ...elements[0].images.map(image => ({...image, type: 'image'}))
                                    ],
                                    buttons: []
                                }
                                : elements[0]
                        )
                    })
                }
            })
        },
        validateSave: function(newObject, oldObject, context, callback) {
            if(newObject.prefaced && !oldObject?.prefaced) {
                const mediaBaseCollection = global.app[context.model.id].MediaBase.collection
                mediaBaseCollection.updateMany(
                    {_id: {$ne: global.ObjectID(newObject.id)}},
                    {$set: {prefaced: false}},
                    e => callback(e))
            } else callback()
        },
        beforeSave: (object, oldObject, context, callback) => {
            const action  = context.restAction && context.restAction.crudType
            if(action === 'C') {
                object.createdAt = new Date()
            }
            object.lastUpdate = new Date()
            callback(null, object, oldObject)
        }
    },
]

export const modules = () =>[
    {
        object: "MediaBase",
        name: 'MediaBaseAdmin',
        tKey: "mTitle-mediaBaseAdmin",
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        defaultSortBy: "order",
        defaultSortDirection: "ASC",
        viewMap: {
            dt: [
                {path: "hasVideo", type: 'videoIcon', label: ' ', className: 'kpDataTable-iconColumn', flexGrow: 0, notDraggable: true, width: 35},
                {path: "hasDocument", type: 'documentIcon', label: ' ', className: 'kpDataTable-iconColumn', flexGrow: 0, notDraggable: true, width: 35},
                {path: "title", width: 250},
                {path: "lastUpdate", tKey: 'dateAndTime', width: 200},
                {path: "profiles", tKey: "profile(s)", width: 300},
                {path: "order", width: 100},
                {path: "active", width: 100},
                {path: "fullWidth", tKey: 'extent', width: 150},
                {path: "showDescription", width: 200},
                {path: "showTags", width: 200},
                {path: "tags", width: 250},
                //{path: "includedInLatestNewsList"},
            ],
            form: [
                {path: "title"},
                {path: "description", type: "richTextEditor"},
                {path: "profiles", tKey: "profile(s)", filters: ["thisModel"]},
                {path: "videos", type: 'video', fieldPath: ['id', 'name', 'type', 'user.id', 'user.name', 'date']},
                {path: "images", type: 'image', fieldPath: ['id', 'name', 'type', 'user.id', 'user.name', 'date']},
                {path: "files"},
                {path: "tags", display: "tName"},
                {path: "order"},
                {path: "active", default: true},
                {path: "fullWidth", default: false},
                {path: "showDescription", default: true},
                {path: "showTags", default: false},
                {path: "documentConsultation", tKey: "Consulter document", default: true},
                //{path: "includedInLatestNewsList"},
            ]
        },
        actionSubscriptions: [
            {
                actionType: SAVE_OBJECT_SUCCESS,
                subscription: ({ store }) => {
                    const state = store.getState()
                    const moduleId = _.get(state, 'module.id')
                    store.dispatch(fetchDtData(moduleId))
                }
            }
        ],
    },
    {
        object: "MediaBase",
        name: 'MediaBaseUser',
        tKey: "mTitle-mediaBaseUser",
        //updatable: true,
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        removable: false,
        viewMap: {
            dt: [
                {path: "hasVideo", type: 'videoIcon', label: ' ', className: 'kpDataTable-iconColumn', flexGrow: 0, notDraggable: true, width: 35},
                {path: "hasDocument", type: 'documentIcon', label: ' ', className: 'kpDataTable-iconColumn', flexGrow: 0, notDraggable: true, width: 35},
                {path: "title"},
                {path: "tags"},
                //{path: "visible", width: 100}
            ],
            form: [
                {path: "title", type: 'heading', hideLabel: true, style: {maxWidth: '700px', margin: 'auto', letterSpacing: '-0.011em', lineHeight: '32px', fontSize: '25px', color: '#00b1f0'}, userGroupSelectedColor: true},
                {path: "media", type: 'media' , editable: false, hideLabel: true, noThumbnail: true, style: {maxWidth: '700px', margin: 'auto'}},
                {path: "description", type: "richTextEditor", readOnly: true, style: {maxWidth: '700px', margin: 'auto', fontSize: '14px', letterSpacing: '0.03em'}},
                {path: "files", editable: false, hideLabel: true, readOnly: true, displayEmpty: false, style: {maxWidth: '700px', margin: 'auto'}},
                {path: "buttons", hidden: true}
            ]
        }
    }
]
