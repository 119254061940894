import {decrypt} from "../../../utils/crypto";
import path from "path";

export function choseStepAction2(nextStep){
    if ( nextStep === 'approved' ){
        return "a terminé la validation"
    }
    else if ( nextStep === 'closed'){
        return "a clôturé la réalisation"
    }
}
export function choseSubjectStep(nextStep){
    if ( nextStep === 'realization' ){
        return "Réalisation"
    }
    else if ( nextStep === "draft"){
        return "Création"
    }
    else {
        return "Validation"
    }
}

export function choseSubjectStep2(nextStep){
    if ( nextStep === 'approved' ){
        return "Validation terminée"
    }
    else if ( nextStep === 'closed'){
        return "Clôture"
    }
}


export function choseStepStatus(nextStep){
    if ( nextStep === 'demand' ){
        return "Instrcution"
    }
    else if ( nextStep === 'preValidation' ){
        return "Pré-validation"
    }
    else if ( nextStep === 'control' ){
        return "Contrôle"
    }
    else if ( nextStep === 'validation' ){
        return "Validation"
    }
    else if ( nextStep === 'realization' ){
        return "Réalisation"
    }
    else if ( nextStep === 'approved' ){
        return "Validé"
    }
    else if ( nextStep === 'closed' ){
        return "Clôturé"
    }
    else {
        return "undefined"
    }
}

export function getStepNameForComment(step) {
    if(step === 'demand') {
        return 'instruction'
    } else if (step === 'preValidation'){
        return 'pre-validation'
    }
    else {
        return step
    }
}

export function choseStepRole(status, step){
    if ( ["demand"].includes(status)){
        return "L'instructeur"
    }
    else if ( status === 'control'){
        return "Le contrôleur"
    }
    else if ( status === 'validation'){
        return "Le valideur"
    }
    else if ( status === 'arbitration'){
        return "L'arbitre"
    }
    else if (['preValidation'].includes(status)){
        return "Le pré-valideur"
    }
    else if ( status === 'draft'){
        if (step === 'preValidation'){
            return "Le pré-valideur"
        }
        else if (step === 'demand'){
            return "L'instructeur"
        }
    }
    else if (status === 'realization'){
        return "Le réalisateur"
    }
    else if ( status === 'information request'){
        if ( ["demand"].includes(step)){
            return "L'instructeur"
        }
        else if ( step === 'control'){
            return "Le contrôleur"
        }
        else if ( step === 'validation'){
            return "Le valideur"
        }
        else if (['preValidation', 'draft'].includes(step)){
            return "Le pré-valideur"
        }
        else if (step === 'realization') {
            return "Le réalisateur"
        }
    }
}

export function moduleName(step) {
    switch (step) {
        case 'preValidation':
            return 'Pré-Validation'
        case 'demand':
            return 'Instruction'
        case 'control':
            return 'Contrôle'
        case 'validation':
            return 'Validation'
        case 'realization':
            return 'Réalisation'
        default:
            return 'Unknown'
    }
}

export function choseStepAction(step, action){
    if (action === "validate" || action === "transmit"){
        if ( ["demand", "control", 'validation', 'preValidation'].includes(step)){
            return "a validé"
        }
        else if ( step === "draft" ){
            return "a créé"
        }
    }
    else if (action === "revise"){
        return "vous a sollicité pour des compléments d’informations concernant"
    }
    else if (action === "delegate"){
        return "vous a délégué"
    }
    else if (action === "abandon"){
        return "a abandonné"
    }
    else if (action === "refuse"){
        return "a refusé"
    }
    else if (action === "arbitrate"){
        return "a demandé l'arbitrage pour"
    }
}

export function choseExpectedAction(nextStep, action){
    if ( ["validate", "delegate", "transmit"].includes(action) ){
        if ( ["demand", "control", "validation", 'preValidation'].includes(nextStep) ){
            return "votre décision pour poursuivre (ou non) son processus de validation"
        }
        else if ( nextStep === 'realization'){
            return "de sa réalisation"
        }
    }
    else if (action === "revise"){
        return "votre retour"
    }
    else if (action === "arbitrate"){
        return "votre arbitrage pour poursuivre (ou non) son processus de traitement"
    }
}

export function createValidationMail(context, userMail, subject, demand, stepAction, expectedAction, moduleName){
    const projectOrDemandTemplate = context?.module?.category?.path === 'demande' ? 'fr_demandValidation' : 'fr_projectValidation'
    const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'
    const demandTotalAmount = context?.module?.name === 'preValidation' ? demand.largeImputationsTotalAmount : demand.totalAmount
    return {
        from: 'support@keenpoint.com',
        to: decrypt(userMail),
        subject: {template: subject},
        context: {
            userFirstname: context.user.firstname,
            userLastname: context.user.lastname,
            stepRole: choseStepRole(demand.status.name, demand.workflow[0]),
            stepAction: stepAction,
            expectedAction: expectedAction,
            moduleName: moduleName,
            demandNumber: demand.demandNumber,
            demandTitle: demand.title,
            demandObjectif: demand.objectifs,
            demandDescription: demand.description,
            demandOrganization: demand.organizationAndMesh.name,
            demandTotalAmount: demandTotalAmount,
            //lastComments: lastComments.map(comment => ' « ' + comment + ' »')
        },
        content: projectOrDemandTemplate + withOrWithoutComments + '.html',
        //attachments: newObject.files,
        //template: 'fr_projectValidation.html',
        verbose: {
            general: true,
        },
        templateDir: path.join(
            global.appRoot,
            global.isProd
                ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
        )
    }
}

export function createReadersMail(context, userMail, subject, demand, moduleName){
    const projectOrDemandTemplate = context?.module?.category?.path === 'demande' ? 'fr_demandAfterFirstStep' : 'fr_projectAfterFirstStep'
    const demandTotalAmount = context?.module?.name === 'preValidation' ? demand.largeImputationsTotalAmount : demand.totalAmount
    return {
        from: 'support@keenpoint.com',
        to: decrypt(userMail),
        subject: {template: subject},
        context: {
            userFirstname: context.user.firstname,
            userLastname: context.user.lastname,
            stepRole: choseStepRole(demand.status.name, demand.workflow[0]),
            stepAction: choseStepAction(demand.workflow[0], context.action),
            moduleName: moduleName,
            demandNumber: demand.demandNumber,
            demandTitle: demand.title,
            demandObjectif: demand.objectifs,
            demandDescription: demand.description,
            demandOrganization: demand.organizationAndMesh.name,
            demandTotalAmount: demandTotalAmount,
        },
        content: projectOrDemandTemplate + '.html',
        //attachments: newObject.files,
        //template: 'fr_projectValidation.html',
        verbose: {
            general: true,
        },
        templateDir: path.join(
            global.appRoot,
            global.isProd
                ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
        )
    }
}

export function createAbondonMail(context, userMail, subject, demand, stepAction){
    const projectOrDemandTemplate = context?.module?.category?.path === 'demande' ? 'fr_demandAbondon' : 'fr_projectAbondon'
    const demandTotalAmount = context?.module?.name === 'preValidation' ? demand.largeImputationsTotalAmount : demand.totalAmount
    return {
        from: 'support@keenpoint.com',
        to: decrypt(userMail),
        subject: {template: subject},
        context: {
            userFirstname: context.user.firstname,
            userLastname: context.user.lastname,
            stepRole: choseStepRole(demand.status.name, demand.workflow[0]),
            stepAction: stepAction,
            demandNumber: demand.demandNumber,
            demandTitle: demand.title,
            demandObjectif: demand.objectifs,
            demandDescription: demand.description,
            demandOrganization: demand.organizationAndMesh.name,
            demandTotalAmount: demandTotalAmount,
        },
        content: projectOrDemandTemplate + '.html',
        //attachments: newObject.files,
        //template: 'fr_projectValidation.html',
        verbose: {
            general: true,
        },
        templateDir: path.join(
            global.appRoot,
            global.isProd
                ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
        )
    }
}

export function createFinishedMail(context, userMail, demand, nextStep){
    const projectOrDemandTemplate = context?.module?.category?.path === 'demande' ? 'fr_demandFinished' : 'fr_projectFinished'
    const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
    const demandTotalAmount = context?.module?.name === 'preValidation' ? demand.largeImputationsTotalAmount : demand.totalAmount
    return {
        from: 'support@keenpoint.com',
        to: decrypt(userMail),
        subject: {template: `${choseSubjectStep2(nextStep)} ${projectOrDemandTitle} N° : ${demand.demandNumber} (Statut : ${choseStepStatus(nextStep)})`},
        context: {
            userFirstname: context.user.firstname,
            userLastname: context.user.lastname,
            stepRole: choseStepRole(demand.status.name, demand.workflow[0]),
            stepAction: choseStepAction2(nextStep),
            demandNumber: demand.demandNumber,
            demandTitle: demand.title,
            demandObjectif: demand.objectifs,
            demandDescription: demand.description,
            demandOrganization: demand.organizationAndMesh.name,
            demandTotalAmount: demandTotalAmount,
        },
        content: projectOrDemandTemplate + '.html',
        //attachments: newObject.files,
        //template: 'fr_projectValidation.html',
        verbose: {
            general: true,
        },
        templateDir: path.join(
            global.appRoot,
            global.isProd
                ? 'buildServer/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
                : 'src/server/models/suiviEngagement/mails/templates/concernedDemands/demandStep'
        )
    }
}
