import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Registration from '../../../../components/Registration'
import { I18n } from 'react-i18next'
import {kpFetch} from "../../../../restClient"
import {toastr} from "react-redux-toastr"
import {europeanCountriesCodes, validateEmail} from "../../../KpModule/components/Form/formValidations";


const shelterNumberCheck = value =>
    value % 1 === 0 && value >= 0 && value <= 10
        ? undefined
        : 'Saisissez un nombre entier compris entre 0 et 10'

const required = value =>
    (typeof value === 'string') || _.isArray(value)
        ? value.length !== 0
            ? undefined
            : 'required'
        : (value !== undefined && value !== null)
            ? undefined
            : 'required'

const emailAddress = value =>
    value
        ? validateEmail(value)
            ? undefined
            : 'shouldBeAnEmail'
        : undefined

const getElementsById = options => options.reduce((acc, option) => {
    return {
        ...acc,
        [option.value]: option
    }},{})

const isValidPostalCode = (value) => {
    const postalCodePattern = /^\d{5}$/
    return postalCodePattern.test(value)
        ? undefined
        : 'Le code postal doit contenir exactement 5 chiffres'
}

function validateEuropeanPhoneNumber(phoneNumber, values, props) {

    // Ensure the input contains only digits
    if (!/^\d+$/.test(phoneNumber)) {
        return undefined
    }

    // Extract country code
    let countryCode = phoneNumber.substring(0, 2)
    let remainingNumber = phoneNumber.substring(2)

    // Validate the extracted country code
    if (countryCode !== "33") {
        return props.t('invalidCountryPrefix')
    }

    // Validate the number length
    const { min, max } = europeanCountriesCodes[countryCode];
    if (remainingNumber.length < min || remainingNumber.length > max) {
        return props.t('invalidPhoneNumber')
    }

    return undefined
}


class UserRegistrationPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            error: true,
            errorMessage: true,
            success: false,
            functions: [],
            animalTypes: [],
            yesNo: [
                {value: 'yes', label: 'Oui'},
                {value: 'no', label: 'Non'}
            ],
            civilities: [
                { value: 'm', label: 'Monsieur' },
                { value: 'mme', label: 'Madame' }
            ],
            shelterTypes: [
                { value: 'pound', label: 'Fourrière' },
                { value: 'retirementHome', label: 'Maison de retraite' },
                { value: 'pension', label: 'Pension' },
                { value: 'hostFamily', label: "Famille d'accueil" },
                { value: 'shelter', label: 'Refuge' },
                { value: 'guarding', label: 'Gardiennage' },
                { value: 'requisition', label: 'Réquisition' }
            ],
            activities: [
                { value: 'refuge', label: 'Refuge' },
                { value: 'hostFamily', label: "Famille d'accueil" },
                { value: 'pound', label: 'Fourrière' }
            ],
            organizationSizes: [
                { value: '1', label: 'Petite' },
                { value: '2', label: 'Moyenne' },
                { value: '3', label: 'Grande' }
            ],
            validations: {
                civility: [required],
                firstname: [required],
                lastname: [required],
                mail: [required, emailAddress],
                phone: [required, validateEuropeanPhoneNumber],
                organizationName: [required],
                organizationAddress: [required],
                organizationZipCode: [required, isValidPostalCode],
                organizationCity: [required],
                organizationCountry: [required],
                organizationSize: [required],
                rna: [required],
                sheltersNumber: [required, shelterNumberCheck],
                generalInterestRecognition: [required],
                publicUtilityRecognition: [required],
                hasEndowmentFund: [required],
                hasDUERP: [required],
                DUERPUpdateDate: [required],
                contactID: [required],
                situationNotice: [required],
                updatedStatus: [required],
                balanceSheetsAndIncomeStatements: [required],
                officialStatement: [required],
                CERFA: [required],
                lastGeneralMeeting: [required]
            },
            validationErrors: {},
            formDirty: false,
        }
        this.validateField = this.validateField.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleResponse = this.handleResponse.bind(this)
    }
    componentDidMount() {
        kpFetch('GET', `/userRegistrationDataLists`, "", {})
            .then(
                ({ ok, json, message }) => {
                    if (!ok || !json.length) {
                        console.log('response for fetching data for registration form not OK')
                    }
                    this.setState({
                        functions: json.functions.map(o => ({value: o._id.toString(), label: o.name})),
                        animalTypes: json.animalTypes.map(o => ({value: o._id.toString(), label: o.name}))
                    })
                }
            )
            .catch(error =>
                console.log('error fetching data for registration form', error)
            )
    }

    handleResponse = response => {
        if(!response.error) {
            this.setState({
                loading: false,
                success: true,
            })
            setTimeout(() => window.open("https://www.defensedelanimal.fr", "_self"), 15000)
        } else {
            this.setState({
                loading: false,
                error: true,
                errorMessage: response.error.message
            })
        }
    }
    handleSubmit = data => {
        const {t} = this.props
        const validationErrors = this.validateForm(data)

        const errorFieldsKeys = Object.keys(validationErrors)

        const formDirty = !!errorFieldsKeys.length

        if(formDirty) {

            toastr.error(
                t('validationError'),
                t(errorFieldsKeys[0]) + ' : ' + t(validationErrors[errorFieldsKeys[0]])
            )
            this.setState({formDirty, validationErrors})
        } else {
            this.setState({
                loading: true,
                formDirty: false,
                validationErrors: {}
            })
            this.props.register(data)
                .then( response => this.handleResponse(response))
        }
    }

    validateField(data) {
        const validationErrors = this.validateForm(data)

        const noErrorFields = Object.keys(data).filter(key => !validationErrors[key])
        const filteredValidationsObject = _.omit(this.state.validationErrors, noErrorFields)

        const combinedValidationErrors = {
            ...filteredValidationsObject,
            ...validationErrors
        }

        this.setState({
            formDirty: !!Object.keys(combinedValidationErrors).length,
            validationErrors: combinedValidationErrors
        })
    }

    validateForm(data) {
        return Object.keys(data).reduce((acc, key) => {
            if(key === 'DUERPUpdateDate' && data.hasDUERP?.id === 'no') return acc
            const validations = this.state.validations[key]
            if(!validations || validations.length === 0) return acc
            let error
            const hasError = validations.some(validation => {
                const result = validation(data[key], data, this.props)
                if(result) {
                    error = result
                    return true
                }
                return false
            })
            return hasError
                ? {...acc, [key]: error}
                : acc
        }, {})
    }

    render() {
        const {yesNo, civilities, functions, activities, animalTypes, shelterTypes, organizationSizes, loading, success, validationErrors} = this.state

        const yesNoById = getElementsById(yesNo)
        const civilitiesById = getElementsById(civilities)
        const activitiesById = getElementsById(activities)
        const functionsById = getElementsById(functions)
        const animalTypesById = getElementsById(animalTypes)
        const shelterTypesById = getElementsById(shelterTypes)
        const organizationSizesById = getElementsById(organizationSizes)

        return (
            <I18n
                key={'C'}
                ns={['C', 'platform']}
            >
                {
                    t =>
                        <Registration
                            handleSubmit={this.handleSubmit}
                            validateField={this.validateField}
                            success={success}
                            loading={loading}
                            yesNo={yesNo}
                            yesNoById={yesNoById}
                            civilities={civilities}
                            civilitiesById={civilitiesById}
                            functions={functions}
                            activities={activities}
                            activitiesById={activitiesById}
                            functionsById={functionsById}
                            animalTypes={animalTypes}
                            shelterTypes={shelterTypes}
                            shelterTypesById={shelterTypesById}
                            animalTypesById={animalTypesById}
                            organizationSizes={organizationSizes}
                            organizationSizesById={organizationSizesById}
                            validationErrors={validationErrors}
                            t={t}
                        />
                }
            </I18n>
        )

    }
}

UserRegistrationPage.propTypes = {
    register: PropTypes.func.isRequired,
}

export default UserRegistrationPage
