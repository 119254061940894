const _ = require("lodash");
const {groupQueryFromContextWithoutOr} = require("../../../../enhancers/defaultObjects");
const {importantInputCorrespondences} = require("../../inputCorrespondence");

export const alertConfigurationFieldPath = [
    "tName",
    "timeWindow.id", "timeWindow.quantity", "timeWindow.period", "queryDates",
    "hasTimeInterval", "startTime", "endTime",
    "baseDay", "weekDay", "monthDay",
    "filterFields.filterObjectForMongo",
    "conditions.id", "conditions.conditionType.id", "conditions.stream.id",
    "conditions.source.id", "conditions.source.dataType.isNumber", "conditions.source.internalIdentifier", "conditions.source.inputCorrespondenceByImportType.id",
    "conditions.source2.id", "conditions.source2.dataType.isNumber", "conditions.source2.internalIdentifier", "conditions.source2.inputCorrespondenceByImportType.id",
    "conditions.operator.id", "conditions.operator.mongoSymbol", "conditions.condValue", "conditions.condValues",
    "groupAxes.childOfAxisEntityName",
    "groupAxes.projectObjectForMongo", "groupAxes.groupIdObjectForMongo",
    "preGroupAxes.projectObjectForMongo", "preGroupAxes.groupIdObjectForMongo",
    "alertFields.alertEngineOrder.id", "alertFields.themeFieldPaths", "alertFields.preGroupObjectForMongo", "alertFields.themeFieldPath", "alertFields.denominatorThemeFieldPath",
    "alertFields.themeStreamId", "alertFields.denominatorThemeStreamId",
    "alertFields.condOperator.mongoSymbol", "alertFields.value", "alertFields.aggOperator.mongoOperator", "alertFields.preAggOperator.mongoOperator"
];

const datesQuery = (alertConf, delay) => {

    return {
        [importantInputCorrespondences.Date.internalIdentifier]: delay
            ? {
                $lt: alertConf.queryDates["$lt"].clone().subtract(delay * alertConf.timeWindow.quantity, alertConf.timeWindow.period).toDate(),
                $gte: alertConf.queryDates["$gte"].clone().subtract(delay * alertConf.timeWindow.quantity, alertConf.timeWindow.period).toDate()
            }
            : {
                $lt: alertConf.queryDates["$lt"].toDate(),
                $gte: alertConf.queryDates["$gte"].toDate()
            }
    }
}

const datesExpressionQuery = (alertConf, delay) => {
    return {
        $and: [
            {
                $lt: [
                    `$${importantInputCorrespondences.Date.internalIdentifier}`,
                    delay
                        ? alertConf.queryDates["$lt"].clone().subtract(delay * alertConf.timeWindow.quantity, alertConf.timeWindow.period).toDate()
                        : alertConf.queryDates["$lt"].toDate()
                ]
            },
            {
                $gte: [
                    `$${importantInputCorrespondences.Date.internalIdentifier}`,
                    delay
                        ? alertConf.queryDates["$gte"].clone().subtract(delay * alertConf.timeWindow.quantity, alertConf.timeWindow.period).toDate()
                        : alertConf.queryDates["$gte"].toDate()
                ]
            },
        ]
    }
}

const groupObjectForMongo = (alertConf, alertField, index) => ['themeFieldPath', 'denominatorThemeFieldPath'].reduce(
    (acc, fieldPath) => {
        const themeFieldPath = alertField[fieldPath]
        if(!themeFieldPath) return acc
        const fieldPathWithPrefix = fieldPath === 'themeFieldPath'
            ? `${index}-numerator-${themeFieldPath}`
            : `${index}-denominator-${themeFieldPath}`

        const delay = fieldPath === 'themeFieldPath'
            ? alertField.numeratorDelay
            : alertField.denominatorDelay

        return Object.assign(
            acc,
            {
                [fieldPathWithPrefix.replace(/\./g,' ')]: {
                    [alertField.aggOperator.mongoOperator]: {
                        $cond: [
                            datesExpressionQuery(alertConf, delay),
                            `$${themeFieldPath}`,
                            0
                        ]
                    }

                }
            }
        )
    },
    {}
);

const projectObjectForMongo = (alertField, index) => ['themeFieldPath', 'denominatorThemeFieldPath'].reduce(
    (acc, fieldPath) => {
        const themeFieldPath = alertField[fieldPath]
        if(!themeFieldPath) return acc
        const fieldPathWithPrefix = fieldPath === 'themeFieldPath'
            ? `${index}-numerator-${themeFieldPath}`
            : `${index}-denominator-${themeFieldPath}`
        return _.setWith(acc, fieldPathWithPrefix, `$${fieldPathWithPrefix.replace(/\./g,' ')}`, Object) //Object is the customizer to avoid setting number keys as arrays
    }, {}
);

export const groupIdForMongoQuery = axes => axes.reduce(
    (acc, groupAxis) => Object.assign(acc, groupAxis.groupIdObjectForMongo),
    {}
)

export const sortObjectForMongoQuery = axes => axes.reduce(
    (acc, groupAxis) => Object.assign(acc, groupAxis.sortObjectForMongo),
    {}
)

const roundRatios = alertFields => alertFields.reduce((acc, alertField, i) => {
    if (!alertField.denominatorThemeFieldPath) return acc;
    return Object.assign(
        acc,
        {
            [`ratio${i}`]: {
                $round: [`$ratio${i}`, 4 ]
            }
        }
    );
}, {});

const ratiosProjection = alertFields => alertFields.reduce((acc, alertField, i) => {
    if (!alertField.denominatorThemeFieldPath) return acc;
    return Object.assign(
        acc,
        {
            [`ratio${i}`]: {
                $cond: [
                    { $eq: [`$${alertField.denominatorThemeFieldPath}`, 0 ] },
                    0,
                    {$divide: [
                        `$${alertField.themeFieldPath}`,
                        `$${alertField.denominatorThemeFieldPath}`
                        ]
                    }
                ]
            }
        }
    );
}, {});

const ratiosProjectionAfterAggregation = alertFields => alertFields.reduce((acc, alertField, i) => {
    if (!alertField.denominatorThemeFieldPath) return acc;
    return Object.assign(
        acc,
        {
            [`ratio${i}`]: {
                $cond: [
                    { $eq: [`$${i}-denominator-${alertField.denominatorThemeFieldPath}`, 0 ] },
                    0,
                    {$divide: [
                            `$${i}-numerator-${alertField.themeFieldPath}`,
                            `$${i}-denominator-${alertField.denominatorThemeFieldPath}`
                        ]
                    }
                ]
            }
        }
    );
}, {});

const matchConditions = alertFields => alertFields.reduce((acc, alertField, i) => {
    const matchKey = alertField.denominatorThemeFieldPath ? `ratio${i}` : alertField.themeFieldPath;
    return Object.assign(
        acc,
        {[matchKey]: {
            [`$${alertField.condOperator.mongoSymbol}`]: alertField.value
        }}
    )
}, {});

const matchConditionsAfterAggregation = alertFields => alertFields
    .reduce((acc, alertField, i) => {
        if(alertField.alertEngineOrder.id === "aggregationRatioCondition") {
            const matchKey = alertField.denominatorThemeFieldPath ? `ratio${i}` : i + '-numerator-' + alertField.themeFieldPath;
            return Object.assign(
                acc,
                {[matchKey]: {
                        [`$${alertField.condOperator.mongoSymbol}`]: alertField.value
                    }}
            )
        }
        return acc
}, {});

export const createAggregationPipeline = (alertConfiguration, context) => {
    const projectGroupAxes = alertConfiguration.groupAxes.reduce(
        (acc, groupAxis) => Object.assign(acc, _.mapValues(groupAxis.projectObjectForMongo, () => 1)),
        {}
    );

    const projectAlertFields = _(alertConfiguration.alertFields)
        .flatMap("themeFieldPaths")
        .reduce(
            (acc, fieldPath) => Object.assign(acc, {[fieldPath]: 1}),
            {}
        );

    const projectAlertFieldsAfterAggregation = _(alertConfiguration.alertFields)
        .flatMap( (alertField, index) => {
            return _.compact(
                ['themeFieldPath', 'denominatorThemeFieldPath'].map(themeFieldPath => {
                    const prefix = themeFieldPath === 'themeFieldPath'
                        ? index + '-numerator-'
                        : index + '-denominator-'
                    return alertField[themeFieldPath] && prefix + alertField[themeFieldPath]
                })
            )
        })
        .reduce(
            (acc, fieldPath) => Object.assign(acc, {[fieldPath]: 1}),
            {}
        );

    const fieldsBeforeAggregation = alertConfiguration.alertFields.filter(
        alertField => alertField.alertEngineOrder.id === "ratioConditionAggregation"
    );

    const fieldsAfterAggregation = alertConfiguration.alertFields.filter(
        alertField => alertField.alertEngineOrder.id === "aggregationRatioCondition"
    );

    const groupAxesExist = groupAxes => groupAxes.reduce(
        (acc, groupAxis) => Object.assign(acc, {[groupAxis.code]: {$exists: true}}), {}
    );

    const filterFieldsConditions = filterFields => filterFields.reduce(
        (acc, filter) => Object.assign(acc, filter.filterObjectForMongo), {}
    );

    const getQuery = conditions => {
        return conditions.reduce((acc, condition) => {
            const isApplyConditionType = _.get(condition, 'conditionType.id') === "applyCondition"
            const isNumber = _.get(condition, 'source.dataType.isNumber')

            const query = {
                [`$${condition.operator.mongoSymbol}`]: [
                    `$${condition.source.internalIdentifier}`,
                    isApplyConditionType
                        ? ["000000000000000000000018", "000000000000000000000019"].includes(condition.operator.id)
                            ? condition.condValues.split(';').map(value => isNumber ? Number(value) : value )
                            : isNumber ? Number(condition.condValue) : condition.condValue
                        : `$${condition.source2.internalIdentifier}`
                ]
            }
            if(acc["$expr"]) {
                if(acc["$expr"]["$and"]) {
                    return {
                        "$expr": {
                            "$and": [
                                ...acc["$expr"]["$and"],
                                query
                            ]
                        }
                    }
                }
                return {
                    "$expr": {
                        "$and": [
                            acc["$expr"],
                            query
                        ]
                    }
                }
            }
            return {"$expr": query}
        }, {})
    }

    const themesNotEmpty = alertFields => alertFields.reduce((acc, alertField) => {
        const numeratorThemeConditions = alertConfiguration.conditions.filter(condition => _.get(condition, "stream.id") === alertField.themeStreamId)
        const denominatorThemeConditions = alertConfiguration.conditions.filter(
            condition => !!alertField.denominatorThemeFieldPath && _.get(condition, "stream.id") === alertField.denominatorThemeStreamId
        )
        return Object.assign(
            acc,
            !alertField.denominatorThemeFieldPath
                ? {
                    [alertField.themeFieldPath]: {$exists: true},
                    ...datesQuery(alertConfiguration, alertField.numeratorDelay),
                    ...getQuery(numeratorThemeConditions)
                }
                : {$or: [
                        {
                            [alertField.themeFieldPath]: {$exists: true},
                            ...datesQuery(alertConfiguration, alertField.numeratorDelay),
                            ...getQuery(numeratorThemeConditions)
                        },
                        {
                            [alertField.denominatorThemeFieldPath]: {$exists: true},
                            ...datesQuery(alertConfiguration, alertField.denominatorDelay),
                            ...getQuery(denominatorThemeConditions)
                        }
                    ]
                }
        )
    }, {});

    const timeIntervalPipeline = alertConfiguration.hasTimeInterval
        ? [
            {
                $addFields: {
                    timeString: { $dateToString: { format: "%H:%M", date: "$date" } }
                }
            },
            {
                $match: {
                    timeString: { $gte: alertConfiguration.startTime, $lte: alertConfiguration.endTime }
                }
            }
        ]
        : []

    const groupByPipeline = !!alertConfiguration.preGroupAxes.length
        ? [
            {$group: Object.assign(
                    {
                        _id: groupIdForMongoQuery(alertConfiguration.preGroupAxes),
                        date: {$first: "$date"}
                    },
                    alertConfiguration.groupAxes.reduce(
                        (acc, groupAxis) => Object.assign(acc,{[groupAxis.code]: {$first: `$${groupAxis.code}`} } ),
                        {}
                    ),
                    ..._.map(alertConfiguration.alertFields, alertField => alertField.preGroupObjectForMongo)
                )},
            {$project: Object.assign(
                    {date: 1},
                    projectGroupAxes,
                    alertConfiguration.alertFields.reduce((acc, alertField) => {
                        return {
                            ...acc,
                            ...['themeFieldPath', 'denominatorThemeFieldPath'].reduce((innerAcc, fieldPath) => {
                                if(!alertField[fieldPath]) return innerAcc
                                return {...innerAcc, [alertField[fieldPath]]: `$${alertField[fieldPath].replace(/\./g,' ')}`}
                                },{}
                            )
                        }
                    }, {}),
                )},
        ]
        : []

    const aggregation = [
        {$match: Object.assign(
                groupAxesExist(alertConfiguration.groupAxes),
                filterFieldsConditions(alertConfiguration.filterFields),
                groupQueryFromContextWithoutOr(context),
                themesNotEmpty(alertConfiguration.alertFields)
            )},
        ...timeIntervalPipeline,
        ...groupByPipeline,
        {$project: Object.assign(
                {_id: 0, date: 1},
                projectGroupAxes,
                projectAlertFields,
                ratiosProjection(fieldsBeforeAggregation)
            )},
        {$project: Object.assign(
                {date: 1},
                projectGroupAxes,
                projectAlertFields,
                roundRatios(fieldsBeforeAggregation),
            )},
        {$match: matchConditions(fieldsBeforeAggregation)},
        {$group: Object.assign(
                {
                    _id: groupIdForMongoQuery(alertConfiguration.groupAxes),
                    lineCount: { $sum: 1 }
                },
                ..._.map(alertConfiguration.alertFields, (alertField, index) => groupObjectForMongo(alertConfiguration, alertField, index))
            )},
        {$project: Object.assign(
                {
                    _id: 0,
                    lineCount: 1
                },
                ...alertConfiguration.groupAxes.map(groupAxis => groupAxis.projectObjectForMongo),
                _.defaultsDeep(...alertConfiguration.alertFields.map(projectObjectForMongo))
            )},
        {$project: Object.assign(
                {lineCount: 1},
                projectGroupAxes,
                projectAlertFieldsAfterAggregation,
                ratiosProjectionAfterAggregation(fieldsAfterAggregation)
            )},
        {$match: matchConditionsAfterAggregation(alertConfiguration.alertFields)},
        {$project: Object.assign(
                {lineCount: 1},
                projectGroupAxes,
                projectAlertFieldsAfterAggregation
            )}
    ]
    return aggregation
};
