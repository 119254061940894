export const dependencies = [
    require("./../../utils/entityJoin").joinEntities({
        name: "DashboardElement",
        fields: [
            "name", "type", "url", "suffix", "link", "keys", "colors", "width", "sum", "params"
        ]
    }, ["StaticDashboardElement", "DynamicDashboardElement"]),
    {
        name: "DynamicDashboardElement",
        fields: [
            "name", "type", "url", "suffix", "link", "keys", "colors", "width", "sum", "params"
        ],
        db: {
            find: function(context, callback) {
                if(!context.model?.id) return callback(null, [])
                return global.app[context.model.id].DynamicDashboardElement.collection.find({
                    group: new global.ObjectID(context.group.id),
                }).toArray(callback)
            }
        }
    },
    {
        name: "StaticDashboardElement",
        type: "static",
        fields: [
            {path: "id", type: "id"},
            "name", "type", "url", "suffix", "link", "keys", "colors", "width", "sum"
        ],
        objects: [

            // Common Elements
            {
                id: "000000000000000000000001",
                name: "fr::Alertes déclenchées\nen::Alerts triggered\nit::Alerts ricevuti",
                type: 'shortCard',
                url: "CurrentUserAlertsInProgress",
                suffix: " (Nb)",
                link: {
                    title: "Traiter les alertes",
                    path: "tracking/m-S-alertProcessing"
                }
            },
            {
                id: "000000000000000000000002",
                name: "fr::Alertes à traiter\nen::Alerts to process\nit::Alerts da gestire",
                type: 'shortCard',
                url: "CurrentUserAlerts",
                suffix: " (Nb)",
                link: {
                    title: "Traiter les alertes",
                    path: "tracking/m-S-alertProcessing"
                }
            },

            {id: "000000000000000000000003", name: "fr::Magasin : Remise ln. = 100%\nen::Store : Disc. ln. = 100%\nit::Negozio: Sconto netto per articolo 100%", type: 'simpleBarChart', url: "TopStore100Disc", suffix: ' (Nb)', width: 6, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "000000000000000000000004", name: "fr::Magasin : Remise ln. 90 ≤ % < 100\nen::Store : Disc. ln. 90 ≤ % < 100\nit::Negozio: Sconto netto per articolo 90 ≤ % < 100", type: 'simpleBarChart', url: "Top90To100Disc", suffix: ' (Nb)', width: 6, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "000000000000000000000005", name: "fr::Magasin : Remise ln. 80 ≤ % < 90\nen::Store : Disc. ln. 80 ≤ % < 90\nit::Negozio: Sconto netto per articolo 80 ≤ % < 90", type: 'simpleBarChart', url: "Top80To90Disc", suffix: ' (Nb)', width: 6, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "000000000000000000000006", name: "fr::Magasin : Remise ln. 70 ≤ % < 80\nen::Store : Disc. ln. 70 ≤ % < 80\nit::Negozio: Sconto netto per articolo 70 ≤ % < 80", type: 'simpleBarChart', url: "Top70To80Disc", suffix: ' (Nb)', width: 6, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "000000000000000000000007", name: "fr::Magasin : Remboursement > 0€\nen::Store : Refund > 0€\nit::Negozio: Rimborsi > 0€", type: 'simpleBarChart', url: "TopNegativePmts", suffix: ' (Nb)', width: 6, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "000000000000000000000008", name: "fr::Magasin : Caissier ≠ Client, même adresse mail\nen::Store : Cashier ≠ Customer, Same email\nit::Negozio: Venditore ≠ cliente, ma stessa email", type: 'simpleBarChart', url: "CashierCustomerWithSameEmail", suffix: ' (Nb)', width: 6, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "000000000000000000000009", name: "fr::Magasin : Caissier = Client\nen::Store : Cashier = Customer\nit::Negozio: Venditore = Cliente", type: 'simpleBarChart', url: "CashierIsCustomer", suffix: ' (Nb)', width: 6, keys: ['store', 'number'], sum: true, useColorsByStore: true},
            {id: "000000000000000000000010", name: "fr::Employee : Gross purch. > 1000€\nen::Employee : Gross purch. > 1000€\nit::Dipendenti : Acquisti lordi mensili > 1000€", type: 'simpleBarChart', url: "EmployeePurchaseH1000", suffix: ' (Nb)', width: 6, keys: ['customerIsEmployee', 'number'], sum: true, useColorsByStore: true},
            {id: "000000000000000000000011", name: "fr::Customer : Gross Purch. > 2.5k€\nen::Customer : Gross Purch. > 2.5k€\nit::Clienti : Acquisti lordi settimanali > 2500€", type: 'simpleBarChart', url: "CustomerGrossPurchaseH2500", suffix: ' (Nb)', width: 6, keys: ['ticketCustomer', 'number'], sum: true, useColorsByStore: true, axisHeight: 90},
            {id: "000000000000000000000012", name: "fr::Customer : Tkt. ≥ 4\nen::Customer : Tkt. ≥ 4\nit::Clienti : ≥ 4 Ticket giornalieri", type: 'simpleBarChart', url: "CustomerTicketEH4", suffix: ' (Nb)', width: 6, keys: ['ticketCustomer', 'number'], sum: true, useColorsByStore: true, axisHeight: 90}
        ]
    }
]
