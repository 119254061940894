import React from 'react'
import PropTypes from 'prop-types'
import { defaultT } from '../../utils/i18n'

const FormField = ({ required, label, children, t = defaultT, fullWidth, hideLabel, width, type }) => {
    const fieldWidth = width && {width}
    return (
        <div className="form-group" style={{marginBottom: '25px'}}>
            { !['readOnly', 'readOnlyObject', 'text', 'textarea', 'number', 'dropdownObject', 'tags', 'comment2', 'files2', 'checkbox', 'datePicker', 'dateRange', 'dtObjects' ,'accordion', 'richTextEditor', 'image', 'video', 'creatableTags','monthPicker', 'timePicker', 'monthPickerRange', 'password', 'dualBoxList', 'phoneNumber', 'toggle', 'colorPicker', 'logoPicker'].includes(type) && !fullWidth && <label className={`col-sm-12 col-md-12`} style={{fontWeight: 400, fontSize: '14px', marginBottom: '5px'}}>{!hideLabel && t(label, { capitalize: true })} {!hideLabel && required && '*'}</label> }
            <div className={`col-sm-12 col-md-12`} style={fieldWidth}>
                {children}
            </div>
        </div>
    )
}

FormField.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    t: PropTypes.func
}

export default FormField
