import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Route, withRouter, Redirect, Switch } from 'react-router-dom'
import { I18n } from 'react-i18next'
import _ from 'lodash'
import {
    setUiDevice,
    setSidebarDocked,
    setSidebarOpen,
    setProfileOpened,
    setJobOpened,
    setDocsOpened,
    setLanguagesOpened,
    logoutAndRedirect,
    fetchDashboardData,
    fetchPlatformMediaElements, setTheme
} from '../../actions/index'
import KpModule from '../../../KpModule'
import * as selectors from '../../selectors'
import {
    getDashboardData,
    dashboardDataIsFetching,
    getDashboardElements,
    getMediaElements,
    elementsAreFetching
} from '../../selectors'
import SidebarContent from '../../components/SidebarContent'
import DeviceDetector from '../../../../components/DeviceDetector'
import Sidebar from 'react-sidebar'
import Navbar from '../../components/NavBar'
import ErrorBoundary from '../../../../components/ErrorBoundary'
import './PrivateApp.css'
import NotFoundRoute from '../NotFoundRoute/NotFoundRoute'
import KpDashboard from '../Dashboard'
import queryString from 'query-string'

const Module = ({ groupModelId, groupModels, t, ...props }) => {
    const groupModel = _.find(groupModels, { id: groupModelId })

    return (
        <ErrorBoundary t={t}>
            <KpModule groupModel={groupModel} {...props} />
        </ErrorBoundary>
    )
}

const Dashboard = ({ groupModelId, groupModels, fetchUserDashboard, fetchDashboardData, fetchPlatformMediaElements, dashboardElements, elementsAreFetching, dashboardData, language, dashboardDataIsFetching, user, t, ...props }) => {
    const groupModel = _.find(groupModels, { id: groupModelId })
    const groupId = groupModel.group.id
    const modelId = groupModel.model.id

    const filteredDashElements = dashboardElements.filter(element => element.groupModel.id === groupModelId)

    return (
        <ErrorBoundary t={t}>
            <I18n
                key={modelId}
                ns={[modelId, 'platform']}
            >
                {
                    t => (
                        <KpDashboard
                            modelId={modelId}
                            groupModelId={groupModelId}
                            groupModel={groupModel}
                            groupId={groupId}
                            fetchUserDashboard={fetchUserDashboard}
                            dashboardElements={filteredDashElements}
                            elementsAreFetching={elementsAreFetching}
                            dashboardData={dashboardData}
                            dashboardDataIsFetching={dashboardDataIsFetching}
                            fetchDashboardData={fetchDashboardData}
                            fetchPlatformMediaElements={fetchPlatformMediaElements}
                            user={user}
                            t={t}
                            language={language}
                            {...props}
                        />
                    )
                }
            </I18n>

        </ErrorBoundary>
    )
}
class PrivateApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notifications: {}
        }
        this.setNotifications = this.setNotifications.bind(this)
    }

    setNotifications(data, groupModelId) {
        this.setState({
            notifications : {
                ...this.state.notifications,
                [groupModelId]: data
            }
        })
    }

    render() {
        const {
            device,
            groups,
            groupModels,
            groupModelsSimplified,
            user,
            logout,
            docked,
            sidebarOpen,
            profileOpened,
            jobOpened,
            docsOpened,
            languagesOpened,
            setUiDevice,
            setSidebarDocked,
            setSidebarOpen,
            setProfileOpened,
            setJobOpened,
            setDocsOpened,
            setTheme,
            kpLogo,
            backgroundColor,
            menuButtonsColor,
            selectedColor,
            documents,
            mediaElements,
            setLanguagesOpened,
            language,
            languagesList,
            isUserK,
            fetchUserDashboard,
            dashboardElements,
            elementsAreFetching,
            dashboardData,
            dashboardDataIsFetching,
            fetchDashboardData,
            fetchPlatformMediaElements,
            t
        } = this.props

        const toggleSideBar = () =>
            device === 'mobile'
                ? setSidebarOpen(!sidebarOpen)
                : setSidebarDocked(!docked)

        return (
            <Sidebar
                sidebar={<SidebarContent
                    notifications={this.state.notifications}
                    setNotifications={this.setNotifications}
                    selectedColor={selectedColor}
                />}
                docked={docked}
                open={sidebarOpen}
                onSetOpen={setSidebarOpen}
                rootClassName={"PrivateApp-container"}
                styles={{root: {}, sidebar: {backgroundColor}, overlay: {}, dragHandle: {}, content: {backgroundColor}}}
                contentClassName="PrivateApp-content"
                sidebarClassName="PrivateApp-sidebar"
            >
                <DeviceDetector
                    handleChange={setUiDevice}
                    dockSidebar={setSidebarDocked}
                />
                <Navbar
                    toggleSideBar={toggleSideBar}
                    profileOpened={profileOpened}
                    setProfileOpened={setProfileOpened}
                    jobOpened={jobOpened}
                    docsOpened={docsOpened}
                    setJobOpened={setJobOpened}
                    setDocsOpened={setDocsOpened}
                    documents={documents}
                    languagesOpened={languagesOpened}
                    setLanguagesOpened={setLanguagesOpened}
                    device={device}
                    logout={logout}
                    language={language}
                    languagesList={languagesList}
                    user={user}
                    isUserK={isUserK}
                    groupModels={groupModelsSimplified}
                    notifications={this.state.notifications}
                    iconColor={menuButtonsColor}
                    kpLogo={kpLogo}
                />
                <Switch>
                    <Route
                        exact
                        path={`/`}
                        render={() => <Redirect to="/business"/>}
                    />
                    <Route
                        path={`/private/:moduleId`}
                        render={({match}) => {
                            return (
                                <NotFoundRoute
                                    params={match.params}
                                    technical={true}
                                >
                                    {() => (
                                        <Module
                                            moduleId={match.params.moduleId}
                                            user={user}
                                            language={language}
                                            menuButtonsColor={menuButtonsColor}
                                            selectedColor={selectedColor}
                                            setTheme={setTheme}
                                            t={t}
                                        />
                                    )}
                                </NotFoundRoute>
                            )
                        }}
                    />
                    <Route
                        path={`/setting/:moduleId`}
                        render={({match}) => {
                            return (
                                <NotFoundRoute
                                    params={match.params}
                                    technical={true}
                                >
                                    {() => (
                                        <Module
                                            moduleId={match.params.moduleId}
                                            user={user}
                                            language={language}
                                            menuButtonsColor={menuButtonsColor}
                                            selectedColor={selectedColor}
                                            setTheme={setTheme}
                                            t={t}
                                        />
                                    )}
                                </NotFoundRoute>
                            )
                        }}
                    />
                    <Route
                        path={`/business/:groupModelId/:category?/:moduleId/:objectId?`}
                        render={({match, location}) => (
                            <NotFoundRoute params={match.params}>
                                {() => {
                                    const parsed = location.search ? queryString.parse(location.search) : null
                                    return (
                                        <Module
                                            defaultObject={parsed}
                                            groupModelId={match.params.groupModelId}
                                            moduleId={match.params.moduleId || match.params.category}
                                            objectId={match.params.objectId}
                                            groupModels={groupModels}
                                            user={user}
                                            language={language}
                                            setTheme={setTheme}
                                            menuButtonsColor={menuButtonsColor}
                                            selectedColor={selectedColor}
                                            t={t}
                                        />
                                    )
                                }
                                }
                            </NotFoundRoute>
                        )}
                    />
                    <Route
                        path={`/business/:groupModelId`}
                        render={({match}) => (
                            <NotFoundRoute params={match.params}>
                                {({groupModel}) => {
                                    return groupModel && (
                                        <Dashboard
                                            device={device}
                                            mediaElements={mediaElements}
                                            groupModelId={match.params.groupModelId}
                                            groupModel={groupModel}
                                            groups={groups}
                                            groupModels={groupModels}
                                            fetchUserDashboard={fetchUserDashboard}
                                            user={user}
                                            language={language}
                                            dashboardElements={dashboardElements}
                                            elementsAreFetching={elementsAreFetching}
                                            dashboardData={dashboardData}
                                            dashboardDataIsFetching={dashboardDataIsFetching}
                                            fetchDashboardData={fetchDashboardData}
                                            fetchPlatformMediaElements={fetchPlatformMediaElements}
                                            t={t}
                                        />
                                    )
                                }

                                }
                            </NotFoundRoute>
                        )}
                    />
                </Switch>
            </Sidebar>
        )
    }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
    language: state.ui.language,
    languagesList: state.ui.languagesList,
    device: state.ui.device,
    docked: state.ui.sidebar.docked,
    kpLogo: state.ui.theme.kpLogo,
    backgroundColor: state.ui.theme.backgroundColor,
    menuButtonsColor: state.ui.theme.menuButtonsColor,
    selectedColor: state.ui.theme.selectedColor,
    sidebarOpen: state.ui.sidebar.open,
    profileOpened: state.ui.navbar.profileOpened,
    jobOpened: state.ui.navbar.jobOpened,
    docsOpened: state.ui.navbar.docsOpened,
    languagesOpened: state.ui.navbar.languagesOpened,
    groups: selectors.entities.getGroups(state),
    groupModels: selectors.getUserGroupModels(state),
    groupModelsSimplified: selectors.getGroupModels(state),
    documents: selectors.getDocuments(state),
    mediaElements: getMediaElements(state),
    user: selectors.getUserData(state),
    isUserK: selectors.isUserK(state),
    dashboardElements: getDashboardElements(state),
    elementsAreFetching: elementsAreFetching(state),
    dashboardData: getDashboardData(state),
    dashboardDataIsFetching: dashboardDataIsFetching(state)
})

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, { t }) => {
    return {
        setTheme: val => dispatch(setTheme(val)),
        setSidebarOpen: val => dispatch(setSidebarOpen(val)),
        setSidebarDocked: val => dispatch(setSidebarDocked(val)),
        setProfileOpened: val => dispatch(setProfileOpened(val)),
        setJobOpened: val => dispatch(setJobOpened(val)),
        setDocsOpened: val => dispatch(setDocsOpened(val)),
        setLanguagesOpened: val => dispatch(setLanguagesOpened(val)),
        logout: () => dispatch(logoutAndRedirect()),
        setUiDevice: val => dispatch(setUiDevice(val)),
        fetchDashboardData: (dashboardElements, groupModelId, modelId) => dispatch(fetchDashboardData(dashboardElements, groupModelId, modelId)),
        fetchPlatformMediaElements: (modelId, groupId, profiles) => dispatch(fetchPlatformMediaElements(modelId, groupId, profiles))
    }
}

/* istanbul ignore next */
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PrivateApp)
)
