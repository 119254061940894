import React, { Component } from 'react'
import * as d3 from 'd3'
import { select } from 'd3-selection'
import {translateName} from '../../utils/i18n'

class MultiAxisBarChart extends Component {
    shouldComponentUpdate(nextProps) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps)
    }

    render() {
        const { h, w, data = [], dataKeys, defaultKeys = [], className = 'MultiAxisBarChart', absolute = false, axisHeight = 60, language } = this.props

        const keys = dataKeys || defaultKeys

        if (keys.length < 2 || typeof keys[0] !== 'string') {
            return <div className={className}>Error: Provide a valid keys format.</div>
        }

        const legendHeight = 30
        const margin = { top: legendHeight, right: 30, bottom: axisHeight, left: 30 }
        const width = w - margin.left - margin.right
        const height = h - margin.top - margin.bottom

        const x = d3.scaleBand()
            .range([0, width])
            .padding(0.3)
            .domain(data.map(d => d[keys[0]]))

        // **Separate keys based on axis**
        let leftKeys = keys.slice(1).filter(k => k.axis === 'left')
        let rightKeys = keys.slice(1).filter(k => k.axis === 'right')

        // **Compute independent max values for each axis**
        const leftMax = leftKeys.length
            ? d3.max(data, d => Math.max(...leftKeys.map(k => Math.abs(d[k.path] || 0))))
            : 0

        const rightMax = rightKeys.length
            ? d3.max(data, d => Math.max(...rightKeys.map(k => Math.abs(d[k.path] || 0))))
            : 0

        // **Define separate y scales**
        const yScales = {}
        if (leftKeys.length) {
            yScales.left = d3.scaleLinear().domain([0, leftMax]).range([height, 0]).nice()
        }
        if (rightKeys.length) {
            yScales.right = d3.scaleLinear().domain([0, rightMax]).range([height, 0]).nice()
        }

        const xAxis = d3.axisBottom(x)
        const leftAxis = leftKeys.length ? d3.axisLeft(yScales.left).ticks(Math.min(leftMax, 7)).tickFormat(d3.format("~s")) : null
        const rightAxis = rightKeys.length ? d3.axisRight(yScales.right).ticks(Math.min(rightMax, 7)).tickFormat(d3.format("~s")) : null

        const svg = select(`.${className}`).select('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom + legendHeight)
            .select('.container')
            .attr('transform', `translate(${margin.left},${margin.top})`)

        svg.select(".bars").selectAll("*").remove()
        svg.select(".lines").selectAll("*").remove()
        svg.select(".legend").remove()

        // **Get only bar and line keys**
        const barKeys = keys.filter(k => k.type === 'bar')
        const lineKeys = keys.filter(k => k.type === 'line')

        // **Create separate color scales for bars and lines**
        const colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(keys.slice(1).map(k => k.path))

        // **Create an inner x scale for bars inside each category**
        const barInnerX = d3.scaleBand()
            .domain(barKeys.map(k => k.path))
            .range([0, x.bandwidth()])
            .padding(0.1)

        const barGroups = svg.select('.bars')
            .selectAll('.bar-group')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', d => `translate(${x(d[keys[0]])}, 0)`)

        // **Check if the animation has already played**
        const firstRender = !svg.node()?.__data__

        barKeys.forEach((key, index) => {
            const yScale = key.axis === 'left' ? yScales.left : yScales.right
            if (!yScale) return

            const bars = barGroups.append("rect")
                .attr("x", d => barInnerX(key.path))
                .attr("width", barInnerX.bandwidth())
                .attr("y", d => firstRender ? height : yScale(Math.abs(d[key.path])))
                .attr("height", d => firstRender ? 0 : height - yScale(Math.abs(d[key.path])))
                .attr("fill", colorScale(key.path))

            if (firstRender) {
                bars.transition()
                    .duration(1000)
                    .attr("y", d => yScale(Math.abs(d[key.path])))
                    .attr("height", d => height - yScale(Math.abs(d[key.path])))
            }
        })

        lineKeys.forEach((key, index) => {
            const yScale = key.axis === 'left' ? yScales.left : yScales.right
            if (!yScale) return

            const line = d3.line()
                .x(d => x(d[keys[0]]) + x.bandwidth() / 2)
                .y(d => yScale(Math.abs(d[key.path])))
                .curve(d3.curveMonotoneX)

            const path = svg.select('.lines')
                .append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", colorScale(key.path))
                .attr("stroke-width", 2)
                .attr("d", firstRender ? line(data.map(d => ({ ...d, [key.path]: 0 }))) : line(data))

            if (firstRender) {
                path.transition()
                    .duration(1000)
                    .attr("d", line(data))
            }
        })

        // ✅ **Legend (Now Restored)**
        const legend = svg.append("g")
            .attr("class", "legend")
            .attr("transform", `translate(0, -${legendHeight})`)

        const legendSpacing = 35
        const textLengths = keys.slice(1).map(key => translateName(key.tKey, language).length * 7)
        const totalTextWidth = textLengths.reduce((a, b) => a + b, 0)
        const totalLegendWidth = totalTextWidth + (keys.length * legendSpacing)

        let cumulativeX = (width - totalLegendWidth) / 2
        keys.slice(1).forEach((key, i) => {
            const legendItem = legend.append("g")
                .attr("class", "legend-item")
                .attr("transform", `translate(${cumulativeX}, 0)`)

            legendItem.append("rect")
                .attr("width", 12)
                .attr("height", 12)
                .attr("fill", key.type === 'bar' ? colorScale(key.path) : colorScale(key.path))

            legendItem.append("text")
                .attr("x", 18)
                .attr("y", 10)
                .style("font-size", "12px")
                .text(translateName(key.tKey, language))

            cumulativeX += textLengths[i] + legendSpacing
        })

        if (svg.node()) {
            svg.node().__data__ = { animated: true }
        }

        svg.select('.axis--x')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis)
            .selectAll("text")
            .attr("y", 5)
            .attr("x", -6)
            .attr("dy", ".35em")
            .attr("transform", "rotate(-55)")
            .style("text-anchor", "end")

        if (leftAxis) {
            svg.select('.axis--left')
                .attr('transform', `translate(0,0)`)
                .call(leftAxis)
        } else {
            svg.select('.axis--left').remove()
        }

        if (rightAxis) {
            svg.select('.axis--right')
                .attr('transform', `translate(${width},0)`)
                .call(rightAxis)
        } else {
            svg.select('.axis--right').remove()
        }

        return (
            <div className={className}>
                <svg>
                    <g className="container">
                        <g className="bars" />
                        <g className="lines" />
                        <g className="legend" />
                        <g className="axis axis--x" />
                        <g className="axis axis--left" />
                        <g className="axis axis--right" />
                    </g>
                </svg>
            </div>
        )
    }
}

export default MultiAxisBarChart
