import ShortCard from './ShortCard'
import DonutCard from './DonutCard'
import LongCard from './LongCard'
import RadialProgressComponent from './RadialProgress'
import SimpleBarChart from './SimpleBarChart'
import MultiAxisBarChart from './MultiAxisBarChart'
import DoubleBarChart from './DoubleBarChart'
import LineChart from './LineChart'
import HorizontalDoubleBarChart from './HorizontalDoubleBarChart'
import BarLineChart from './BarLineChart'
import StackedBarChart from './StackedBarChart'
import MultipleBarChart from './MultipleBarChart'


export default {
    'shortCard': ShortCard,
    'longCard': LongCard,
    'simpleBarChart': SimpleBarChart,
    'multiBarChart': MultiAxisBarChart,
    'doubleBarChart': DoubleBarChart,
    'multipleBarChart': MultipleBarChart,
    'horizontalDoubleBarChart': HorizontalDoubleBarChart,
    'lineChart': LineChart,
    'barLineChart': BarLineChart,
    'donutChart': DonutCard,
    'radialProgressCard': RadialProgressComponent,
    'stackedBarChart': StackedBarChart
}
