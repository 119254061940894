import async from 'async'
import {basicContext} from "../../../utils/contextUtils"
import moment from "moment"
import {decrypt} from "../../../utils/crypto"

const Errors = require("../../../utils/Errors").default
const _ = require('lodash')
import {
    returnButton,
    getUserHabsAndGrantedOrgsAndMeshesPerRole
} from "../utils/demandUtils";
import {buttonsIfArbitrator, buttonsIfDraft, buttonsIfFirstDemander, steps} from "../utils/demandUtils";
import {
    generateOrderOfSteps,
    getNextStepStatus,
    getPreviousStepPreviousOrder,
    getAndValidateNextStep,
    transmitToNextStep
} from "../utils/demandUtils";
import {userHaveExhaustifScope, isItFirstStepFirstOrder, isItDraft} from "../utils/demandUtils";
import {
    substractedItems2,
    compareArrays,
    isArrayInArrayOfArrays,
    monthRangeToList,
    fiscalYearsMonth, fiscalYearsMonth2
} from "../utils/usefulFunctions";
import {
    choseSubjectStep,
    choseStepStatus,
    choseStepAction,
    choseExpectedAction,
    moduleName,
    getStepNameForComment,
    createValidationMail, createReadersMail, createAbondonMail, createFinishedMail
} from "../utils/mailsUtils";
import {CrPdfConfig} from "../utils/pdfUtils";


export const entity = {
    name: 'Demand',
    facets: [
        {name: 'comments', path: 'comments'},
        {name: 'files', path: 'files'},
        {name: 'files', path: 'projectCommitteeFiles'},
        {name: 'files', path: 'pilotageCommitteeFiles'}
    ],
    fields: [
        {
            path: 'step',
            $f: function (object, context, callback){
                // todo check if needed to be replaced with first step
                context.module.name

                return object.workflow[0] === 'draft' ?
                        context?.module?.category?.path === 'Demande' ? callback(null, `demand`) : callback(null, `preValidation`)
                        : callback(null, `${object.workflow[0]}`)
            }
        },
        {path: 'startFiscalYear', type: 'FiscalYear', nullable: true},
        {path: 'endFiscalYear', type: 'FiscalYear', nullable: true},
        {
            path: "sequence", unique: true, ps: {
                object: [{
                    type: "nextSequence",
                    sequenceId: "se.demandSeq",
                    formatResult: result => {
                        const prefixLength = 7 - result.toString().length
                        const prefix = _.range(prefixLength).reduce(result => result + '0', '')
                        return `${prefix}${result}`
                    }
                }]
            }
        },
        {path: 'description'},
        {path: 'enjeux'},
        {path: 'objectifs'},
        {
            path: 'pointsOfAttention',
            type: 'PointsOfAttention',
            link: 'MTM'
        },
        {
            path: 'indicatorKeys',
            type: 'IndicatorKey',
            link: {
                type: "OTM",
                onParent: true,
                onChild: false,
            }
        },
        {
            path: 'pilotageCommitteeMembers',
            type: 'User',
            link: 'MTM',
            nullable: true
        },
        {
            path: 'projectCommitteeMembers',
            type: 'User',
            link: 'MTM',
            nullable: true
        },
        {
            path: 'delegateToCheckBox',
            type: 'boolean'
        },
        {
            path: 'organizationAndMesh',
            type: 'Organization'
        },
        {
            path: 'delegateTo',
            type: 'User',
            link: 'MTM',
            nullable: true
        },
        {
            path: 'currentContributors',
            type: 'User',
            link: 'MTM',
            nullable: true
        },
        {path: 'status', type: 'DemandStatus', nullable: true},
        {path: 'demandNumber'},
        {path: 'title'},
        {
            path: 'demandNumberAndTitle',
            fieldPath: ['demandNumber', 'title'],
            $f: function (object, context, callback) {
                return callback(null, `${object.demandNumber} : ( ${object.title} )`)
            }
        },
        {
            path: 'currentFiscalYear',
            $f: function (object, context, callback) {
                global.app.SE.FiscalYear.get(
                    {
                        "fiscalYearRange.0": {$lte: moment().toDate()},
                        "fiscalYearRange.1": {$gte: moment().toDate()}
                    }, {
                        fieldPath: ['code'],
                        group: context.group
                    },
                    (error, fiscalYear) => {
                        return callback(error, fiscalYear)
                    }
                )
            }
        },
        {path: 'relatedDemand', type: 'Demand', nullable: true},
        {path: 'relatedProject', type: 'Demand', nullable: true},
        {path: 'analyticalMesh', type: 'AnalyticalMesh', link: 'MTM'},
        'DemandCategory',
        {type: 'Category', nullable: true}, // to have access to the demand's categoryID directly, usefull for filters
        'DemandNature',
        {path: 'totalAmount', type: 'decimal', nullable: true},
        {path: 'largeImputationsTotalAmount', type: 'decimal', nullable: true},
        {path: 'startMonth', type: 'month'},
        {path: 'endMonth', type: 'month'},
        {
            path: 'imputations',
            type: 'Imputation',
            link: {
                type: "OTM",
                onParent: true,     //parent est demand
                onChild: true,     //child est imputation
            }
        },
        {
            path: 'largeImputations',
            type: 'LargeImputation',
            link: {
                type: "OTM",
                onParent: true,
                onChild: false,
            }
        },
        {
            path: 'workflow',
            list: true
        },
        {path: 'contributorsFunctions', type: 'Role', link: 'MTM'}, //fonctions des intervenants
        {path: 'arbitratorsFunctions', type: 'Role', link: 'MTM'},
        {
            path: 'consultantsFunctions',
            type: 'Role',
            link: 'MTM',
        },
        {
            path: 'allArbitratorsFunctions',
            type: 'Role',
            link: 'MTM',
        },
        {
            path: 'delegationHistory',
            type: 'User',
            link: 'MTM',
        },
        {path: 'followFunctions', type: 'Role', link: 'MTM'}, // n'est pas encore utilisé
        {path: 'alreadyTreatedByFunctions', type: 'object', nullable: true}, // les fonctions qui ont déja traité la demande dans l'étape actuelle.
        {path: 'plafond', type: 'MinMaxAmount'},
        {
            path: 'buttons',
            $f: function (object, context, callback) {
                if (context.module && ['arbitration', 'DemandArbitration'].includes(context.module.name) ){
                    return callback(null, buttonsIfArbitrator)
                } else {
                    async.parallel([
                        callback => global.app.SE.Habilitation.find(
                            {
                                ...basicContext(context),
                                fieldPath: ['role.id'],
                                query: {
                                    user: {$eq: global.ObjectID(context.user.id)},
                                }
                            }, callback),
                        callback => {
                            global.app.SE.Habilitation.find({
                                ...basicContext(context),
                                fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                                query: {
                                    user: global.ObjectID(context.user.id),
                                }
                            }, (e, userHabilitations) => {
                                if (e) {
                                    return callback(e)
                                }
                                if (!userHabilitations.length) {
                                    return callback(null, [])
                                } else {
                                    let userGrantedOrgsAndMeshes = []   //array of arrays
                                    userHabilitations.forEach(hab => {
                                        userGrantedOrgsAndMeshes.push(hab.grantedAccess)
                                        userGrantedOrgsAndMeshes.push(hab.grantedMesh)
                                    })
                                    const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)
                                    if (!userGrantedOrgsAndMeshesIDs.length) {
                                        return callback(null, [])
                                    } else {
                                        global.app.SE.OrganizationalMesh.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: [],
                                                query: {
                                                    $or: [
                                                        {"attachments": {$elemMatch: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}},
                                                        {_id: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                                    ]
                                                }
                                            }, (er, meshes) => {
                                                if (er) return callback(er)
                                                let userGrantedOrgsAndMeshesPerRole = {}
                                                userHabilitations.forEach(hab => {
                                                    let orgsAndMeshes = []
                                                    orgsAndMeshes.push(hab.grantedAccess)
                                                    orgsAndMeshes.push(hab.grantedMesh)
                                                    userGrantedOrgsAndMeshesPerRole[hab.role.id] = orgsAndMeshes.flat(1).map(org => org.id)
                                                })
                                                if (!!meshes.length) {
                                                    meshes.forEach(mesh => {
                                                        Object.keys(userGrantedOrgsAndMeshesPerRole).forEach(role => {
                                                            if (userGrantedOrgsAndMeshesPerRole[role].includes(mesh.id)) {
                                                                userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                                mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                            } else if (mesh.attachments.some(orgId => userGrantedOrgsAndMeshesPerRole[role].includes(orgId.toString()))) {
                                                                userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                                mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                            }
                                                        })
                                                    })
                                                }
                                                callback(er, userGrantedOrgsAndMeshesPerRole)
                                            }
                                        )
                                    }
                                }
                            })
                        }
                    ], (error, results) => {
                        if (error) return callback(error)
                        const [ userHabilitations, userGrantedOrgsAndMeshesPerRole] = results
                        const userIsInCurrentContributors = object.currentContributors.map(user => user.id).includes(context.user.id)
                        const userIsInDelegateTo = object.delegateTo.map(user => user.id).includes(context.user.id)

                        if ((!userHabilitations.length && !userIsInCurrentContributors && !userIsInDelegateTo)) {
                            return callback(null, [returnButton])
                        } else {
                            const userRoles = userHabilitations.map(hab => hab.role.id)
                            const userRoleAndAugmentedHabOk = userRoles.some(roleID => object.contributorsFunctions.map(role => role.id).includes(roleID) && (userHaveExhaustifScope(userHabilitations) || (userGrantedOrgsAndMeshesPerRole[roleID] && userGrantedOrgsAndMeshesPerRole[roleID].includes(object.organizationAndMesh.id.toString()))))

                            if (userRoleAndAugmentedHabOk || userIsInCurrentContributors || userIsInDelegateTo) {
                                const moduleName = context.module && context.module.name
                                if (isItFirstStepFirstOrder(moduleName, object.workflow[0], parseInt(object.workflow[1]))) {
                                    return callback(null, buttonsIfFirstDemander)
                                } else if (isItDraft(object.workflow[0], parseInt(object.workflow[1]))) {
                                    return callback(null, buttonsIfDraft)
                                } else {
                                    const userIsInThisStep = steps.find(step => step.name === object.workflow[0])
                                    return callback(null, userIsInThisStep.buttons)
                                }
                            } else {
                                return callback(null, [returnButton])
                            }
                        }
                    })
                }
            }
        },
        {
            path: "greenStyledRow",
            fieldPath: ['workflow', 'organizationAndMesh', 'delegateTo', 'currentContributors', 'contributorsFunctions'],
            $f: function (demand, context, callback) {
                let moduleStep
                // todo, verify added cases
                switch (context.module.name) {
                    case 'preValidation':
                        moduleStep = ['preValidation', 'draft']
                        break;
                    case 'Instruction':
                        moduleStep = ['demand']
                        break;
                    case 'DemandInstruction':
                        moduleStep = ['demand', 'draft']
                        break;
                    case 'Control':
                    case 'DemandControl':
                        moduleStep = ['control']
                        break;
                    case 'Validation':
                    case 'DemandValidation':
                        moduleStep = ['validation']
                        break;
                    case 'Realization':
                        moduleStep = ['realization']
                        break;
                    case 'arbitration':
                    case 'DemandArbitration':
                        moduleStep = ['arbitration']
                        break;
                    default:
                        moduleStep = ['unknown']
                }
                if ( moduleStep[0] === 'arbitration'){
                    return callback(null, true)
                }
                else {
                    getUserHabsAndGrantedOrgsAndMeshesPerRole(context, (error, userHabilitations, userGrantedOrgsAndMeshesPerRole) => {
                        const stepMatch =  moduleStep.includes(demand.workflow[0]) || ['Consultation', 'DemandConsultation'].includes(context.module.name)
                        const userInDelegationList = demand.delegateTo.map(o => o.id).includes(context.user.id)
                        const userInContributionList = demand.currentContributors.map(o => o.id).includes(context.user.id)

                        const noHabsCondition = stepMatch && //largeImputationsCondition &&
                            (userInDelegationList || userInContributionList)

                        if (!userHabilitations.length) {
                            return callback(null, noHabsCondition)
                        }

                        return callback(null, noHabsCondition || userHabilitations.some(hab => {

                            const habRoleInContributionList = demand.contributorsFunctions.map(o => o.id).includes(hab.role.id)
                            const habRoleInArbitrationList = demand.arbitratorsFunctions.map(o => global.ObjectID(o.id).toString()).includes(hab.role.id.toString())
                            const withHabsCondition = stepMatch && //largeImputationsCondition &&
                                ( habRoleInContributionList || habRoleInArbitrationList )
                            if (!hab.grantedAccess.length && !hab.grantedMesh.length) {
                                return withHabsCondition
                            }
                            const orgAndMeshInUserGrantedOrgsAndMeshes = userGrantedOrgsAndMeshesPerRole[hab.role.id].includes(demand.organizationAndMesh.id)
                            return withHabsCondition && orgAndMeshInUserGrantedOrgsAndMeshes
                        }))
                    })
                }
            }
        },
        {
            path: "noDeleteButtonAccess",
            $f: function (object, context, callback) {
                if (context.module && ['preValidation', 'DemandInstruction'].includes(context.module.name) ) {
                    const userIsInCurrentContributors = !!object.currentContributors && !!object.currentContributors.length && object.currentContributors.map(user => JSON.stringify(global.ObjectID(user.id))).includes(JSON.stringify(global.ObjectID(context.user.id)))
                    if (userIsInCurrentContributors && object.workflow[0] === "draft") {
                        callback(null, false)
                    } else {
                        callback(null, true)
                    }
                }
                else {
                    callback(null, true)
                }
            }
        },
        {
            path: 'sendTo',
            type: 'User',
            link: 'MTM',
            nullable: true
        },
        {
            path: 'sendToOthers',
        },
        {path: 'synthesis'},
        {
            path: 'news'
        },
        {path: 'deadline'},
        {path: 'cost'},
        {path: 'treasury'},
        {path: 'committeeMinutes'},
        {path: 'committeeMinutesP'},
        {path: 'revueDate', type: 'date'},
        {path: 'projectCommitteeLastUpdate', type: 'date', nullable: true, dateFormat: 'YYYY-MM-DD HH:mm'},
        {path: 'pilotageCommitteeLastUpdate', type: 'date', nullable: true, dateFormat: 'YYYY-MM-DD HH:mm'},
        {path: 'projectCommitmentLastUpdate', type: 'date', nullable: true, dateFormat: 'YYYY-MM-DD HH:mm'},
        {path: 'projectCommitmentLastUpdatedBy', type: 'User', nullable: true},
        {path: 'meetingsPlanning', type: 'MeetingsPlanning', nullable: true},
        {
            path: 'sendToP',
            type: 'User',
            link: 'MTM',
            nullable: true
        },
        {
            path: 'sendToOthersP',
        },
        {path: 'synthesisP'},
        {
            path: 'newsP'
        },
        {path: 'deadlineP'},
        {path: 'costP'},
        {path: 'treasuryP'},
        {path: 'revueDateP', type: 'date'},
        {
            path: 'arbitrateText',
        },
        {
            path: 'arbitrateCheckBox',
            type: 'boolean'
        },
        {
            path: 'arbitrateTextList',
            type: 'ArbitrateTextList',
            link: 'OTM',
            nullable: true
        },
        {
            path: 'arbitorNotYetActive',
            type: 'boolean',
            nullable: true
        },
        {
            path: 'arbitrationType',
            type: 'ArbitrationType',
            nullable: true
        },
        {
            type: 'DelegationOption',
            nullable: true
        },
        /*
        {
            path: 'totalEstimated',
            type: "decimal"
        },
        {
            path: 'totalEngaged',
            type: "decimal"
        },
        {
            path: 'totalRevised',
            type: "decimal"
        },

         */
        {
            path: 'revisedTable',
            type: 'RevisedTable',
            link: {
                type: "OTM",
                onParent: true,
                onChild: false,
            },
        },
        {
            path: 'revisedTableHistory',
            type: 'RevisedTableHistory',
            link: {
                type: "OTM",
                onParent: true,
                onChild: false,
            }
        },
        {
            path: 'completeRevisedTableHistory',
            type: 'CompleteRevisedTableHistory',
            link: {
                type: "OTM",
                onParent: true,
                onChild: true,
            }
        },
        {
            path: 'followTable2',
            type: 'FollowTable2',
            lazy: true,
            fieldPath: [],
            list: true,
        },
        {
            path: 'displayEngagementData',
            $f: function (object, context, callback) {
                global.app.SE.DemandCategory.collection.findOne(
                    {
                        category: '2',
                        display: true,
                        group: global.ObjectID(context.group.id)
                    },
                    (error, category) => callback(error, !!category)
                )
            }
        }
    ],
    filters: [
        { //verified
            name: "byFiscalYear",
            isDefault: false,
            query: function(context) {
                global.app.SE.Demand.find({
                    ...basicContext(context),
                    fieldPath: ['imputations'],
                    query: {
                        category: "1"
                    }
                }, (e, projects) => {

                })
            }
        },
        { //verified
            name: "byProjectType",
            isDefault: false,
            query: async function(context) {
                const projectTypes = _.get(context.data, 'projectTypes')
                const fiscalYears = _.get(context, 'data.fiscalYear', [])
                const ongoingValidationStatusesIds = ['1', '2', '3', '4', '5', '10', '11', '12']
                const postValidationStatusesIds = ['6', '7', '8']
                //we gonna treat 9 (abandoned differently)
                const abandonedStatusQuery1 = {
                    status: '9',
                    revisedTable: {$exists: true},
                }
                const abandonedStatusQuery2 = {
                    status: '9',
                    revisedTable: {$exists: false}
                }

                const queryForProjectType = !!projectTypes && !!projectTypes.length && projectTypes.length === 1 ?
                    projectTypes[0].id === '2' ?
                        {
                            $or: [{status: {$in: ongoingValidationStatusesIds}}, abandonedStatusQuery2],
                        }
                        : {$or: [{status: {$in: postValidationStatusesIds}}, abandonedStatusQuery1]}
                    : {}

                const projects = await global.app.SE.Demand.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {category: '1'}}
                )
                let filteredProjectIDs = []
                projects.forEach(project => {
                    const startMonth = project.startMonth
                    const endMonth = project.endMonth
                    const fiscalYearForThisDemand = fiscalYears.filter(fiscalYear => {
                        const fiscalYearStartDate = moment(fiscalYear.fiscalYearRange[0])
                        const fiscalYearEndDate = moment(fiscalYear.fiscalYearRange[1])
                        const firstCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                        const secondCondition = fiscalYearStartDate.isSameOrAfter(startMonth, 'month') && fiscalYearEndDate.isSameOrBefore(endMonth, 'month')
                        const thirdCondition = fiscalYearStartDate.isSameOrBefore(endMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                        const fourthCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(startMonth, 'month')
                        return firstCondition || secondCondition || thirdCondition || fourthCondition
                    })
                    const isInFiscalYears = fiscalYearForThisDemand.map(FY=>global.ObjectID(FY.id).toString())
                        .some(id => fiscalYears.map(fy => global.ObjectID(fy.id).toString()).includes(id))
                    if (isInFiscalYears){
                        filteredProjectIDs.push(global.ObjectID(project.id))
                    }
                })
                const queryForFiscalYears = !!fiscalYears.length ? {_id: {$in: filteredProjectIDs}} : {}
                return {
                    ...queryForProjectType,
                    ...queryForFiscalYears
                }
            }
        },
        {
            name: "byRelatedProject",
            isDefault: false,
            query: function (context) {
                const relatedProjectID = _.get(context, 'data.relatedProjectID')
                return !!relatedProjectID ? {relatedProject: {$eq: global.ObjectID(relatedProjectID)}} : {relatedProject: {$eq: null}}
            }
        },
        {
            name: "byOrganization",
            isDefault: false,
            query: function (context) {
                const projectOrganizationID = _.get(context, 'data.projectOrganizationID')
                return !!projectOrganizationID ? {organizationAndMesh: {$eq: global.ObjectID(projectOrganizationID)}} : {}
            }
        },
        {
            name: "isProject",
            isDefault: false,
            query: function () {
                return {category: "1"}
            }
        },
        {
            name: "approvedOrInRealization",
            isDefault: false,
            query: function () {
                return { "workflow.0": {$in: ["approved", "realization"]} }
            }
        },
        {
            name: "inRealization",
            isDefault: false,
            query: function () {
                return { "workflow.0": {$eq: "realization"} }
            }
        },
        {
            name: "isDemand",
            isDefault: false,
            query: function () {
                return {category: "2"}
            }
        },
        {
            name: "isApproved",
            isDefault: false,
            query: function () {
                return { "workflow.0": {$eq: "approved"} }
            }
        },
        {
            name: "noRelatedDemand",
            isDefault: false,
            query: function () {
                return {relatedDemand: null}
            }
        },
        {
            name: "noRelatedProject",
            isDefault: false,
            query: function () {
                return {relatedProject: null}
            }
        },
        {
            name: "inPilotageCommittee",
            isDefault: false,
            query: function (context) {
                const userID = _.get(context, 'user.id')
                return userID ? {
                    "pilotageCommitteeMembers": global.ObjectID(userID),
                    'workflow': {$elemMatch: {$eq: "realization"}}
                } : {_id: "0"}
            }
        },
        {
            name: "inProjectCommittee",
            isDefault: false,
            query: function (context) {
                const userID = _.get(context, 'user.id')
                return userID ? {
                    "projectCommitteeMembers": global.ObjectID(userID),
                    'workflow': {$elemMatch: {$eq: "realization"}}
                } : {_id: "0"}
            }
        },
        {
            name: "myArbitration",
            isDefault: false,
            async: true,
            query: function (context, callback) {
                async.parallel([
                    callback => global.app.SE.Habilitation.find(
                        {
                            ...basicContext(context),
                            fieldPath: ['role.id'],
                            query: {
                                user: {$eq: global.ObjectID(context.user.id)},
                            }
                        }, callback),
                    callback => {
                        global.app.SE.Habilitation.find({
                            ...basicContext(context),
                            fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                            query: {user: global.ObjectID(context.user.id)}
                        }, (e, userHabilitations) => {
                            if (e) {
                                return callback(e)
                            }
                            if (!userHabilitations.length) {
                                return callback(null, [])
                            } else {
                                let userGrantedOrgsAndMeshes = []   //array of arrays
                                userHabilitations.forEach(hab => {
                                    userGrantedOrgsAndMeshes.push(hab.grantedAccess)
                                    userGrantedOrgsAndMeshes.push(hab.grantedMesh)
                                })
                                const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)
                                if (!userGrantedOrgsAndMeshesIDs.length) {
                                    return callback(null, [])
                                } else {
                                    global.app.SE.OrganizationalMesh.find(
                                        {
                                            ...basicContext(context),
                                            fieldPath: [],
                                            query: {
                                                $or: [
                                                    {"attachments": {$elemMatch: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}},
                                                    {_id: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                                ]
                                            }
                                        }, (er, meshes) => {
                                            if (er) return callback(er)
                                            let userGrantedOrgsAndMeshesPerRole = {}
                                            userHabilitations.forEach(hab => {
                                                let orgsAndMeshes = []
                                                orgsAndMeshes.push(hab.grantedAccess)
                                                orgsAndMeshes.push(hab.grantedMesh)
                                                userGrantedOrgsAndMeshesPerRole[hab.role.id] = orgsAndMeshes.flat(1).map(org => org.id)
                                            })
                                            if (!!meshes.length) {
                                                meshes.forEach(mesh => {
                                                    Object.keys(userGrantedOrgsAndMeshesPerRole).forEach(role => {
                                                        if (userGrantedOrgsAndMeshesPerRole[role].includes(mesh.id)) {
                                                            userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                            mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                        } else if (mesh.attachments.some(orgId => userGrantedOrgsAndMeshesPerRole[role].includes(orgId.toString()))) {
                                                            userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                            mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                        }
                                                    })
                                                })
                                            }
                                            callback(er, userGrantedOrgsAndMeshesPerRole)
                                        }
                                    )
                                }
                            }
                        })
                    }
                ], (error, results) => {
                    if (error) return callback(error)
                    const [userHabilitations, userGrantedOrgsAndMeshesPerRole] = results
                    if (!!userHabilitations.length) {
                        let categoryQuery = {}
                        let moduleName = _.get(context, 'module.name')
                        if ( moduleName === 'arbitration'){
                            categoryQuery = { 'category' : { $eq: '1'} }
                        }
                        else if (moduleName === 'DemandArbitration'){
                            categoryQuery = { 'category' : { $eq: '2'} }
                        }
                        let queries = []
                        userHabilitations.forEach(hab => {
                            if (!hab.grantedAccess.length && !hab.grantedMesh.length) { // exhaustif scope
                                queries.push({
                                    arbitratorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                    ...categoryQuery
                                })
                            } else {
                                queries.push({
                                    arbitratorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                    organizationAndMesh: {$in: userGrantedOrgsAndMeshesPerRole[hab.role.id].map(orgID => global.ObjectID(orgID))},
                                    ...categoryQuery
                                })
                            }
                        })
                        return callback(null, {
                                $or: queries
                            }
                        )
                    } else {
                        return callback(error, {_id: "0"})
                    }
                })
            }
        },
        {
            name: "byModuleStep",
            // todo, add category query in all queries
            isDefault: false,
            async: true,
            query: function (context, callback) {
                let moduleStep
                let projectOrDemand = '1'  // project by default
                switch (context.module.name) {
                    case 'preValidation':
                        moduleStep = ['preValidation', 'draft']
                        break;
                    case 'Instruction':
                        moduleStep = ['demand']
                        break;
                    case 'DemandInstruction':
                        moduleStep = ['demand', 'draft']
                        projectOrDemand = '2'
                        break;
                    case 'Control':
                        moduleStep = ['control']
                        break;
                    case 'DemandControl':
                        moduleStep = ['control']
                        projectOrDemand = '2'
                        break;
                    case 'Validation':
                        moduleStep = ['validation']
                        break;
                    case 'DemandValidation':
                        moduleStep = ['validation']
                        projectOrDemand = '2'
                        break;
                    case 'Realization':
                        moduleStep = ['realization']
                        break;
                    default:
                        moduleStep = ['unknown']
                }

                const stepQuery = {workflow: {$elemMatch: {$in: moduleStep}}}

                getUserHabsAndGrantedOrgsAndMeshesPerRole(context, (error, userHabilitations, userGrantedOrgsAndMeshesPerRole) => {
                    if (error) return callback(error)
                    if (!userHabilitations.length) {
                        return callback(error, {
                            $or: [
                                {
                                    ...stepQuery,
                                    delegateTo: {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                                    category : projectOrDemand
                                },
                                {
                                    ...stepQuery,
                                    currentContributors: {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                                    category : projectOrDemand
                                },
                                {
                                    ...stepQuery,
                                    delegationHistory: {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                                    category : projectOrDemand
                                },
                            ],
                        })
                    }
                    let queries = []

                    userHabilitations.forEach(hab => {
                        if (!hab.grantedAccess.length && !hab.grantedMesh.length) { // exhaustif scope
                            queries.push({
                                contributorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                category : projectOrDemand,
                                ...stepQuery,
                            })
                            queries.push({ //consultants query
                                consultantsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                "workflow.0": {$ne: "draft"},
                                category : projectOrDemand,
                                ...stepQuery,
                            })
                        } else {
                            queries.push({
                                contributorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                organizationAndMesh: {$in: userGrantedOrgsAndMeshesPerRole[hab.role.id].map(orgID => global.ObjectID(orgID))},
                                category : projectOrDemand,
                                ...stepQuery,
                            })
                            queries.push({
                                consultantsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                organizationAndMesh: {$in: userGrantedOrgsAndMeshesPerRole[hab.role.id].map(orgID => global.ObjectID(orgID))},
                                "workflow.0": {$ne: "draft"},
                                category : projectOrDemand,
                                ...stepQuery
                            })
                        }
                    })
                    queries.push({
                        'delegateTo': {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                        category : projectOrDemand,
                        ...stepQuery
                    })
                    queries.push({
                        'delegationHistory': {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                        category : projectOrDemand,
                        ...stepQuery
                    })
                    queries.push({
                        'currentContributors': {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                        category : projectOrDemand,
                        ...stepQuery
                    })
                    return callback(null, {$or: queries})
                })
            }
        },
        {
            name: "byFiscalYear",
            isDefault: false,
            client: true,
            path: "year",
            object: "FiscalYearFilter",
            display: "name",
            fieldPath: ["name"],
            default: {id: moment().year()},
            query: function (context) {
                const year = _.get(context, 'data.year.id')
                const startMonth = `${year}-01`
                const endMonth = `${year}-12`
                return year
                    ? {startMonth: {$lte: endMonth}, endMonth: {$gte: startMonth}}
                    : {}
            }
        },
        {
            name: "myConsultations",
            tKey: 'statut',
            isDefault: false,
            client: true,
            path: 'demandStatuses',
            object: 'DemandStatus',
            display: 'name2',
            clearable: true,
            fieldPath: ['name', 'name2'],
            async: true,
            type: 'tags',
            default: [{id: "6"}, {id: '8'}],
            query: function (context, callback) {
                const selectedStatuses = _.get(context, 'data.demandStatuses') || []
                async.parallel([
                    callback => global.app.SE.Habilitation.find(
                        {
                            ...basicContext(context),
                            fieldPath: ['role.id'],
                            query: {
                                user: {$eq: global.ObjectID(context.user.id)},
                            }
                        }, callback),
                    callback => {
                        global.app.SE.Habilitation.find({
                            ...basicContext(context),
                            fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                            query: {user: global.ObjectID(context.user.id)}
                        }, (e, userHabilitations) => {
                            if (e) {
                                return callback(e)
                            }
                            if (!userHabilitations.length) {
                                return callback(null, [])
                            } else {
                                let userGrantedOrgsAndMeshes = []   //array of arrays
                                userHabilitations.forEach(hab => {
                                    userGrantedOrgsAndMeshes.push(hab.grantedAccess)
                                    userGrantedOrgsAndMeshes.push(hab.grantedMesh)
                                })
                                const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)
                                if (!userGrantedOrgsAndMeshesIDs.length) {
                                    return callback(null, [])
                                } else {
                                    global.app.SE.OrganizationalMesh.find(
                                        {
                                            ...basicContext(context),
                                            fieldPath: [],
                                            query: {
                                                $or: [
                                                    {"attachments": {$elemMatch: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}},
                                                    {_id: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                                ]
                                            }
                                        }, (er, meshes) => {
                                            if (er) return callback(er)
                                            let userGrantedOrgsAndMeshesPerRole = {}
                                            userHabilitations.forEach(hab => {
                                                let orgsAndMeshes = []
                                                orgsAndMeshes.push(hab.grantedAccess)
                                                orgsAndMeshes.push(hab.grantedMesh)
                                                userGrantedOrgsAndMeshesPerRole[hab.role.id] = orgsAndMeshes.flat(1).map(org => org.id)
                                            })
                                            if (!!meshes.length) {
                                                meshes.forEach(mesh => {
                                                    Object.keys(userGrantedOrgsAndMeshesPerRole).forEach(role => {
                                                        if (userGrantedOrgsAndMeshesPerRole[role].includes(mesh.id)) {
                                                            userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                            mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                        } else if (mesh.attachments.some(orgId => userGrantedOrgsAndMeshesPerRole[role].includes(orgId.toString()))) {
                                                            userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                            mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                        }
                                                    })
                                                })
                                            }
                                            callback(er, userGrantedOrgsAndMeshesPerRole)
                                        }
                                    )
                                }
                            }
                        })
                    }
                ], (error, results) => {
                    if (error) return callback(error)
                    const [userHabilitations, userGrantedOrgsAndMeshesPerRole] = results
                    if (!!userHabilitations.length) {
                        const userRoles = userHabilitations.map(hab => global.ObjectID(hab.role.id))
                        let readersQueries = []
                        userHabilitations.forEach(hab => {
                            if (!hab.grantedAccess.length && !hab.grantedMesh.length) { // exhaustif scope
                                readersQueries.push({
                                    readers: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                })
                            } else {
                                readersQueries.push({
                                    readers: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                    organizationAndMesh: {$in: userGrantedOrgsAndMeshesPerRole[hab.role.id].map(orgID => global.ObjectID(orgID))},
                                })
                            }
                        })
                        return global.app.SE.WorkFlow.find(
                            {
                                ...basicContext(context),
                                fieldPath: [],
                                query: {
                                    $or: readersQueries
                                }
                            }, (e, workflows) => {
                                if (e) return callback(e)
                                let queries = []
                                let auxiliaryQuery = !!selectedStatuses.length ? { 'status' : { $in: selectedStatuses.map( status => status.id )} } : {}
                                let moduleName = _.get(context, 'module.name')
                                let categoryQuery = {}
                                if ( moduleName === 'Consultation'){
                                    categoryQuery = { 'category' : { $eq: '1'} }
                                }
                                else if (moduleName === 'DemandConsultation'){
                                    categoryQuery = { 'category' : { $eq: '2'} }
                                }
                                if (workflows && workflows.length !== 0) {
                                    workflows.forEach(wf => {
                                        queries.push(
                                            {
                                                'organizationAndMesh': {$in: wf.organizationAndMesh.map(org => global.ObjectID(org.id))},
                                                'demandCategory': {$eq: global.ObjectID(wf.demandCategory.id)},
                                                'demandNature': {$in: wf.demandNature.map(type => global.ObjectID(type.id))},
                                                'plafond': {$in: wf.minMaxAmount.map(minMax => global.ObjectID(minMax.id))},
                                                'workflow.0': {$ne: "draft"}, //{$ne: "draft"}, // {$elemMatch: {$in: ["control", "validation", "approved", "refused"]}}
                                                ...auxiliaryQuery,
                                                ...categoryQuery
                                            }
                                        )
                                    })
                                }
                                userHabilitations.forEach(hab => {
                                    if (!hab.grantedAccess.length && !hab.grantedMesh.length) { // exhaustif scope
                                        queries.push({
                                            contributorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                            ...auxiliaryQuery,
                                            ...categoryQuery
                                        })
                                        queries.push({
                                            consultantsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                            "workflow.0": {$ne: "draft"},
                                            ...auxiliaryQuery,
                                            ...categoryQuery
                                        })
                                        queries.push({
                                            allArbitratorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                            "workflow.0": {$ne: "draft"},
                                            ...auxiliaryQuery,
                                            ...categoryQuery
                                        })
                                    } else {
                                        queries.push({
                                            contributorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                            organizationAndMesh: {$in: userGrantedOrgsAndMeshesPerRole[hab.role.id].map(orgID => global.ObjectID(orgID))},
                                            ...auxiliaryQuery,
                                            ...categoryQuery
                                        })
                                        queries.push({
                                            consultantsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                            "workflow.0": {$ne: "draft"},
                                            organizationAndMesh: {$in: userGrantedOrgsAndMeshesPerRole[hab.role.id].map(orgID => global.ObjectID(orgID))},
                                            ...auxiliaryQuery,
                                            ...categoryQuery
                                        })
                                        queries.push({
                                            allArbitratorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                                            "workflow.0": {$ne: "draft"},
                                            organizationAndMesh: {$in: userGrantedOrgsAndMeshesPerRole[hab.role.id].map(orgID => global.ObjectID(orgID))},
                                            ...auxiliaryQuery,
                                            ...categoryQuery
                                        })
                                    }
                                })
                                queries.push({
                                    'delegateTo': {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                                    ...auxiliaryQuery,
                                    ...categoryQuery
                                })
                                queries.push({
                                    'currentContributors': {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                                    ...auxiliaryQuery,
                                    ...categoryQuery
                                })
                                queries.push({
                                    'delegationHistory': {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                                    ...auxiliaryQuery,
                                    ...categoryQuery
                                })
                                return callback(null, {$or: queries})
                            }
                        )
                    } else { // if user with no habilitaion
                        let auxiliaryQuery = !!selectedStatuses.length ? { 'status' : { $in: selectedStatuses.map( status => status.id )} } : {}
                        let categoryQuery = {}
                        let moduleName = _.get(context, 'module.name')
                        if ( moduleName === 'Consultation'){
                            categoryQuery = { 'category' : { $eq: '1'} }
                        }
                        else if (moduleName === 'DemandConsultation'){
                            categoryQuery = { 'category' : { $eq: '2'} }
                        }
                        return callback(error, {
                            $or: [
                                {
                                    delegateTo: {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                                    ...auxiliaryQuery,
                                    ...categoryQuery
                                },
                                {
                                    currentContributors: {$elemMatch: {$eq: global.ObjectID(context.user.id)}},
                                    ...auxiliaryQuery,
                                    ...categoryQuery
                                }
                            ],
                        })
                    }
                })
            }
        }
    ],
    uniqueProcess: function ( demandOrganizationID, relatedDemand, relatedProject, imputations, context, callback) {
        if (context.module && context.module.name === 'DemandInstruction' ){
            const relatedDemandOrgID = relatedDemand?.organizationAndMesh?.id
            const relatedProjectOrgID = relatedProject?.organizationAndMesh?.id

            const relatedDemandOrgIDIsOk = !relatedDemandOrgID || relatedDemandOrgID === demandOrganizationID
            const relatedProjectOrgIDIsOk = !relatedProjectOrgID || relatedProjectOrgID === demandOrganizationID
            console.log("relatedDemandOrgIDIsOk", imputations.map( imputation => imputation.budget.mesh.id), imputations.map( imputation => imputation.organizationalMesh.id))

            if (!relatedDemandOrgIDIsOk){
                return callback(new Errors.ValidationError('Related demand should have same organization as your demand'))
            } else if (!relatedProjectOrgIDIsOk){
                return callback(new Errors.ValidationError('Related project should have same organization as your demand'))
            }
            else{
                return global.app.SE.OrganizationalMesh.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {
                        attachments : {$elemMatch: {$eq: global.ObjectID(demandOrganizationID)}}
                    }
                }, (e, meshes) => {
                    if (e) return callback(e)
                    if (!!meshes.length){
                        const imputationsBU = imputations.map( imputation => imputation.organizationalMesh.id)
                        const imputationsBudgetsBU = imputations.map( imputation => imputation.budget.mesh.id)
                        const meshesIDs = meshes.map( mesh => mesh.id)

                        const imputationsMeshesIsOk = imputationsBU.every( id => meshesIDs.includes(id))
                        const imputationsBudgetsIsOk = imputationsBudgetsBU.every( id => meshesIDs.includes(id))

                        if (!imputationsMeshesIsOk){
                            return callback(new Errors.ValidationError('All imputations BU should be attached to the selected organization'))
                        } else if (!imputationsBudgetsIsOk){
                            return callback(new Errors.ValidationError('All imputations budgets BU should be attached to the selected organization'))
                        }
                        else{
                            return callback()
                        }
                    }
                    else{
                        return callback(new Errors.ValidationError('No business unit is attached to the selected organization'))
                    }
                })
            }
        }
        callback()
    },
    validateSave: function (newObject, oldObject, context, callback) {
        this.uniqueProcess(newObject.organizationAndMesh.id, newObject.relatedDemand, newObject.relatedProject, newObject.imputations, context, callback)
    },
    ps: {
        context: [{
            $$u: function (context, callback) {
                const isPrincipalProjectOrDemand = context?.module?.category?.path === 'Projet' && !context.clientObject?.relatedProject || context?.module?.category?.path === 'demande' && !context.clientObject?.relatedDemand
                if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C" && isPrincipalProjectOrDemand) {
                    context.internalFieldPath = [
                        ...new Set([
                            ...context.internalFieldPath,
                            "sequence"
                        ])
                    ]
                }
                callback(null, context)
            }
        }]
    },
    beforeSave: function (newObject, oldObject, context, callback) {
        context.tl('word', 'language')
        /*
        if ( !!newObject.comments && !!newObject.comments.length){
            newObject.comments.forEach( comment => {
                const commentWords = comment.text.split(',')
                const isAutomaticComment = commentWords.length === 2
                if (isAutomaticComment) {
                    const nonTranslatedComment = oldObject.comments.find(obj => obj.id.toString() === comment.id.toString())
                    console.log('t', oldObject.comments, newObject.comments)
                    comment = nonTranslatedComment
                    console.log('t', comment.text)
                }
            })
        }

         */
        if (context.module && context.module.name === 'preValidation') {
            const filteredLargeImputations = newObject.largeImputations.filter(row => row.amount !== 0)
            newObject.largeImputations = filteredLargeImputations
        }
        if (context.module && context.module.name === 'Realization'){
            const removedTotalLineRevisedTable = newObject.revisedTable.filter(obj => !(obj.objet === 'Tot.' && obj.entity === null && obj.imputationType === null))
            const removedTotalLineRevisedTableHistory = newObject.revisedTableHistory.filter(obj => !(obj.objet === 'Tot.' && obj.entity === null && obj.imputationType === null))
            removedTotalLineRevisedTable.forEach(line => {
                const oldLine = oldObject.revisedTable.find( row => row.id.toString() === line.id.toString())
                line.budget = oldLine.budget
                line.validated = oldLine.validated
                line.estimated = oldLine.estimated
                line.validatedEngagement = oldLine.validatedEngagement
                line.ongoingFiscalYearValidatedEngagement = oldLine.ongoingFiscalYearValidatedEngagement
                line.ongoingFiscalYearValidatedBudget = oldLine.ongoingFiscalYearBudget
                line.ongoingFiscalYearValidated = oldLine.ongoingFiscalYearValidated
                line.ongoingFiscalYearEstimated = oldLine.ongoingFiscalYearEstimated
            })
            const oldUntouchedLines = oldObject.revisedTable.filter( line => {
                const modifiedLinesIds = removedTotalLineRevisedTable.map(obj => obj.id.toString())
                return !modifiedLinesIds.includes(line.id.toString())
            })
            const oldOldUntouchedLines = oldObject.revisedTableHistory.filter( line => {
                const modifiedLinesIds = removedTotalLineRevisedTableHistory.map(obj => obj.id.toString())
                return !modifiedLinesIds.includes(line.id.toString())
            })
            newObject.revisedTable = [...removedTotalLineRevisedTable, ...oldUntouchedLines]
            newObject.revisedTableHistory = [...removedTotalLineRevisedTableHistory, ...oldOldUntouchedLines]
        }
        if (context.module && context.module.name === 'ProjectCommitment') {
            newObject.projectCommitmentLastUpdate = moment().format('YYYY-MM-DD HH:mm')
            newObject.projectCommitmentLastUpdatedBy = {id: context.user.id}
            return callback(null, newObject, oldObject)
        }
        if (context.module && context.module.name === 'pilotageCommittee') {
            newObject.pilotageCommitteeLastUpdate = moment().format('YYYY-MM-DD HH:mm')
            const removedTotalLineRevisedTable = newObject.revisedTable.filter(obj => !(obj.objet === 'Tot.' && obj.entity === null && obj.imputationType === null))
            const removedTotalLineRevisedTableHistory = newObject.revisedTableHistory.filter(obj => !(obj.objet === 'Tot.' && obj.entity === null && obj.imputationType === null))
            removedTotalLineRevisedTable.forEach(line => {
                const oldLine = oldObject.revisedTable.find( row => row.id.toString() === line.id.toString())
                line.budget = oldLine.budget
                line.validated = oldLine.validated
                line.estimated = oldLine.estimated
                line.validatedEngagement = oldLine.validatedEngagement
                line.ongoingFiscalYearValidatedEngagement = oldLine.ongoingFiscalYearValidatedEngagement
                line.ongoingFiscalYearValidatedBudget = oldLine.ongoingFiscalYearBudget
                line.ongoingFiscalYearValidated = oldLine.ongoingFiscalYearValidated
                line.ongoingFiscalYearEstimated = oldLine.ongoingFiscalYearEstimated
            })
            const oldUntouchedLines = oldObject.revisedTable.filter( line => {
                const modifiedLinesIds = removedTotalLineRevisedTable.map(obj => obj.id.toString())
                return !modifiedLinesIds.includes(line.id.toString())
            })
            const oldOldUntouchedLines = oldObject.revisedTableHistory.filter( line => {
                const modifiedLinesIds = removedTotalLineRevisedTableHistory.map(obj => obj.id.toString())
                return !modifiedLinesIds.includes(line.id.toString())
            })
            newObject.revisedTable = [...removedTotalLineRevisedTable, ...oldUntouchedLines]
            newObject.revisedTableHistory = [...removedTotalLineRevisedTableHistory, ...oldOldUntouchedLines]
            if (context.action === "save") {
                return callback(null, newObject, oldObject)
            } else if (context.action === "send") {
                if (!!newObject.sendToP && !!newObject.sendToP.length){
                    return global.app.SE.Currency.collection.findOne({
                        //group
                        nature: '1',
                    }, (error, currency) => {
                        if(error) return callback(error)
                        if (!!currency){
                            global.pdf.generatePdf(CrPdfConfig(context.module.name, newObject, context, currency), (error, file) => {
                                newObject.pilotageCommitteeFiles.push({
                                    user: _.pick(context.user, ['id', 'name']),
                                    date: moment().format("YYYY-MM-DD HH:mm"),
                                    ...file
                                })
                                const mails = newObject.sendToP.map(user => {
                                    return {
                                        from: 'support@keenpoint.com',
                                        to: decrypt(user.mail),
                                        subject: {template: `Comité de pilotage du ${moment.utc(newObject.revueDateP, 'YYYY-MM-DD').format('DD-MM-YYYY')} : Compte-rendu réalisation du projet N° : ${newObject.demandNumber}`},
                                        content: {html: "<div><p>Bonjour,</p></div><div><p>Veuillez trouver ci-joint le compte-rendu réalisation du comité pilotage.</p></div><div><p>Cordialement,</p><p>Keenpoint Consulting</p></div>"},
                                        attachments: [file],
                                        verbose: {
                                            general: true,
                                        }
                                    }
                                }) || []
                                if (!!newObject.sendToOthersP.length) {
                                    newObject.sendToOthersP.split(';').map(str => str.trim()).forEach(mail => {
                                        mails.push(
                                            {
                                                from: 'support@keenpoint.com',
                                                to: mail,
                                                subject: {template: `Comité de pilotage du ${moment.utc(newObject.revueDateP, 'YYYY-MM-DD').format('DD-MM-YYYY')} : Compte-rendu réalisation du projet N° : ${newObject.demandNumber}`},
                                                content: {html: "<div><p>Bonjour,</p></div><div><p>Veuillez trouver ci-joint le compte-rendu réalisation du comité pilotage.</p></div><div><p>Cordialement,</p><p>Keenpoint Consulting</p></div>"},
                                                attachments: [file],
                                                verbose: {
                                                    general: true,
                                                }
                                            }
                                        )
                                    })
                                }
                                global.mailer.sendMail(mails, (error) => {
                                    callback(error, newObject, oldObject)
                                })
                            })
                        }
                        else{
                            return callback(new Errors.ValidationError('You have to create a reference currency first'))
                        }
                    })
                }
                else{
                    return callback(new Errors.ValidationError('You have to select at least one recipient'))
                }
            }
        }
        if (context.module && context.module.name === 'projectCommittee') {
            newObject.projectCommitteeLastUpdate = moment().format('YYYY-MM-DD HH:mm')
            const removedTotalLineRevisedTable = newObject.revisedTable.filter(obj => !(obj.objet === 'Tot.' && obj.entity === null && obj.imputationType === null))
            const removedTotalLineRevisedTableHistory = newObject.revisedTableHistory.filter(obj => !(obj.objet === 'Tot.' && obj.entity === null && obj.imputationType === null))
            removedTotalLineRevisedTable.forEach(line => {
                const oldLine = oldObject.revisedTable.find( row => row.id.toString() === line.id.toString())
                line.budget = oldLine.budget
                line.validated = oldLine.validated
                line.estimated = oldLine.estimated
                line.validatedEngagement = oldLine.validatedEngagement
                line.ongoingFiscalYearValidatedEngagement = oldLine.ongoingFiscalYearValidatedEngagement
                line.ongoingFiscalYearBudget = oldLine.ongoingFiscalYearBudget
                line.ongoingFiscalYearValidated = oldLine.ongoingFiscalYearValidated
                line.ongoingFiscalYearEstimated = oldLine.ongoingFiscalYearEstimated
            })
            const oldUntouchedLines = oldObject.revisedTable.filter( line => {
                const modifiedLinesIds = removedTotalLineRevisedTable.map(obj => obj.id.toString())
                return !modifiedLinesIds.includes(line.id.toString())
            })
            const oldOldUntouchedLines = oldObject.revisedTableHistory.filter( line => {
                const modifiedLinesIds = removedTotalLineRevisedTableHistory.map(obj => obj.id.toString())
                return !modifiedLinesIds.includes(line.id.toString())
            })
            newObject.revisedTable = [...removedTotalLineRevisedTable, ...oldUntouchedLines]
            newObject.revisedTableHistory = [...removedTotalLineRevisedTableHistory, ...oldOldUntouchedLines]
            if (context.action === "save") {
                return callback(null, newObject, oldObject)
            } else if (context.action === "send") {
                newObject.revisedTableHistory = newObject.revisedTable.map( line => { return {...line, id: new global.ObjectID()}} )
                newObject.revisedTable.forEach( line => {
                    newObject.completeRevisedTableHistory.push({
                        ...line,
                        id: new global.ObjectID(),
                        revueDate: moment().format("YYYY-MM-DD HH:mm:ss"), // instead of newObject.revueDate, //to have in the history the exact time when it was saved
                        meetingsPlanning: newObject.meetingsPlanning,
                    })
                })
                if (!!newObject.sendTo && !!newObject.sendTo.length){
                    return global.app.SE.Currency.collection.findOne({
                        nature: '1',
                    }, (error, currency) => {
                        if(error) return callback(error)
                        if (!!currency){
                            global.pdf.generatePdf(CrPdfConfig(context.module.name, newObject, context, currency), (error, file) => {
                                newObject.projectCommitteeFiles.push({
                                    user: _.pick(context.user, ['id', 'name']),
                                    date: moment().format("YYYY-MM-DD HH:mm"),
                                    ...file
                                })
                                const mails = newObject.sendTo.map(user => {
                                    return {
                                        from: 'support@keenpoint.com',
                                        to: decrypt(user.mail),
                                        subject: {template: `Comité de projet du ${moment.utc(newObject.revueDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} : Compte-rendu réalisation du projet N° : ${newObject.demandNumber}`},
                                        content: {html: "<div><p>Bonjour,</p></div><div><p>Veuillez trouver ci-joint le compte-rendu réalisation du comité projet.</p></div><div><p>Cordialement,</p><p>Keenpoint Consulting</p></div>"},
                                        attachments: [file],
                                        verbose: {
                                            general: true,
                                        }
                                    }
                                }) || []
                                if (!!newObject.sendToOthers.length) {
                                    newObject.sendToOthers.split(';').map(str => str.trim()).forEach(mail => {
                                        mails.push(
                                            {
                                                from: 'support@keenpoint.com',
                                                to: mail,
                                                subject: {template: `Comité de projet du ${moment.utc(newObject.revueDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} : Compte-rendu réalisation du projet N° : ${newObject.demandNumber}`},
                                                content: {html: "<div><p>Bonjour,</p></div><div><p>Veuillez trouver ci-joint le compte-rendu réalisation du comité projet.</p></div><div><p>Cordialement,</p><p>Keenpoint Consulting</p></div>"},
                                                attachments: [file],
                                                verbose: {
                                                    general: true,
                                                }
                                            }
                                        )
                                    })
                                }
                                global.mailer.sendMail(mails, (error) => {
                                    callback(error, newObject, oldObject)
                                })
                            })
                        }
                        else{
                            return callback(new Errors.ValidationError('You have to create a reference currency first'))
                        }
                    })
                }
                else{
                    return callback(new Errors.ValidationError('You have to select at least one recipient'))
                }
            }
        }
        if (context.module && ['preValidation', 'DemandInstruction'].includes(context.module.name) ){
            async.parallel([
                // looking for the start fiscal year of this demand,
                callback => {
                    const principalProjectOrDemandId = context?.module?.category?.path === 'Projet' ? newObject.relatedProject?.id : newObject.relatedDemand?.id
                    const query = !!principalProjectOrDemandId ?
                        context?.module?.category?.path === 'Projet' ?
                            {
                                relatedProject: global.ObjectID(principalProjectOrDemandId)
                            } : {
                                relatedDemand: global.ObjectID(principalProjectOrDemandId)
                            }
                        : {
                            _id: null
                        }
                    return global.app.SE.Demand.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query
                    }, callback)
                },
                callback => {
                    const query = moment().format("YYYY-MM") === newObject.startMonth ?
                        {
                            "fiscalYearRange.0": {$lte: moment().toDate()},
                            "fiscalYearRange.1": {$gte: moment().toDate()},
                        }
                        : {
                            "fiscalYearRange.0": {$lte: moment(newObject.startMonth, 'YYYY-MM').toDate()},
                            "fiscalYearRange.1": {$gte: moment(newObject.startMonth, 'YYYY-MM').toDate()},
                        }
                    return global.app.SE.FiscalYear.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query
                    }, callback)
                },
                // looking for the end fiscal year of this demand,
                callback => {
                    const query = moment().format("YYYY-MM") === newObject.endMonth ?
                        {
                            "fiscalYearRange.0": {$lte: moment().toDate()},
                            "fiscalYearRange.1": {$gte: moment().toDate()},
                        }
                        : {
                            "fiscalYearRange.0": {$lte: moment(newObject.endMonth, 'YYYY-MM').toDate()},
                            "fiscalYearRange.1": {$gte: moment(newObject.endMonth, 'YYYY-MM').toDate()},
                        }
                    return global.app.SE.FiscalYear.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query
                    }, callback)
                },
                // looking for the workflow for this demand,
                callback => global.app.SE.WorkFlow.find({
                    ...basicContext(context),
                    fieldPath: ["workFlowConfigs.step", "workFlowConfigs.order", "workFlowConfigs.role.id"],
                    query: {
                        "organizationAndMesh": {$elemMatch: {$eq: global.ObjectID(newObject.organizationAndMesh.id)}},
                        "demandCategory": {$eq: global.ObjectID(newObject.demandCategory.id)},
                        "demandNature": {$elemMatch: {$eq: global.ObjectID(newObject.demandNature.id)}},
                        "minMaxAmount": global.ObjectID(newObject.plafond.id)
                    }
                }, callback),
                // looking for the habilitation of this user,
                callback => global.app.SE.Habilitation.find({
                    ...basicContext(context),
                    fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                    query: {user: global.ObjectID(context.user.id)}
                }, callback),
                // looking for user habilitations with the current roles to intervent in the demand
                callback => {
                    if (context.module && context.module.name === "arbitration") {
                        return callback(null, [])
                    }
                    global.app.SE.WorkFlow.find({
                        ...basicContext(context),
                        fieldPath: ["workFlowConfigs.step", "workFlowConfigs.order", "workFlowConfigs.role.id"],
                        query: {
                            "organizationAndMesh": {$elemMatch: {$eq: global.ObjectID(newObject.organizationAndMesh.id)}},
                            "demandCategory": {$eq: global.ObjectID(newObject.demandCategory.id)},
                            "demandNature": {$elemMatch: {$eq: global.ObjectID(newObject.demandNature.id)}},
                            "minMaxAmount": global.ObjectID(newObject.plafond.id)
                        }
                    }, (e, worlflows) => {
                        if (e) {
                            return callback(e)
                        }
                        if (!worlflows.length) {
                            callback(null, [])
                        } else if (worlflows.length !== 1) {
                            return callback(null, [])
                        } else {
                            const _workflowConf = worlflows[0]
                            // todo preValidation --> first step, done
                            const orderOfSteps = generateOrderOfSteps(steps, _workflowConf.workFlowConfigs)
                            const firstStep = Object.keys(orderOfSteps)[0]
                            const currentConfigs = !!newObject.workflow && !!newObject.workflow[0] && newObject.workflow[0] !== 'draft' ? _workflowConf.workFlowConfigs.filter(conf => conf.step === newObject.workflow[0] && conf.order === parseInt(newObject.workflow[1])) : _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep && conf.order === 1)
                            const currentRolesIDs = currentConfigs.map(conf => global.ObjectID(conf.role.id))
                            global.app.SE.Habilitation.find({
                                ...basicContext(context),
                                fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                                query: {
                                    user: global.ObjectID(context.user.id),
                                    role: {$in: currentRolesIDs}
                                }
                            }, (e, userHabilitations) => {
                                if (e) {
                                    return callback(e)
                                }
                                if (!userHabilitations.length) {
                                    callback(null, [])
                                } else {
                                    let userGrantedOrgsAndMeshes = []   //array of arrays
                                    userHabilitations.forEach(hab => {
                                        userGrantedOrgsAndMeshes.push(hab.grantedAccess)
                                        userGrantedOrgsAndMeshes.push(hab.grantedMesh)
                                    })
                                    const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)
                                    if (!userGrantedOrgsAndMeshesIDs.length) {
                                        if (!userHaveExhaustifScope(userHabilitations)) {

                                            callback(new Errors.ValidationError('Vous ne disposez pas des autorisations nécessaires pour accomplir cette action'))
                                        } else {
                                            callback(null, []) //if exhaustif, we verifie Habs directly
                                        }
                                    } else {
                                        global.app.SE.OrganizationalMesh.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: [],
                                                query: {
                                                    $or: [
                                                        {"attachments": {$elemMatch: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}},
                                                        {_id: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                                    ]
                                                }
                                            }, (er, meshes) => {
                                                if (er) return callback(er)
                                                let userGrantedOrgsAndMeshesPerRole = {}
                                                userHabilitations.forEach(hab => {
                                                    let orgsAndMeshes = []
                                                    orgsAndMeshes.push(hab.grantedAccess)
                                                    orgsAndMeshes.push(hab.grantedMesh)
                                                    userGrantedOrgsAndMeshesPerRole[hab.role.id] = orgsAndMeshes.flat(1).map(org => org.id)
                                                })
                                                if (!!meshes.length) {
                                                    meshes.forEach(mesh => {
                                                        Object.keys(userGrantedOrgsAndMeshesPerRole).forEach(role => {
                                                            if (userGrantedOrgsAndMeshesPerRole[role].includes(mesh.id)) {
                                                                userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                                mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                            } else if (mesh.attachments.some(orgId => userGrantedOrgsAndMeshesPerRole[role].includes(orgId.toString()))) {
                                                                userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                                mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                            }
                                                        })
                                                    })
                                                }
                                                callback(er, userGrantedOrgsAndMeshesPerRole)
                                            }
                                        )
                                    }
                                }
                            })
                        }
                    })
                },
                // looking for all related orgs to the orgization of the demand.
                callback => {
                    const action = context.restAction && context.restAction.crudType
                    if (["save", "delegate"].includes(context.action)) return callback()
                    global.app.SE.OrganizationalMesh.find(
                        {
                            ...basicContext(context),
                            fieldPath: [],
                            query: {
                                $or: [
                                    {"attachments": {$elemMatch: {$eq: global.ObjectID(newObject.organizationAndMesh.id)}}},
                                    {_id: {$eq: global.ObjectID(newObject.organizationAndMesh.id)}}
                                ]
                            }
                        }, (ero, meshes) => {
                            if (ero) return callback(ero)
                            let demandAugmentedOrgsAndMeshes = []
                            let finalResult = []
                            if (!meshes.length) {
                                finalResult = [global.ObjectID(newObject.organizationAndMesh.id)]
                            } else {
                                demandAugmentedOrgsAndMeshes.push(meshes.map(mesh => global.ObjectID(mesh.id)))
                                const relatedOrganizationsIDsList = meshes.map(mesh => mesh.attachments) //liste de liste des ids
                                const relatedOrganizationsIDs = relatedOrganizationsIDsList.flat(1)
                                let uniqueStringRelatedOrganizationsIDs = []
                                relatedOrganizationsIDs.forEach(function (org) {
                                    if (!uniqueStringRelatedOrganizationsIDs.includes(JSON.stringify(org))) {
                                        uniqueStringRelatedOrganizationsIDs.push(JSON.stringify(org))
                                    }
                                })
                                demandAugmentedOrgsAndMeshes.push(uniqueStringRelatedOrganizationsIDs.map(stringID => global.ObjectID(JSON.parse(stringID))))
                                finalResult = demandAugmentedOrgsAndMeshes.flat(1)
                            }
                            callback(ero, finalResult)
                        }
                    )
                },
                // looking for arbitrator habilitations and his augmented organizations
                callback => {
                    if (context.module && context.module.name !== "arbitration") {
                        return callback(null, [])
                    }
                    global.app.SE.Habilitation.find({
                        ...basicContext(context),
                        fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                        query: {
                            user: global.ObjectID(context.user.id),
                            //role: { $in : arbitratorsIds }
                        }
                    }, (e, userHabilitations) => {
                        if (e) {
                            return callback(e)
                        }
                        if (!userHabilitations.length) {
                            callback(null, [])
                        } else {
                            let userGrantedOrgsAndMeshes = []   //array of arrays
                            userHabilitations.forEach(hab => {
                                userGrantedOrgsAndMeshes.push(hab.grantedAccess)
                                userGrantedOrgsAndMeshes.push(hab.grantedMesh)
                            })
                            const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)
                            if (!userGrantedOrgsAndMeshesIDs.length) {
                                if (!userHaveExhaustifScope(userHabilitations)) {
                                    callback(new Errors.ValidationError('Vous ne disposez pas des autorisations nécessaires pour accomplir cette action'))
                                } else {
                                    callback(null, []) //if exhaustif, we verifie Habs directly
                                }
                            } else {
                                global.app.SE.OrganizationalMesh.find(
                                    {
                                        ...basicContext(context),
                                        fieldPath: [],
                                        query: {
                                            $or: [
                                                {"attachments": {$elemMatch: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}},
                                                {_id: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                            ]
                                        }
                                    }, (er, meshes) => {
                                        if (er) return callback(er)
                                        let userGrantedOrgsAndMeshesPerRole = {}
                                        userHabilitations.forEach(hab => {
                                            let orgsAndMeshes = []
                                            orgsAndMeshes.push(hab.grantedAccess)
                                            orgsAndMeshes.push(hab.grantedMesh)
                                            userGrantedOrgsAndMeshesPerRole[hab.role.id] = orgsAndMeshes.flat(1).map(org => org.id)
                                        })
                                        if (!!meshes.length) {
                                            meshes.forEach(mesh => {
                                                Object.keys(userGrantedOrgsAndMeshesPerRole).forEach(role => {
                                                    if (userGrantedOrgsAndMeshesPerRole[role].includes(mesh.id)) {
                                                        userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                        mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                    } else if (mesh.attachments.some(orgId => userGrantedOrgsAndMeshesPerRole[role].includes(orgId.toString()))) {
                                                        userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                        mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                    }
                                                })
                                            })
                                        }
                                        callback(er, userGrantedOrgsAndMeshesPerRole)
                                    }
                                )
                            }
                        }
                    })
                },
            ], (error, results) => {
                if (error) return callback(error)
                const [relatedProjectsOrDemands, demandStartFiscalYears, demandEndFiscalYears, workflowConfs, userHabilitations, userGrantedOrgsAndMeshesPerRole, demandAugmentedOrgsAndMeshesIDs, arbitratorGrantedOrgsAndMeshesPerRole] = results
                const userIsInDelegateTo = (newObject.delegateTo && newObject.delegateTo.map(user => user.id).includes(context.user.id))
                const userIsInCurrentContributors = (newObject.currentContributors && newObject.currentContributors.map(user => user.id).includes(context.user.id))
                if (!demandStartFiscalYears.length || !demandEndFiscalYears.length) {
                    return callback(new Errors.ValidationError("Aucun exercice n'a été configuré pour le mois que vous avez choisi"))
                }
                if (demandStartFiscalYears.length !== 1 || demandEndFiscalYears.length !== 1) {
                    return callback(new Errors.ValidationError("Aucun exercice n'a été configuré pour le mois que vous avez choisi"))
                }
                if (!userHabilitations.length && !userIsInDelegateTo) {
                    return callback(new Errors.ValidationError('Vous ne disposez pas des autorisations nécessaires pour accomplir cette action'))
                }
                if (!workflowConfs.length) {
                    return callback(new Errors.ValidationError('your demand have no defined workflow'))
                } else if (workflowConfs.length !== 1) {
                    return callback(new Errors.ValidationError('your demand have more than one workflow'))
                } else {
                    /**
                     * we assign the appropriate start fiscal year and end fiscal year to the demand
                     * it could be modified after its creation (in preValidation or instruction steps)
                     */
                    const demandStartFiscalYear = demandStartFiscalYears[0]
                    const demandEndFiscalYear = demandEndFiscalYears[0]
                    newObject.startFiscalYear = {id: demandStartFiscalYear.id}
                    newObject.endFiscalYear = {id: demandEndFiscalYear.id}
                    const _workflowConf = workflowConfs[0]
                    const readersIDs = _workflowConf.readers.map(role => global.ObjectID(role.id))
                    const action = context.restAction && context.restAction.crudType
                    const userRoles = userHabilitations.map(hab => hab.role.id)
                    if (action === 'C') {
                        // montant initial total devrait être supérieur à 0
                        const orderOfSteps = generateOrderOfSteps(steps, _workflowConf.workFlowConfigs)
                        const firstStep = Object.keys(orderOfSteps)[0]
                        const firstLetter = context.module.name === 'preValidation' ? 'P': 'D'
                        const isPrincipalProjectOrDemand = context?.module?.category?.path === 'Projet' && !newObject.relatedProject || context?.module?.category?.path === 'demande' && !newObject.relatedDemand
                        const principalProjectOrDemandNumber = context?.module?.category?.path === 'Projet' ? newObject.relatedProject?.demandNumber : newObject.relatedDemand?.demandNumber
                        const demandNumber = isPrincipalProjectOrDemand ?
                            firstLetter + '-' + demandStartFiscalYear.code + '-' + newObject.organizationAndMesh.code + '-' + newObject.demandNature.code + '-' + newObject.sequence
                            : principalProjectOrDemandNumber + '-' + parseInt(relatedProjectsOrDemands.length + 1)
                        newObject.demandNumber = demandNumber
                        newObject.arbitorNotYetActive = false
                        newObject.arbitrationType = {id: _workflowConf.arbitrationType}
                        newObject.delegationOption = {id: _workflowConf.delegationOption}
                        const firstConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep && conf.order === 1)
                        const firstRolesIDs = firstConfigs.map(conf => conf.role.id)
                        const firstUserRoleOk = userRoles.some(role => firstRolesIDs.includes(role))
                        const firstUserAugmentedHabOk = userRoles.some(roleID => firstRolesIDs.includes(roleID) && (userHaveExhaustifScope(userHabilitations) || (userGrantedOrgsAndMeshesPerRole[roleID] && userGrantedOrgsAndMeshesPerRole[roleID].includes(newObject.organizationAndMesh.id.toString()))))
                        const lastComments = newObject.comments.sort((a, b) => moment(a.date).format('YYYY-MM-DD HH:MM:ss') - moment(b.date).format('YYYY-MM-DD HH:MM:ss')).map(comment => comment.text)
                        // assert these two conditions
                        if (firstStep === 'demand' && context.module.name === 'preValidation') {
                            return callback(new Errors.ValidationError('your demand have to be created from the Project menu'))
                        }
                        if (firstStep === 'preValidation' && context.module.name === 'DemandInstruction') {
                            return callback(new Errors.ValidationError('your demand have to be created from the Demand menu'))
                        }

                        // continue the creation
                        // todo, check it works
                        console.log('test', !!newObject.totalAmount, !!newObject.largeImputationsTotalAmount)
                        if ( !!newObject.totalAmount && !(0 < newObject.totalAmount && newObject.plafond.max >= newObject.totalAmount)) {
                            return callback(new Errors.ValidationError('The total amount should respect the selected min/max amount'))
                        }
                        if ( !!newObject.largeImputationsTotalAmount && !(0 < newObject.largeImputationsTotalAmount && newObject.plafond.max >= newObject.largeImputationsTotalAmount)) {
                            return callback(new Errors.ValidationError('The total amount should respect the selected min/max amount'))
                        }

                        console.log('ji', newObject.category, newObject.demandCategory.category.id)
                        newObject.category = {id: newObject.demandCategory.category.id}

                        if (firstUserRoleOk && firstUserAugmentedHabOk) {
                            newObject.workflow = ["draft", 1]
                            newObject.alreadyTreatedByFunctions = {}
                            newObject.alreadyTreatedByFunctions[firstStep] = []
                            newObject.consultantsFunctions = _workflowConf.workFlowConfigs.map(conf => global.ObjectID(conf.role.id))
                            newObject.allArbitratorsFunctions = _workflowConf.arbitrators.map(id => global.ObjectID(id))
                            if (context.action === "save") {  //brouillon
                                newObject.currentContributors = [{"id": context.user.id}]
                                newObject.status = {id: '1'}
                                callback(null, newObject, oldObject)
                            } else if (context.action === 'validate') {  //enregistrer
                                newObject.status = firstStep === "demand" ? {id: '10', name: 'demand'} : {id: '12', name: 'preValidation'}
                                newObject.comments.push({
                                    automatic: true,
                                    user: _.pick(context.user, ['id', 'name']),
                                    text: getStepNameForComment(newObject.workflow[0]) + ', ' + "save",
                                    date: moment().format("YYYY-MM-DD HH:mm:ss")
                                })
                                newObject.workflow = [firstStep, 1]
                                newObject.contributorsFunctions = firstRolesIDs.map(id => global.ObjectID(id))
                                return global.app.SE.Habilitation.find(
                                    {
                                        ...basicContext(context),
                                        fieldPath: ["user.id"],
                                        query: {
                                            "role": {$in: firstRolesIDs.map(id => global.ObjectID(id))},
                                            $or: [{
                                                "grantedAccess": [],
                                                "grantedMesh": []
                                            }, {
                                                "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                "grantedMesh": []
                                            }, {
                                                "grantedAccess": [],
                                                "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                            }]
                                        }
                                    }, (e, nextUsersList) => {
                                        if (!nextUsersList.length) {
                                            return callback(new Errors.ValidationError('there is no person that can handle this demand on the next step'))
                                        } else {
                                            const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                            const mails = nextUsersList.filter(hab => hab.user.id !== context.user.id).map(hab => {
                                                const userMail = hab.user.mail
                                                const subject = `Création ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(firstStep)})`
                                                const stepAction = choseStepAction("draft", context.action)
                                                const expectedAction = choseExpectedAction(newObject.workflow[0], context.action)
                                                const module = moduleName(firstStep)
                                                return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                            })
                                            if (!!mails.length){
                                                global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    callback(error, newObject, oldObject)
                                                })
                                            }
                                            else{
                                                callback(error, newObject, oldObject)
                                            }
                                        }
                                    }
                                )
                            } else if (context.action === 'transmit') { //valider
                                newObject.status = firstStep === "demand" ? {id: '10', name: 'demand'} : {id: '12', name: 'preValidation'}
                                newObject.alreadyTreatedByFunctions[firstStep].push(firstRolesIDs.map(id => global.ObjectID(id).toString()))
                                let [nextNextStep, nextNextOrder] = transmitToNextStep(_workflowConf.workFlowConfigs)
                                newObject.comments.push({
                                    automatic: true,
                                    user: _.pick(context.user, ['id', 'name']),
                                    text: getStepNameForComment(newObject.workflow[0]) + ', ' + "validate",
                                    date: moment().format("YYYY-MM-DD HH:mm:ss")
                                })
                                const nextConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextNextStep && conf.order === nextNextOrder)
                                let nextRolesIDs = nextConfigs.map(conf => global.ObjectID(conf.role.id))
                                return global.app.SE.Habilitation.find(
                                    {
                                        ...basicContext(context),
                                        fieldPath: ["user.id"],
                                        query: {
                                            "role": {$in: nextRolesIDs},
                                            $or: [{
                                                "grantedAccess": [],
                                                "grantedMesh": []
                                            }, {
                                                "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                "grantedMesh": []
                                            }, {
                                                "grantedAccess": [],
                                                "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                            }]
                                        }
                                    }, (e, nextUsersList) => {
                                        if (!nextUsersList.length) {
                                            return callback(new Errors.ValidationError('there is no person that can handle this demand on the next step'))
                                        } else {
                                            const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                            const mails = nextUsersList.map(hab => {
                                                const userMail = hab.user.mail
                                                const subject = `${choseSubjectStep(firstStep)} ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(nextNextStep)})`
                                                const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                const expectedAction = choseExpectedAction(nextNextStep, context.action)
                                                const module = moduleName(nextNextStep)
                                                return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                            })
                                            global.mailer.sendMail(mails, (error) => {
                                                if (error) return callback(error)
                                                    // todo, done
                                                if ( ["draft", firstStep].includes(newObject.workflow[0]) && nextNextStep !== firstStep ) {
                                                    global.app.SE.Habilitation.find(
                                                        {
                                                            ...basicContext(context),
                                                            fieldPath: ["user.id"],
                                                            query: {
                                                                "role": {$in: readersIDs},
                                                                $or: [{
                                                                    "grantedAccess": [],
                                                                    "grantedMesh": []
                                                                }, {
                                                                    "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                                    "grantedMesh": []
                                                                }, {
                                                                    "grantedAccess": [],
                                                                    "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                                }]
                                                            }
                                                        }, (e, readers) => {
                                                            if (!readers.length) {
                                                                newObject.contributorsFunctions = nextRolesIDs
                                                                newObject.workflow = [nextNextStep, nextNextOrder]
                                                                newObject.status = getNextStepStatus(nextNextStep)
                                                                return callback(error, newObject, oldObject)
                                                            } else {
                                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                                const mails = readers.map(hab => {
                                                                    const userMail = hab.user.mail
                                                                    const subject = `Validation ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(nextNextStep)})`
                                                                    const module = moduleName(nextNextStep)
                                                                    return createReadersMail(context, userMail, subject, newObject, module)
                                                                })
                                                                global.mailer.sendMail(mails, (error) => {
                                                                    if (error) return callback(error)
                                                                    newObject.contributorsFunctions = nextRolesIDs
                                                                    newObject.workflow = [nextNextStep, nextNextOrder]
                                                                    newObject.status = getNextStepStatus(nextNextStep)
                                                                    callback(error, newObject, oldObject)
                                                                })
                                                            }
                                                        })
                                                } else {
                                                    newObject.contributorsFunctions = nextRolesIDs
                                                    newObject.workflow = [nextNextStep, nextNextOrder]
                                                    newObject.status = getNextStepStatus(nextNextStep)
                                                    return callback(error, newObject, oldObject)
                                                }
                                            })
                                        }
                                    }
                                )
                            }
                        } else {
                            return callback(new Errors.ValidationError('you are not eligible to this demand'))
                        }
                    }
                    //verified to here
                    else {
                        const orderOfSteps = generateOrderOfSteps(steps, _workflowConf.workFlowConfigs)
                        const firstStep = Object.keys(orderOfSteps)[0]
                        const firstConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep && conf.order === 1)
                        const firstRolesIDs = firstConfigs.map(conf => conf.role.id)
                        const firstUserRoleOk = userRoles.some(role => firstRolesIDs.includes(role))
                        const firstUserAugmentedHabOk = userRoles.some(roleID => firstRolesIDs.includes(roleID) && (userHaveExhaustifScope(userHabilitations) || (!!userGrantedOrgsAndMeshesPerRole[roleID] && userGrantedOrgsAndMeshesPerRole[roleID].includes(newObject.organizationAndMesh.id.toString()))))
                        let userRoleOk = false
                        let userAugmentedHabOk = false

                        // todo delete
                        if (context.module && context.module.name === 'arbitration') {
                            userRoleOk = userRoles.some(role => newObject.arbitratorsFunctions.map(func => func.id).includes(role))
                            userAugmentedHabOk = userRoles.some(roleID => newObject.arbitratorsFunctions.map(role => role.id).includes(roleID) && (userHaveExhaustifScope(userHabilitations) || (arbitratorGrantedOrgsAndMeshesPerRole[roleID] && arbitratorGrantedOrgsAndMeshesPerRole[roleID].includes(newObject.organizationAndMesh.id.toString()))))
                        } else {
                            userRoleOk = userRoles.some(role => newObject.contributorsFunctions.map(func => func.id).includes(role))
                            userAugmentedHabOk = userRoles.some(roleID => newObject.contributorsFunctions.map(role => role.id).includes(roleID) && (userHaveExhaustifScope(userHabilitations) || (userGrantedOrgsAndMeshesPerRole[roleID] && userGrantedOrgsAndMeshesPerRole[roleID].includes(newObject.organizationAndMesh.id.toString()))))
                        }

                        if (!(userRoleOk && userAugmentedHabOk) && !userIsInDelegateTo && !(userIsInCurrentContributors && firstUserRoleOk && firstUserAugmentedHabOk)) {
                            return callback(new Errors.ValidationError('you are not referenced as a contributor on this step'))
                        } else {
                            if ( !!newObject.totalAmount && !(0 < newObject.totalAmount && newObject.plafond.max >= newObject.totalAmount)) {
                                return callback(new Errors.ValidationError('The total amount should respect the selected min/max amount'))
                            }
                            if (!!newObject.largeImputationsTotalAmount && !(0 < newObject.largeImputationsTotalAmount && newObject.plafond.max >= newObject.largeImputationsTotalAmount)) {
                                return callback(new Errors.ValidationError('The total amount should respect the selected min/max amount'))
                            }
                            // todo add verification for imputationsTotal
                            newObject.arbitrationType = {id: _workflowConf.arbitrationType}
                            newObject.delegationOption = {id: _workflowConf.delegationOption}
                            if (context.action === 'save') {//same step, same order
                                return callback(null, newObject, oldObject)
                            } else {
                                const sortedCommentsDESC = newObject.comments.sort((a, b) => moment(a.date).format('YYYY-MM-DD HH:MM:ss') - moment(b.date).format('YYYY-MM-DD HH:MM:ss')).map(comment => comment.text).reverse()
                                let lastComments = []
                                for (let i = 0; i < sortedCommentsDESC.length; i++) {
                                    const commentFormat = sortedCommentsDESC[i].split(",").map(info => info.split(":")[0].trim())
                                    const isItAutomaticComment = commentFormat.length === 3 && commentFormat[0] === "step" && commentFormat[1] === "order" && commentFormat[2] === "action"
                                    const stepOrderAction = sortedCommentsDESC[i].split(",").map(info => info.split(":")[1] && info.split(":")[1].trim())
                                    const lastCommentsEndHere = commentFormat.length === 3 && !["delegate", "save"].includes(stepOrderAction[2])
                                    if (!isItAutomaticComment) {
                                        lastComments.push(sortedCommentsDESC[i])
                                    } else if (lastCommentsEndHere) {
                                        break
                                    }
                                }
                                lastComments = lastComments.reverse()
                                if (newObject && newObject.workflow[0] === "draft") {
                                    if (context.action === "validate") {
                                        newObject.comments.push({
                                            automatic: true,
                                            user: _.pick(context.user, ['id', 'name']),
                                            text: getStepNameForComment(newObject.workflow[0]) + ', ' + "save",
                                            date: moment().format("YYYY-MM-DD HH:mm:ss")
                                        })
                                    } else if (context.action === "transmit") {
                                        newObject.comments.push({
                                            automatic: true,
                                            user: _.pick(context.user, ['id', 'name']),
                                            text: getStepNameForComment(newObject.workflow[0]) + ', ' + "validate",
                                            date: moment().format("YYYY-MM-DD HH:mm:ss")
                                        })
                                    }
                                } else {
                                    newObject.comments.push({
                                        automatic: true,
                                        user: _.pick(context.user, ['id', 'name']),
                                        text: getStepNameForComment(newObject.workflow[0]) + ', ' + context.action,
                                        date: moment().format("YYYY-MM-DD HH:mm:ss")
                                    })
                                }
                                if (context.action === "validate") {
                                    newObject.currentContributors = []
                                    const currentStep = newObject.workflow[0]
                                    if (!newObject.alreadyTreatedByFunctions) {
                                        newObject.alreadyTreatedByFunctions = {}
                                        newObject.alreadyTreatedByFunctions[firstStep] = []
                                    }
                                    if (currentStep !== "draft" && !!newObject.contributorsFunctions.length) {
                                        if (newObject.alreadyTreatedByFunctions[currentStep]) {
                                            newObject.alreadyTreatedByFunctions[currentStep].push(newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString()))
                                        } else {
                                            newObject.alreadyTreatedByFunctions[currentStep] = [newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString())]
                                        }
                                    }
                                    //let [nextStep, nextOrder] = getNextStepNextOrder(currentStep, currentOrder, workFlowConfigs)
                                    let [nextStep, nextOrder] = getAndValidateNextStep(newObject.workflow[0], parseInt(newObject.workflow[1]), _workflowConf.workFlowConfigs, newObject.alreadyTreatedByFunctions)

                                    const configsIfNextStep = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                    let rolesIDsIfNextStep = configsIfNextStep.map(conf => conf.role.id)
                                    let alreadyTreatedByFunctionsCopy = _.cloneDeep(newObject.alreadyTreatedByFunctions)
                                    if (alreadyTreatedByFunctionsCopy[nextStep]) {
                                        alreadyTreatedByFunctionsCopy[nextStep].push(rolesIDsIfNextStep.map(id => global.ObjectID(id).toString()))
                                    } else {
                                        alreadyTreatedByFunctionsCopy[nextStep] = [rolesIDsIfNextStep.map(id => global.ObjectID(id).toString())]
                                    }
                                    if (["approved", "closed", "realization"].includes(nextStep)) {
                                        let nextConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                        let nextRolesIDs = nextConfigs.map(conf => global.ObjectID(conf.role.id))
                                        let currentConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === newObject.workflow[0] && conf.order === parseInt(newObject.workflow[1]))
                                        let currentRolesIDs = currentConfigs.map(conf => global.ObjectID(conf.role.id))
                                        let rolesIds = !!nextRolesIDs && !!nextRolesIDs.length ? nextRolesIDs : currentRolesIDs
                                        newObject.followFunctions = rolesIds
                                    }
                                    let [nextNextStep, nextNextOrder] = getAndValidateNextStep(nextStep, nextOrder, _workflowConf.workFlowConfigs, alreadyTreatedByFunctionsCopy)
                                    newObject.arbitratorsFunctions = []
                                    newObject.arbitrateText = ''
                                    if (!!newObject.delegateTo.length) {
                                        newObject.delegateTo.forEach(deleg => {
                                            newObject.delegationHistory.push(deleg)
                                        })
                                    }
                                    newObject.delegateTo = []

                                    let draftOrNextStep = nextStep
                                    if (newObject.workflow[0] === "draft") {
                                        draftOrNextStep = "draft"
                                    }
                                    if (nextStep !== 'approved' && nextStep !== 'closed') {
                                        const nextConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                        let nextRolesIDs = nextConfigs.map(conf => global.ObjectID(conf.role.id))
                                        return global.app.SE.Habilitation.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: ["user.id"],
                                                query: {
                                                    "role": {$in: nextRolesIDs},
                                                    $or: [{
                                                        "grantedAccess": [],
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": [],
                                                        "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                    }],
                                                }
                                            }, (e, nextUsersList) => {
                                                if (!nextUsersList.length) {
                                                    return callback(new Errors.ValidationError('there is no person that can handle this demand on the next step'))
                                                } else {
                                                    let concernedNextUsers = _.cloneDeep(nextUsersList)
                                                    if (newObject.workflow[0] === "draft") {
                                                        concernedNextUsers = concernedNextUsers.filter(hab => hab.user.id !== context.user.id)
                                                    }
                                                    const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                    const mails = concernedNextUsers.map(hab => {
                                                        const userMail = hab.user.mail
                                                        const subject = `${choseSubjectStep(draftOrNextStep)} ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(nextStep)})`
                                                        const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                        const expectedAction = choseExpectedAction(nextStep, context.action)
                                                        const module = moduleName(nextStep)
                                                        return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                    })
                                                    global.mailer.sendMail(mails, (error) => {
                                                        if (error) return callback(error)
                                                        // todo, done
                                                        if ( ["draft", firstStep].includes(newObject.workflow[0]) && nextStep !== firstStep ) {
                                                            global.app.SE.Habilitation.find(
                                                                {
                                                                    ...basicContext(context),
                                                                    fieldPath: ["user.id"],
                                                                    query: {
                                                                        "role": {$in: readersIDs},
                                                                        $or: [{
                                                                            "grantedAccess": [],
                                                                            "grantedMesh": []
                                                                        }, {
                                                                            "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                                            "grantedMesh": []
                                                                        }, {
                                                                            "grantedAccess": [],
                                                                            "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                                        }]
                                                                    }
                                                                }, (e, readers) => {
                                                                    if (!readers.length) {
                                                                        newObject.workflow = [nextStep, nextOrder]
                                                                        newObject.contributorsFunctions = nextRolesIDs
                                                                        newObject.status = getNextStepStatus(nextStep)
                                                                        return callback(error, newObject, oldObject)
                                                                    } else {
                                                                        const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                                        const mails = readers.map(hab => {
                                                                            const userMail = hab.user.mail
                                                                            const subject = `${choseSubjectStep(nextStep)} ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(nextStep)})`
                                                                            const module = moduleName(nextStep)
                                                                            return createReadersMail(context, userMail, subject, newObject, module)
                                                                        })
                                                                        global.mailer.sendMail(mails, (error) => {
                                                                            if (error) return callback(error)
                                                                            newObject.workflow = [nextStep, nextOrder]
                                                                            newObject.contributorsFunctions = nextRolesIDs
                                                                            newObject.status = getNextStepStatus(nextStep)
                                                                            callback(error, newObject, oldObject)
                                                                        })
                                                                    }
                                                                })
                                                        } else {
                                                            newObject.workflow = [nextStep, nextOrder]
                                                            newObject.contributorsFunctions = nextRolesIDs
                                                            newObject.status = getNextStepStatus(nextStep)
                                                            return callback(error, newObject, oldObject)
                                                        }
                                                    })
                                                }
                                            }
                                        )
                                    } else {
                                        const demandConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep)
                                        let demandRolesIDs = demandConfigs.map(conf => global.ObjectID(conf.role.id))
                                        demandRolesIDs = demandRolesIDs.concat(readersIDs)
                                        const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'
                                        return global.app.SE.Habilitation.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: ["user.id"],
                                                query: {
                                                    "role": {$in: demandRolesIDs},
                                                    $or: [{
                                                        "grantedAccess": [],
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": [],
                                                        "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                    }]
                                                }
                                            }, (e, nextUsersList) => {
                                                if (!!nextUsersList.length) {
                                                    const mails = nextUsersList.map(hab => {
                                                        const userMail = hab.user.mail
                                                        return createFinishedMail(context, userMail, newObject, nextStep)
                                                    })
                                                    global.mailer.sendMail(mails, (error) => {
                                                        if (error) return callback(error)
                                                        if (nextStep === 'approved') {
                                                            newObject.workflow = ['approved', 1000000]
                                                        } else if (nextStep === 'closed') {
                                                            newObject.workflow = ['closed', 1000000]
                                                        }
                                                        newObject.contributorsFunctions = []
                                                        newObject.status = getNextStepStatus(nextStep)
                                                        callback(null, newObject, oldObject)
                                                    })
                                                } else {
                                                    if (nextStep === 'approved') {
                                                        newObject.workflow = ['approved', 1000000]
                                                    } else if (nextStep === 'closed') {
                                                        newObject.workflow = ['closed', 1000000]
                                                    }
                                                    newObject.contributorsFunctions = []
                                                    newObject.status = getNextStepStatus(nextStep)
                                                    callback(null, newObject, oldObject)
                                                }
                                            }
                                        )
                                    }
                                } else if (context.action === "transmit") {
                                    newObject.currentContributors = []

                                    const orderOfSteps = generateOrderOfSteps(steps, _workflowConf.workFlowConfigs)
                                    const firstStep = Object.keys(orderOfSteps)[0]
                                    newObject.status = firstStep === "demand" ? {id: '10', name: 'demand'} : {id: '12', name: 'preValidation'}  //only to send mails with the right status
                                    const firstConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep && conf.order === 1)
                                    let firstRolesIDs = firstConfigs.map(conf => conf.role.id)

                                    newObject.alreadyTreatedByFunctions[firstStep].push(firstRolesIDs.map(id => global.ObjectID(id).toString()))
                                    let [nextNextStep, nextNextOrder] = transmitToNextStep(_workflowConf.workFlowConfigs)
                                    newObject.arbitratorsFunctions = []
                                    newObject.arbitrateText = ''
                                    if (!!newObject.delegateTo.length) {
                                        newObject.delegateTo.forEach(deleg => {
                                            newObject.delegationHistory.push(deleg)
                                        })
                                    }
                                    newObject.delegateTo = []
                                    const nextConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextNextStep && conf.order === nextNextOrder)
                                    let nextRolesIDs = nextConfigs.map(conf => global.ObjectID(conf.role.id))
                                    return global.app.SE.Habilitation.find(
                                        {
                                            ...basicContext(context),
                                            fieldPath: ["user.id"],
                                            query: {
                                                "role": {$in: nextRolesIDs},
                                                $or: [{
                                                    "grantedAccess": [],
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": [],
                                                    "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                }]
                                            }
                                        }, (e, nextUsersList) => {
                                            if (!nextUsersList.length) {
                                                return callback(new Errors.ValidationError('there is no person that can handle this demand on the next step'))
                                            } else {
                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                const mails = nextUsersList.map(hab => {
                                                    const userMail = hab.user.mail
                                                    const subject = `${choseSubjectStep(firstStep)} ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(nextNextStep)})`
                                                    const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                    const expectedAction = choseExpectedAction(nextNextStep, context.action)
                                                    const module = moduleName(nextNextStep)
                                                    return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                })
                                                global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    if (["draft", firstStep].includes(newObject.workflow[0]) && nextNextStep !== firstStep) {
                                                        global.app.SE.Habilitation.find(
                                                            {
                                                                ...basicContext(context),
                                                                fieldPath: ["user.id"],
                                                                query: {
                                                                    "role": {$in: readersIDs},
                                                                    $or: [{
                                                                        "grantedAccess": [],
                                                                        "grantedMesh": []
                                                                    }, {
                                                                        "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                                        "grantedMesh": []
                                                                    }, {
                                                                        "grantedAccess": [],
                                                                        "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                                    }]
                                                                }
                                                            }, (e, readers) => {
                                                                if (!readers.length) {
                                                                    newObject.workflow = [nextNextStep, nextNextOrder]
                                                                    newObject.contributorsFunctions = nextRolesIDs
                                                                    newObject.status = getNextStepStatus(nextNextStep)
                                                                    return callback(error, newObject, oldObject)
                                                                } else {
                                                                    const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                                    const mails = readers.map(hab => {
                                                                        const userMail = hab.user.mail
                                                                        const subject = `Validation ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(nextNextStep)})`
                                                                        const module = moduleName(nextNextStep)
                                                                        return createReadersMail(context, userMail, subject, newObject, module)
                                                                    })
                                                                    global.mailer.sendMail(mails, (error) => {
                                                                        if (error) return callback(error)
                                                                        newObject.workflow = [nextNextStep, nextNextOrder]
                                                                        newObject.contributorsFunctions = nextRolesIDs
                                                                        newObject.status = getNextStepStatus(nextNextStep)
                                                                        callback(error, newObject, oldObject)
                                                                    })
                                                                }
                                                            })
                                                    } else {
                                                        newObject.workflow = [nextNextStep, nextNextOrder]
                                                        newObject.contributorsFunctions = nextRolesIDs
                                                        newObject.status = getNextStepStatus(nextNextStep)
                                                        return callback(error, newObject, oldObject)
                                                    }
                                                })
                                            }
                                        }
                                    )
                                } else if (context.action === 'revise') {
                                    let [nextStep, nextOrder] = getPreviousStepPreviousOrder(newObject.workflow[0], parseInt(newObject.workflow[1]), _workflowConf.workFlowConfigs)
                                    const nextConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                    const nextRolesIDs = nextConfigs.map(conf => global.ObjectID(conf.role.id))
                                    const currentStep = newObject.workflow[0]

                                    while (newObject.alreadyTreatedByFunctions[nextStep] && newObject.alreadyTreatedByFunctions[nextStep].length >= nextOrder) {
                                        newObject.alreadyTreatedByFunctions[nextStep].pop()
                                    }
                                    newObject.arbitratorsFunctions = []
                                    newObject.arbitrateText = ''
                                    if (!!newObject.delegateTo.length) {
                                        newObject.delegateTo.forEach(deleg => {
                                            newObject.delegationHistory.push(deleg)
                                        })
                                    }
                                    newObject.delegateTo = []
                                    const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'
                                    return global.app.SE.Habilitation.find(
                                        {
                                            ...basicContext(context),
                                            fieldPath: ["user.id"],
                                            query: {
                                                "role": {$in: nextRolesIDs.map(role => global.ObjectID(role.id))},
                                                $or: [{
                                                    "grantedAccess": [],
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}},
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": [],
                                                    "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                                }]
                                            }
                                        }, (e, nextUsersList) => {
                                            if (!nextUsersList.length) {
                                                return callback(new Errors.ValidationError('there is no person that can handle this demand on the next step'))
                                            } else {
                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                const mails = nextUsersList.map(hab => {
                                                    const userMail = hab.user.mail
                                                    const subject = `Révision ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : Demande d'information)`
                                                    const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                    const expectedAction = choseExpectedAction(nextStep, context.action)
                                                    const module = moduleName(nextStep)
                                                    return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                })
                                                global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    newObject.status = {id: '4'}
                                                    newObject.workflow = [nextStep, nextOrder]
                                                    newObject.contributorsFunctions = nextRolesIDs
                                                    callback(error, newObject, oldObject)
                                                })
                                            }
                                        }
                                    )
                                } else if (context.action === 'refuse') {
                                    const currentStep = newObject.workflow[0]
                                    if (!!newObject.contributorsFunctions.length) {
                                        if (newObject.alreadyTreatedByFunctions[currentStep]) {
                                            newObject.alreadyTreatedByFunctions[currentStep].push(newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString()))
                                        } else {
                                            newObject.alreadyTreatedByFunctions[currentStep] = [newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString())]
                                        }
                                    }

                                    const demandConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep)
                                    let demandRolesIDs = demandConfigs.map(conf => global.ObjectID(conf.role.id))
                                    demandRolesIDs = demandRolesIDs.concat(readersIDs)
                                    return global.app.SE.Habilitation.find(
                                        {
                                            ...basicContext(context),
                                            fieldPath: ["user.id"],
                                            query: {
                                                "role": {$in: demandRolesIDs},
                                                $or: [{
                                                    "grantedAccess": [],
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": [],
                                                    "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                }]
                                            }
                                        }, (e, nextUsersList) => {
                                            if (e) return callback(e)
                                            if (!!nextUsersList.length) {
                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'Demande refusée' : 'Projet refusé'
                                                const mails = nextUsersList.map(hab => {
                                                    const userMail = hab.user.mail
                                                    const subject = `${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : Refusé)`
                                                    const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                    return createAbondonMail(context, userMail, subject, newObject, stepAction)
                                                })
                                                global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    newObject.status = {id: '5'}
                                                    newObject.workflow = ['refused', 1000000]
                                                    newObject.contributorsFunctions = []
                                                    if (!!newObject.delegateTo.length) {
                                                        newObject.delegateTo.forEach(deleg => {
                                                            newObject.delegationHistory.push(deleg)
                                                        })
                                                    }
                                                    newObject.delegateTo = []
                                                    newObject.arbitrateText = ''
                                                    newObject.arbitratorsFunctions = []
                                                    callback(null, newObject, oldObject)
                                                })
                                            } else {
                                                newObject.status = {id: '5'}
                                                newObject.workflow = ['refused', 1000000]
                                                newObject.contributorsFunctions = []
                                                if (!!newObject.delegateTo.length) {
                                                    newObject.delegateTo.forEach(deleg => {
                                                        newObject.delegationHistory.push(deleg)
                                                    })
                                                }
                                                newObject.delegateTo = []
                                                newObject.arbitrateText = ''
                                                newObject.arbitratorsFunctions = []
                                                callback(null, newObject, oldObject)
                                            }
                                        }
                                    )
                                } else if (context.action === 'abandon') {
                                    const currentStep = newObject.workflow[0]
                                    if (!!newObject.contributorsFunctions.length) {
                                        if (newObject.alreadyTreatedByFunctions[currentStep]) {
                                            newObject.alreadyTreatedByFunctions[currentStep].push(newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString()))
                                        } else {
                                            newObject.alreadyTreatedByFunctions[currentStep] = [newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString())]
                                        }
                                    }

                                    const demandConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep)
                                    let demandRolesIDs = demandConfigs.map(conf => global.ObjectID(conf.role.id))
                                    demandRolesIDs = demandRolesIDs.concat(readersIDs)
                                    return global.app.SE.Habilitation.find(
                                        {
                                            ...basicContext(context),
                                            fieldPath: ["user.id"],
                                            query: {
                                                "role": {$in: demandRolesIDs},
                                                $or: [{
                                                    "grantedAccess": [],
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": [],
                                                    "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                }]
                                            }
                                        }, (e, nextUsersList) => {
                                            if (!!nextUsersList.length) {
                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'Demande abandonnée' : 'Projet abandonné'
                                                const mails = nextUsersList.map(hab => {
                                                    const userMail = hab.user.mail
                                                    const subject = `${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : Abandonné)`
                                                    const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                    return createAbondonMail(context, userMail, subject, newObject, stepAction)
                                                })
                                                global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    newObject.status = {id: '9'}
                                                    newObject.workflow = ['abandoned', 1000000]
                                                    newObject.contributorsFunctions = []
                                                    if (!!newObject.delegateTo.length) {
                                                        newObject.delegateTo.forEach(deleg => {
                                                            newObject.delegationHistory.push(deleg)
                                                        })
                                                    }
                                                    newObject.delegateTo = []
                                                    newObject.arbitrateText = ''
                                                    newObject.arbitratorsFunctions = []
                                                    callback(error, newObject, oldObject)
                                                })
                                            } else {
                                                newObject.status = {id: '9'}
                                                newObject.workflow = ['abandoned', 1000000]
                                                newObject.contributorsFunctions = []
                                                if (!!newObject.delegateTo.length) {
                                                    newObject.delegateTo.forEach(deleg => {
                                                        newObject.delegationHistory.push(deleg)
                                                    })
                                                }
                                                newObject.delegateTo = []
                                                newObject.arbitrateText = ''
                                                newObject.arbitratorsFunctions = []
                                                callback(error, newObject, oldObject)
                                            }
                                        }
                                    )
                                } else if (context.action === 'delegate') {
                                    // if userIsInDelegateTo && (habnotok || rolenotok) {
                                    // cannot delagate}
                                    const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'
                                    if (!!newObject.delegateTo.length) {
                                        if (!userIsInDelegateTo || (userAugmentedHabOk && userRoleOk)) {
                                            // le 2ème cas : si jamais on a ajouté par erreur un intervenant actuel au delegateTo
                                            // (makes sense only if we cannot delete users from delegateTo)
                                            const oldDelagation = !!oldObject ? oldObject.delegateTo.map(user => global.ObjectID(user.id)) : []
                                            const addedDelegation = substractedItems2(newObject.delegateTo, oldDelagation)
                                            if (!!addedDelegation.length) {
                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                const mails = addedDelegation.map(user => {
                                                    const userMail = user.mail
                                                    const subject = `Délégation ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(newObject.workflow[0])})`
                                                    const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                    const expectedAction = choseExpectedAction(newObject.workflow[0], context.action)
                                                    const module = moduleName(newObject.workflow[0])
                                                    return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                })
                                                return global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    callback(error, newObject, oldObject)
                                                })
                                            } else {
                                                return callback(new Errors.ValidationError('you have to add at least one user to delegate to'))
                                            }
                                        } else {
                                            return callback(new Errors.ValidationError('you cannot delegate to yourself'))
                                        }
                                    } else {
                                        return callback(new Errors.ValidationError('you have to choose at least one user to delegate to'))
                                    }
                                } else if (context.action === 'arbitrate') {
                                    return callback(new Errors.ValidationError("you can't do such action on this module" ))
                                }
                            }
                        }
                    }
                }
            })
        }
        if (context.module && ['Instruction', 'Control', 'Validation', 'Realization', 'arbitration', 'DemandControl', 'DemandValidation', 'DemandArbitration'].includes(context.module.name)) {
            async.parallel([
                callback => {
                    return global.app.SE.FiscalYear.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query: {"fiscalYearStatus": {$in: ["1", "3"]}}
                    }, callback)
                },
                callback => {
                    return global.app.SE.ImputationType.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query: {}
                    }, callback)
                },
                callback => {
                    return global.app.SE.RealizationType.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query: {}
                    }, callback)
                },
                callback => {
                    const query = {
                        "fiscalYearRange.0": {$lte: moment().toDate()},
                        "fiscalYearRange.1": {$gte: moment().toDate()},
                    }
                        /*!!newObject.revueDate
                        ? {
                            "fiscalYearRange.0": {$lte: moment(newObject.revueDate, 'YYYY-MM-DD').toDate()},
                            "fiscalYearRange.1": {$gte: moment(newObject.revueDate, 'YYYY-MM-DD').toDate()},
                        } : {
                        "fiscalYearRange.0": {$lte: moment().toDate()},
                        "fiscalYearRange.1": {$gte: moment().toDate()},
                        }
                         */
                    return global.app.SE.FiscalYear.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query
                    }, callback)
                },
                // looking for the start fiscal year of this demand
                callback => {
                    const query = moment().format("YYYY-MM") === newObject.startMonth ?
                        {
                            "fiscalYearRange.0": {$lte: moment().toDate()},
                            "fiscalYearRange.1": {$gte: moment().toDate()},
                        }
                        : {
                            "fiscalYearRange.0": {$lte: moment(newObject.startMonth, 'YYYY-MM').toDate()},
                            "fiscalYearRange.1": {$gte: moment(newObject.startMonth, 'YYYY-MM').toDate()},
                        }
                    return global.app.SE.FiscalYear.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query
                    }, callback)
                },
                // looking for the end fiscal year of this demand
                callback => {
                    const query = moment().format("YYYY-MM") === newObject.endMonth ?
                        {
                            "fiscalYearRange.0": {$lte: moment().toDate()},
                            "fiscalYearRange.1": {$gte: moment().toDate()},
                        }
                        : {
                            "fiscalYearRange.0": {$lte: moment(newObject.endMonth, 'YYYY-MM').toDate()},
                            "fiscalYearRange.1": {$gte: moment(newObject.endMonth, 'YYYY-MM').toDate()},
                        }
                    return global.app.SE.FiscalYear.find({
                        ...basicContext(context),
                        fieldPath: [],
                        query
                    }, callback)
                },
                // looking for the workflow for this demand,
                callback => global.app.SE.WorkFlow.find({
                    ...basicContext(context),
                    fieldPath: ["workFlowConfigs.step", "workFlowConfigs.order", "workFlowConfigs.role.id"],
                    query: {
                        "organizationAndMesh": {$elemMatch: {$eq: global.ObjectID(newObject.organizationAndMesh.id)}},
                        "demandCategory": {$eq: global.ObjectID(newObject.demandCategory.id)},
                        "demandNature": {$elemMatch: {$eq: global.ObjectID(newObject.demandNature.id)}},
                        "minMaxAmount": global.ObjectID(newObject.plafond.id)
                    }
                }, callback),
                // looking for the habilitation of this user,
                callback => global.app.SE.Habilitation.find({
                    ...basicContext(context),
                    fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                    query: {user: global.ObjectID(context.user.id)}
                }, callback),
                // userGrantedOrgsAndMeshesPerRole
                callback => {
                    if (context.module && context.module.name === "arbitration") {
                        return callback(null, [])
                    }
                    global.app.SE.WorkFlow.find({
                        ...basicContext(context),
                        fieldPath: ["workFlowConfigs.step", "workFlowConfigs.order", "workFlowConfigs.role.id"],
                        query: {
                            "organizationAndMesh": {$elemMatch: {$eq: global.ObjectID(newObject.organizationAndMesh.id)}},
                            "demandCategory": {$eq: global.ObjectID(newObject.demandCategory.id)},
                            "demandNature": {$elemMatch: {$eq: global.ObjectID(newObject.demandNature.id)}},
                            "minMaxAmount": global.ObjectID(newObject.plafond.id)
                        }
                    }, (e, worlflows) => {
                        if (e) {
                            return callback(e)
                        }
                        if (!worlflows.length) {
                            callback(null, [])
                        } else if (worlflows.length !== 1) {
                            return callback(null, [])
                        } else {
                            const _workflowConf = worlflows[0]
                            const currentConfigs = !!newObject.workflow && !!newObject.workflow[0] && newObject.workflow[0] !== 'draft' ? _workflowConf.workFlowConfigs.filter(conf => conf.step === newObject.workflow[0] && conf.order === parseInt(newObject.workflow[1])) : _workflowConf.workFlowConfigs.filter(conf => conf.step === "demand" && conf.order === 1)
                            const currentRolesIDs = currentConfigs.map(conf => global.ObjectID(conf.role.id))
                            global.app.SE.Habilitation.find({
                                ...basicContext(context),
                                fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                                query: {
                                    user: global.ObjectID(context.user.id),
                                    role: {$in: currentRolesIDs}
                                }
                            }, (e, userHabilitations) => {
                                if (e) {
                                    return callback(e)
                                }
                                if (!userHabilitations.length) {
                                    callback(null, [])
                                } else {
                                    let userGrantedOrgsAndMeshes = []   //array of arrays
                                    userHabilitations.forEach(hab => {
                                        userGrantedOrgsAndMeshes.push(hab.grantedAccess)
                                        userGrantedOrgsAndMeshes.push(hab.grantedMesh)
                                    })
                                    const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)
                                    if (!userGrantedOrgsAndMeshesIDs.length) {
                                        if (!userHaveExhaustifScope(userHabilitations)) {
                                            callback(new Errors.ValidationError('Vous ne disposez pas des autorisations nécessaires pour accomplir cette action'))
                                        } else {
                                            callback(null, []) //if exhaustif, we verifie Habs directly
                                        }
                                    } else {
                                        global.app.SE.OrganizationalMesh.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: [],
                                                query: {
                                                    $or: [
                                                        {"attachments": {$elemMatch: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}},
                                                        {_id: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                                    ]
                                                }
                                            }, (er, meshes) => {
                                                if (er) return callback(er)
                                                let userGrantedOrgsAndMeshesPerRole = {}
                                                userHabilitations.forEach(hab => {
                                                    let orgsAndMeshes = []
                                                    orgsAndMeshes.push(hab.grantedAccess)
                                                    orgsAndMeshes.push(hab.grantedMesh)
                                                    userGrantedOrgsAndMeshesPerRole[hab.role.id] = orgsAndMeshes.flat(1).map(org => org.id)
                                                })
                                                if (!!meshes.length) {
                                                    meshes.forEach(mesh => {
                                                        Object.keys(userGrantedOrgsAndMeshesPerRole).forEach(role => {
                                                            if (userGrantedOrgsAndMeshesPerRole[role].includes(mesh.id)) {
                                                                userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                                mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                            } else if (mesh.attachments.some(orgId => userGrantedOrgsAndMeshesPerRole[role].includes(orgId.toString()))) {
                                                                userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                                mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                            }
                                                        })
                                                    })
                                                }
                                                callback(er, userGrantedOrgsAndMeshesPerRole)
                                            }
                                        )
                                    }
                                }
                            })
                        }
                    })
                },
                // demandAugmentedOrgsAndMeshes
                callback => {
                    const action = context.restAction && context.restAction.crudType
                    if (["save", "delegate"].includes(context.action)) return callback()
                    global.app.SE.OrganizationalMesh.find(
                        {
                            ...basicContext(context),
                            fieldPath: [],
                            query: {
                                $or: [
                                    {"attachments": {$elemMatch: {$eq: global.ObjectID(newObject.organizationAndMesh.id)}}},
                                    {_id: {$eq: global.ObjectID(newObject.organizationAndMesh.id)}}
                                ]
                            }
                        }, (ero, meshes) => {
                            if (ero) return callback(ero)
                            let demandAugmentedOrgsAndMeshes = []
                            let finalResult = []
                            if (!meshes.length) {
                                finalResult = [global.ObjectID(newObject.organizationAndMesh.id)]
                            } else {
                                demandAugmentedOrgsAndMeshes.push(meshes.map(mesh => global.ObjectID(mesh.id)))
                                const relatedOrganizationsIDsList = meshes.map(mesh => mesh.attachments) //liste de liste des ids
                                const relatedOrganizationsIDs = relatedOrganizationsIDsList.flat(1)
                                let uniqueStringRelatedOrganizationsIDs = []
                                relatedOrganizationsIDs.forEach(function (org) {
                                    if (!uniqueStringRelatedOrganizationsIDs.includes(JSON.stringify(org))) {
                                        uniqueStringRelatedOrganizationsIDs.push(JSON.stringify(org))
                                    }
                                })
                                demandAugmentedOrgsAndMeshes.push(uniqueStringRelatedOrganizationsIDs.map(stringID => global.ObjectID(JSON.parse(stringID))))
                                finalResult = demandAugmentedOrgsAndMeshes.flat(1)
                            }
                            callback(ero, finalResult)
                        }
                    )
                },
                // arbitratorGrantedOrgsAndMeshesPerRole
                callback => {
                    if (context.module && context.module.name !== "arbitration") {
                        return callback(null, [])
                    }
                    //const _workflowConf = worlflows[0]
                    //const currentConfigs = newObject.workflow && newObject.workflow[0] ? _workflowConf.workFlowConfigs.filter( conf => conf.step === newObject.workflow[0] && conf.order === parseInt(newObject.workflow[1]) ) : _workflowConf.workFlowConfigs.filter( conf => conf.step === "demand" && conf.order === 1 )
                    //const currentRolesIDs = currentConfigs.map(conf => global.ObjectID(conf.role.id))
                    //const arbitratorsIds = _workflowConf.arbitrators.map(id => global.ObjectID(id)) || []
                    global.app.SE.Habilitation.find({
                        ...basicContext(context),
                        fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                        query: {
                            user: global.ObjectID(context.user.id),
                            //role: { $in : arbitratorsIds }
                        }
                    }, (e, userHabilitations) => {
                        if (e) {
                            return callback(e)
                        }
                        if (!userHabilitations.length) {
                            callback(null, [])
                        } else {
                            let userGrantedOrgsAndMeshes = []   //array of arrays
                            userHabilitations.forEach(hab => {
                                userGrantedOrgsAndMeshes.push(hab.grantedAccess)
                                userGrantedOrgsAndMeshes.push(hab.grantedMesh)
                            })
                            const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)
                            if (!userGrantedOrgsAndMeshesIDs.length) {
                                if (!userHaveExhaustifScope(userHabilitations)) {
                                    callback(new Errors.ValidationError('Vous ne disposez pas des autorisations nécessaires pour accomplir cette action'))
                                } else {
                                    callback(null, []) //if exhaustif, we verifie Habs directly
                                }
                            } else {
                                global.app.SE.OrganizationalMesh.find(
                                    {
                                        ...basicContext(context),
                                        fieldPath: [],
                                        query: {
                                            $or: [
                                                {"attachments": {$elemMatch: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}},
                                                {_id: {$in: userGrantedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                            ]
                                        }
                                    }, (er, meshes) => {
                                        if (er) return callback(er)
                                        let userGrantedOrgsAndMeshesPerRole = {}
                                        userHabilitations.forEach(hab => {
                                            let orgsAndMeshes = []
                                            orgsAndMeshes.push(hab.grantedAccess)
                                            orgsAndMeshes.push(hab.grantedMesh)
                                            userGrantedOrgsAndMeshesPerRole[hab.role.id] = orgsAndMeshes.flat(1).map(org => org.id)
                                        })
                                        if (!!meshes.length) {
                                            meshes.forEach(mesh => {
                                                Object.keys(userGrantedOrgsAndMeshesPerRole).forEach(role => {
                                                    if (userGrantedOrgsAndMeshesPerRole[role].includes(mesh.id)) {
                                                        userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                        mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                    } else if (mesh.attachments.some(orgId => userGrantedOrgsAndMeshesPerRole[role].includes(orgId.toString()))) {
                                                        userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                                        mesh.attachments.forEach(att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()))
                                                    }
                                                })
                                            })
                                        }
                                        callback(er, userGrantedOrgsAndMeshesPerRole)
                                    }
                                )
                            }
                        }
                    })
                },
                callback => global.app.SE.AnalyticalAxis.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {isProjectAxis: true}
                }, callback),
                /**
                 * we firstly made two different queries for arbitration module and for other modules,
                 * because the workflow could be modified and therefore the current Functions stored in the demand
                 * would be outdated.
                 * This problem does not exists anymore, because we update the demands directly after a modification in a workflow.
                 * So, the 2 queries should be unified.
                 */
            ], (error, results) => {
                if (error) return callback(error)
                const [nonClosedFiscalYears, availableImputationTypes, availableRealizationTypes, currentFiscalYears, demandStartFiscalYears, demandEndFiscalYears, workflowConfs, userHabilitations, userGrantedOrgsAndMeshesPerRole, demandAugmentedOrgsAndMeshesIDs, arbitratorGrantedOrgsAndMeshesPerRole, projectAnalyticalAxes] = results
                const userIsInDelegateTo = (newObject.delegateTo && newObject.delegateTo.map(user => user.id).includes(context.user.id))
                const userIsInCurrentContributors = (newObject.currentContributors && newObject.currentContributors.map(user => user.id).includes(context.user.id))
                if (!currentFiscalYears.length || currentFiscalYears.length!==1){
                    return callback(new Errors.ValidationError("L'exercice actuel n'a pas été configuré"))
                }
                if (!demandStartFiscalYears.length || !demandEndFiscalYears.length) {
                    return callback(new Errors.ValidationError("Aucun exercice n'a été configuré pour le mois que vous avez choisi"))
                }
                if (demandStartFiscalYears.length !== 1 || demandEndFiscalYears.length !== 1) {
                    return callback(new Errors.ValidationError("Aucun exercice n'a été configuré pour le mois que vous avez choisi"))
                }
                if (!userHabilitations.length && !userIsInDelegateTo) {
                    return callback(new Errors.ValidationError('you are not referenced neither in Habilitation module nor in delegate to'))
                }
                if (!workflowConfs.length) {
                    return callback(new Errors.ValidationError('your demand have no defined workflow'))
                } else if (workflowConfs.length !== 1) {
                    return callback(new Errors.ValidationError('your demand have more than one workflow'))
                } else {
                    //NCHOF current roles mn workflow => njib les Hab li 3ndhom dok roles w njbed mnhom l orgs => meshes...

                    /**
                     * we assign the appropriate start fiscal year and end fiscal year to the demand
                     * it could be modified after its creation (in preValidation or instruction steps)
                     */
                    const currentFiscalYear = currentFiscalYears[0]
                    const demandStartFiscalYear = demandStartFiscalYears[0]
                    const demandEndFiscalYear = demandEndFiscalYears[0]
                    newObject.startFiscalYear = {id: demandStartFiscalYear.id, fiscalYearRange: demandStartFiscalYear.fiscalYearRange}
                    newObject.endFiscalYear = {id: demandEndFiscalYear.id, fiscalYearRange: demandEndFiscalYear.fiscalYearRange}

                    const _workflowConf = workflowConfs[0]
                    const readersIDs = _workflowConf.readers.map(role => global.ObjectID(role.id))
                    const action = context.restAction && context.restAction.crudType
                    //let [nextStep, nextOrder] = getActualNextStepNextOrder(action, context.action, newObject.workflow, _workflowConf)
                    const userRoles = userHabilitations.map(hab => hab.role.id) /// t2eked wach c'est la bonne forme wla nzid objectID
                    //const userAugmentedHabOk = userRoles.some( roleID => newObject.contributorsFunctions.map( role => role.id).includes(roleID) && ( userHaveExhaustifScope(userHabilitations) || ( userGrantedOrgsAndMeshesPerRole[roleID] && userGrantedOrgsAndMeshesPerRole[roleID].includes( newObject.organizationAndMesh.id.toString() ))) )
                    if (action === 'C') {
                        return callback(new Errors.ValidationError("you can't do such action on this module"))
                    }
                    //verified to here
                    else {
                        const orderOfSteps = generateOrderOfSteps(steps, _workflowConf.workFlowConfigs)
                        const firstStep = Object.keys(orderOfSteps)[0]
                        const firstConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep && conf.order === 1)
                        const firstRolesIDs = firstConfigs.map(conf => conf.role.id)
                        const firstUserRoleOk = userRoles.some(role => firstRolesIDs.includes(role))
                        const firstUserAugmentedHabOk = userRoles.some(roleID => firstRolesIDs.includes(roleID) && (userHaveExhaustifScope(userHabilitations) || (!!userGrantedOrgsAndMeshesPerRole[roleID] && userGrantedOrgsAndMeshesPerRole[roleID].includes(newObject.organizationAndMesh.id.toString()))))
                        let userRoleOk = false
                        let userAugmentedHabOk = false
                        if (context.module && ['arbitration', 'DemandArbitration'].includes(context.module.name) ) {
                            userRoleOk = userRoles.some(role => newObject.arbitratorsFunctions.map(func => func.id).includes(role))
                            userAugmentedHabOk = userRoles.some(roleID => newObject.arbitratorsFunctions.map(role => role.id).includes(roleID) && (userHaveExhaustifScope(userHabilitations) || (arbitratorGrantedOrgsAndMeshesPerRole[roleID] && arbitratorGrantedOrgsAndMeshesPerRole[roleID].includes(newObject.organizationAndMesh.id.toString()))))
                            let [nextStep, nextOrder] = getAndValidateNextStep(newObject.workflow[0], parseInt(newObject.workflow[1]), _workflowConf.workFlowConfigs, newObject.alreadyTreatedByFunctions)
                        } else {
                            userRoleOk = userRoles.some(role => newObject.contributorsFunctions.map(func => func.id).includes(role))
                            userAugmentedHabOk = userRoles.some(roleID => newObject.contributorsFunctions.map(role => role.id).includes(roleID) && (userHaveExhaustifScope(userHabilitations) || (userGrantedOrgsAndMeshesPerRole[roleID] && userGrantedOrgsAndMeshesPerRole[roleID].includes(newObject.organizationAndMesh.id.toString()))))
                        }
                        if (!(userRoleOk && userAugmentedHabOk) && !userIsInDelegateTo && !(userIsInCurrentContributors && firstUserRoleOk && firstUserAugmentedHabOk)) {
                            return callback(new Errors.ValidationError('you are not referenced as a contributor on this step'))
                        } else {
                            if (context.module && ['Instruction'].includes(context.module.name)) {
                                if (!(0 < newObject.totalAmount && newObject.plafond.max >= newObject.totalAmount)) {
                                    return callback(new Errors.ValidationError('The total amount should respect the selected min/max amount'))
                                }
                            }
                            newObject.arbitrationType = {id: _workflowConf.arbitrationType}
                            newObject.delegationOption = {id: _workflowConf.delegationOption}
                            if (context.action === 'save') {//same step, same order
                                return callback(null, newObject, oldObject)
                            } else {
                                const sortedCommentsDESC = newObject.comments.sort((a, b) => moment(a.date).format('YYYY-MM-DD HH:MM:ss') - moment(b.date).format('YYYY-MM-DD HH:MM:ss')).map(comment => comment.text).reverse()
                                let lastComments = []
                                for (let i = 0; i < sortedCommentsDESC.length; i++) {
                                    const commentFormat = sortedCommentsDESC[i].split(",").map(info => info.split(":")[0].trim())
                                    const isItAutomaticComment = commentFormat.length === 3 && commentFormat[0] === "step" && commentFormat[1] === "order" && commentFormat[2] === "action"
                                    const stepOrderAction = sortedCommentsDESC[i].split(",").map(info => info.split(":")[1] && info.split(":")[1].trim())
                                    const lastCommentsEndHere = commentFormat.length === 3 && !["delegate", "save"].includes(stepOrderAction[2])
                                    if (!isItAutomaticComment) {
                                        lastComments.push(sortedCommentsDESC[i])
                                    } else if (lastCommentsEndHere) {
                                        break
                                    }
                                }
                                lastComments = lastComments.reverse()
                                if (newObject && newObject.workflow[0] === "draft") {
                                    if (context.action === "validate") {
                                        newObject.comments.push({
                                            automatic: true,
                                            user: _.pick(context.user, ['id', 'name']),
                                            text: getStepNameForComment(newObject.workflow[0]) + ', ' + "save",
                                            date: moment().format("YYYY-MM-DD HH:mm:ss")
                                        })
                                    } else if (context.action === "transmit") {
                                        newObject.comments.push({
                                            automatic: true,
                                            user: _.pick(context.user, ['id', 'name']),
                                            text: getStepNameForComment(newObject.workflow[0]) + ', ' + "validate",
                                            date: moment().format("YYYY-MM-DD HH:mm:ss")
                                        })
                                    }
                                } else {
                                    newObject.comments.push({
                                        automatic: true,
                                        user: _.pick(context.user, ['id', 'name']),
                                        text: getStepNameForComment(newObject.workflow[0]) + ', ' + context.action,
                                        date: moment().format("YYYY-MM-DD HH:mm:ss")
                                    })
                                }
                                if (context.action === "validate") {
                                    newObject.currentContributors = []
                                    const currentStep = newObject.workflow[0]
                                    if (!newObject.alreadyTreatedByFunctions) {
                                        newObject.alreadyTreatedByFunctions = {}
                                        newObject.alreadyTreatedByFunctions[firstStep] = []
                                    }
                                    if (currentStep !== "draft" && !!newObject.contributorsFunctions.length) {
                                        if (newObject.alreadyTreatedByFunctions[currentStep]) {
                                            newObject.alreadyTreatedByFunctions[currentStep].push(newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString()))
                                        } else {
                                            newObject.alreadyTreatedByFunctions[currentStep] = [newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString())]
                                        }
                                    }
                                    //let [nextStep, nextOrder] = getNextStepNextOrder(currentStep, currentOrder, workFlowConfigs)
                                    let [nextStep, nextOrder] = getAndValidateNextStep(newObject.workflow[0], parseInt(newObject.workflow[1]), _workflowConf.workFlowConfigs, newObject.alreadyTreatedByFunctions)
                                    const configsIfNextStep = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                    let rolesIDsIfNextStep = configsIfNextStep.map(conf => conf.role.id)
                                    let alreadyTreatedByFunctionsCopy = _.cloneDeep(newObject.alreadyTreatedByFunctions)
                                    if (alreadyTreatedByFunctionsCopy[nextStep]) {
                                        alreadyTreatedByFunctionsCopy[nextStep].push(rolesIDsIfNextStep.map(id => global.ObjectID(id).toString()))
                                    } else {
                                        alreadyTreatedByFunctionsCopy[nextStep] = [rolesIDsIfNextStep.map(id => global.ObjectID(id).toString())]
                                    }
                                    if (["approved", "closed", "realization"].includes(nextStep)) {
                                        let nextConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                        let nextRolesIDs = nextConfigs.map(conf => global.ObjectID(conf.role.id))
                                        let currentConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === newObject.workflow[0] && conf.order === parseInt(newObject.workflow[1]))
                                        let currentRolesIDs = currentConfigs.map(conf => global.ObjectID(conf.role.id))
                                        let rolesIds = !!nextRolesIDs && !!nextRolesIDs.length ? nextRolesIDs : currentRolesIDs
                                        newObject.followFunctions = rolesIds
                                    }
                                    let [nextNextStep, nextNextOrder] = getAndValidateNextStep(nextStep, nextOrder, _workflowConf.workFlowConfigs, alreadyTreatedByFunctionsCopy)
                                    newObject.arbitratorsFunctions = []
                                    newObject.arbitrateText = ''
                                    if (!!newObject.delegateTo.length) {
                                        newObject.delegateTo.forEach(deleg => {
                                            newObject.delegationHistory.push(deleg)
                                        })
                                    }
                                    newObject.delegateTo = []
                                    // todo check
                                    const isItLastPreValidation = newObject.workflow[0] === "preValidation" && nextStep !== "preValidation"
                                    const isItLastLastPreValidation = nextStep === "preValidation" && nextNextStep !== "preValidation"
                                    const isItLastLastValidation = nextStep === "validation" && nextNextStep !== "validation"
                                    const isItLastValidation = newObject.workflow[0] === "validation" && nextStep !== "validation"
                                    const arbitorWasDemanded = newObject.arbitrateTextList && !!newObject.arbitrateTextList.length && newObject.arbitrateTextList.some(comment => comment.arbitrationType.id === "2")
                                    //const arbitorWasDemandedButNotYetActive2 = _workflowConf.arbitrationType === null && (newObject.arbitrateTextList && !!newObject.arbitrateTextList.length && newObject.arbitrateTextList.some( comment => comment.arbitrationType.id === "2" ))
                                    const arbitration2IsNeeded = newObject.arbitorNotYetActive
                                    if ((context.module && context.module.name !== "arbitration") && arbitration2IsNeeded) {
                                        if (isItLastLastValidation || isItLastValidation || isItLastPreValidation || isItLastLastPreValidation) {
                                            let arbitratorsIds = _workflowConf.arbitrators.map(id => id) || []
                                            const validationConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                            const lastValidatorsRolesIDs = validationConfigs.map(conf => conf.role.id)
                                            const lastValidatorsAreArbitors = compareArrays(arbitratorsIds.map(id => id.toString()), lastValidatorsRolesIDs) || _workflowConf.arbitrationType === null
                                            if (isItLastValidation || lastValidatorsAreArbitors || isItLastPreValidation) {
                                                if (isItLastLastValidation) {
                                                    newObject.workflow = [nextStep, nextOrder]
                                                    if (newObject.alreadyTreatedByFunctions[nextStep]) {
                                                        newObject.alreadyTreatedByFunctions[nextStep].push(arbitratorsIds.map(id => global.ObjectID(id).toString()))
                                                    } else {
                                                        newObject.alreadyTreatedByFunctions[nextStep] = [arbitratorsIds.map(id => global.ObjectID(id).toString())]
                                                    }
                                                }
                                                if (lastValidatorsAreArbitors && _workflowConf.arbitrationType === null) {
                                                    arbitratorsIds = lastValidatorsRolesIDs.map(id => global.ObjectID(id))
                                                }
                                                //////////////if (isItLastLastValidation && arbitorWasDemandedButNotYetActive2) { newObject.workflow = [nextStep, nextOrder] }
                                                return global.app.SE.Habilitation.find(
                                                    {
                                                        ...basicContext(context),
                                                        fieldPath: ["user.id"],
                                                        query: {
                                                            "role": {$in: arbitratorsIds},
                                                            $or: [{
                                                                "grantedAccess": [],
                                                                "grantedMesh": []
                                                            }, {
                                                                "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                                "grantedMesh": []
                                                            }, {
                                                                "grantedAccess": [],
                                                                "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                            }]
                                                        }
                                                    }, (e, nextUsersList) => {
                                                        if (!!nextUsersList.length) {
                                                            const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                            const mails = nextUsersList.map(hab => {
                                                                const userMail = hab.user.mail
                                                                const subject = `Demande d'arbitrage ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : Arbitrage)`
                                                                const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                                const expectedAction = choseExpectedAction(newObject.workflow[0], "arbitrate")
                                                                const module = 'arbitrage'
                                                                return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                            })
                                                            global.mailer.sendMail(mails, (error) => {
                                                                if (error) return callback(error)
                                                                newObject.status = {id: '11'}
                                                                //arbitratorsIDs.forEach( id => {
                                                                //    newObject.contributorsFunctions.push({id})
                                                                //})
                                                                newObject.arbitratorsFunctions = arbitratorsIds
                                                                newObject.contributorsFunctions = []
                                                                newObject.arbitrateText = ''
                                                                newObject.arbitorNotYetActive = false
                                                                callback(error, newObject, oldObject)
                                                            })
                                                        } else {
                                                            callback(new Errors.ValidationError('this demand have no arbitrators'))
                                                        }
                                                    }
                                                )
                                            }
                                        }
                                    }

                                    let auxiliaryQuery = {}
                                    let draftOrNextStep = nextStep
                                    if (newObject.workflow[0] === "draft") {
                                        draftOrNextStep = "draft"
                                    }
                                    const isItComplementaryProject = !!newObject.relatedProject
                                    const isComplementaryDemand = !!newObject.relatedDemand
                                    const complementaryProjectToBeValidated = context?.module?.category?.path === 'Projet' && isItLastValidation && isItComplementaryProject
                                    const complementaryDemandToBeValidated = context?.module?.category?.path === 'demande' && isItLastValidation && isComplementaryDemand
                                    const mustEndValidationProcess =  complementaryProjectToBeValidated || complementaryDemandToBeValidated
                                    const projectAnalyticalAxesIsGood = !!projectAnalyticalAxes.length && projectAnalyticalAxes.length === 1
                                    const timeToPrepareAnalyticalStructureAndBudgets = context?.module?.category?.path === 'Projet' && isItLastValidation && !newObject.relatedProject
                                    const timeToPrepareRevisedTable = context?.module?.category?.path === 'Projet' && !newObject.relatedProject && nextStep === "realization"
                                    // creation in instruction
                                    //modification in nextstep = realization
                                        //['Instruction', 'Control', 'Validation', 'Realization', 'arbitration', 'DemandControl', 'DemandValidation', 'DemandArbitration'//]
                                    const timeToAddEngagedAmountIntoRevisedTable = context?.module?.category?.path === 'demande' && !!newObject.relatedProject && isItLastValidation
                                        // todo


                                    if (timeToPrepareRevisedTable){
                                        let revisedTableResult = []
                                        let list = []
                                        availableRealizationTypes.forEach(type =>{
                                            list.push({
                                                realizationType: {id: type.id },
                                                budgetColumnType: {id: '1'},  //global
                                                amount: 0
                                            })
                                            list.push({
                                                realizationType: {id: type.id },
                                                budgetColumnType: {id: '2'}, //yearly
                                                amount: 0
                                            })
                                        })

                                        //filledDirectlyByUser
                                        newObject.imputations.forEach( imp => {
                                            const desiredMonthAndAmounts = imp.monthAndAmountList.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === currentFiscalYear.id.toString())
                                            const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)

                                            const validated = imp.totalAmountPerImputation
                                            const budget = imp.totalAmountPerImputation
                                            const ongoingFiscalYearValidated = fiscalYearValidatedAmount
                                            const ongoingFiscalYearBudget = fiscalYearValidatedAmount
                                            revisedTableResult.push({
                                                _id: new global.ObjectID(),
                                                objet: imp.objet,
                                                entity: global.ObjectID(imp.organizationalMesh.id),
                                                imputationType: global.ObjectID(imp.imputationType.id),
                                                //imputationTriplet: imp.objet + '-' + imp.organizationalMesh.name + '-' + imputationType.name,
                                                estimated: 0,
                                                validated,
                                                budget,
                                                revised: 0,
                                                validatedEngagement: 0,
                                                ongoingFiscalYearValidatedEngagement: 0,
                                                ongoingFiscalYearEstimated: 0,
                                                ongoingFiscalYearValidated,
                                                ongoingFiscalYearBudget,
                                                ongoingFiscalYearRevised: 0,
                                                amountByRealizationType: list,
                                                updated: null,
                                                user: null,
                                                //demand: global.ObjectID(newObject.id),
                                                group: new global.ObjectID(_.get(context, 'group.id'))
                                            })
                                        })

                                        //Others
                                        newObject.imputations.forEach( imp => {
                                            availableImputationTypes.forEach( imputationType => { //todo check this part
                                                const filledByUser = newObject.imputations.some(obj => obj.objet === imp.objet && obj.organizationalMesh.id.toString() === imp.organizationalMesh.id.toString() && obj.imputationType.id.toString() === imputationType.id.toString())
                                                if (!filledByUser){
                                                    revisedTableResult.push({
                                                        _id: new global.ObjectID(),
                                                        objet: imp.objet,
                                                        entity: global.ObjectID(imp.organizationalMesh.id),
                                                        imputationType: global.ObjectID(imputationType.id),
                                                        //imputationTriplet: imp.objet + '-' + imp.organizationalMesh.name + '-' + imputationType.name,
                                                        estimated: 0,
                                                        validated: 0,
                                                        budget: 0,
                                                        revised: 0,
                                                        validatedEngagement: 0,
                                                        ongoingFiscalYearValidatedEngagement: 0,
                                                        ongoingFiscalYearEstimated: 0,
                                                        ongoingFiscalYearValidated: 0,
                                                        ongoingFiscalYearBudget: 0,
                                                        ongoingFiscalYearRevised: 0,
                                                        amountByRealizationType: list,
                                                        updated: null,
                                                        user: null,
                                                        //demand: global.ObjectID(newObject.id),
                                                        group: new global.ObjectID(_.get(context, 'group.id'))
                                                    })
                                                }
                                            })
                                        })

                                        newObject.revisedTable = revisedTableResult
                                        newObject.revueDate = moment().format('YYYY-MM-DD')
                                    }

                                    if (timeToPrepareAnalyticalStructureAndBudgets){
                                        if (projectAnalyticalAxesIsGood){
                                            global.app.SE.AnalyticalSubAxis.collection.updateOne(
                                                {code: !newObject.relatedProject? newObject.demandNumber : newObject.relatedProject.demandNumber}, // Filter
                                                {
                                                    $setOnInsert: {
                                                        code: !newObject.relatedProject? newObject.demandNumber : newObject.relatedProject.demandNumber,
                                                        name: !newObject.relatedProject? newObject.title : newObject.relatedProject.title,
                                                        analyticalAxis: new global.ObjectID(projectAnalyticalAxes[0].id),
                                                        //followTable: null,
                                                        group: new global.ObjectID(_.get(context, 'group.id'))
                                                    }
                                                },
                                                { upsert: true }, // Inserts a new document using the $setOnInsert fields if no matching document is found
                                                (e) => {
                                                    if (e) return callback(e)
                                                    global.app.SE.AnalyticalSubAxis.find({
                                                        ...basicContext(context),
                                                        fieldPath: [],
                                                        query: {
                                                            code: !newObject.relatedProject ? newObject.demandNumber : newObject.relatedProject.demandNumber
                                                        }
                                                    },(e, projects) =>{
                                                        if (e) return callback(e)
                                                        if (!!projects.length && projects.length === 1){
                                                            const imputationsObjets = newObject.imputations.map( imp => imp.objet )
                                                            const uniqueObjets = [...new Set(imputationsObjets)];
                                                            let bulkWrites = [{ deleteOne: {filter: { _id: null }} }]
                                                            uniqueObjets.forEach(objet =>{
                                                                bulkWrites.push({
                                                                    updateOne: {
                                                                        filter: {
                                                                            code: objet,
                                                                            attachment: {$elemMatch: {$eq: global.ObjectID(projects[0].id)} }
                                                                        },
                                                                        update: {
                                                                            $setOnInsert: {
                                                                                code: objet,
                                                                                name: objet,
                                                                                attachment: [global.ObjectID(projects[0].id)],
                                                                                isCreatedAutomatically: true,
                                                                                group: new global.ObjectID(_.get(context, 'group.id'))
                                                                            }
                                                                        },
                                                                        upsert: true // Only insert if a document matching the filter does not exist
                                                                    }
                                                                })
                                                            })
                                                            const isPrincipaleProject = !newObject.relatedProject
                                                            if (isPrincipaleProject){
                                                                global.app.SE.AnalyticalMesh.collection.bulkWrite(bulkWrites,
                                                                    {},
                                                                    (e) =>{
                                                                        if (e) return callback(e)
                                                                        global.app.SE.AnalyticalMesh.find({
                                                                            ...basicContext(context),
                                                                            fieldPath: [],
                                                                            query: {
                                                                                attachment: {$elemMatch: {$eq: global.ObjectID(projects[0].id)}}
                                                                            }
                                                                        },(e, analyticalCodesForThisProject) => {
                                                                            if (e) return callback(e)
                                                                            if (!!analyticalCodesForThisProject.length){
                                                                                const startFiscalYear = newObject.startFiscalYear
                                                                                const endFiscalYear = newObject.endFiscalYear
                                                                                global.app.SE.FiscalYear.find({
                                                                                    ...basicContext(context),
                                                                                    fieldPath: [],
                                                                                    query: {
                                                                                        "fiscalYearRange.0": {$gte: startFiscalYear.fiscalYearRange[0]},
                                                                                        "fiscalYearRange.1": {$lte: endFiscalYear.fiscalYearRange[1]}
                                                                                    }
                                                                                }, (e, ongoingFiscalYears) =>{
                                                                                    if (e) return callback(e)
                                                                                    if (!!ongoingFiscalYears.length){
                                                                                        let budgetFollowUpToInsert = []
                                                                                        let budgetIdentifiersForInsertedBudgetFollowUp = []
                                                                                        let insertedBudgetFollowUpIDs = []
                                                                                        // todo you here
                                                                                        newObject.imputations.forEach(imputation =>{
                                                                                            ongoingFiscalYears.forEach(fiscalYear =>{
                                                                                                const newID = new global.ObjectID()
                                                                                                const desiredMonthAndAmounts = imputation.monthAndAmountList.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === fiscalYear.id.toString() )
                                                                                                const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)
                                                                                                const correspondentAnalyticalCode = analyticalCodesForThisProject.find( analyticalCode => analyticalCode.code === imputation.objet)
                                                                                                if (!imputation.organizationalMesh.id || !correspondentAnalyticalCode.id) return callback(new Errors.ValidationError('Project analytical codes are not yet created'))
                                                                                                budgetIdentifiersForInsertedBudgetFollowUp.push([imputation.organizationalMesh.id, correspondentAnalyticalCode.id])
                                                                                                insertedBudgetFollowUpIDs.push(newID)
                                                                                                budgetFollowUpToInsert.push({
                                                                                                    _id: newID,
                                                                                                    fiscalYear: new global.ObjectID(fiscalYear.id),
                                                                                                    type: new global.ObjectID(imputation.imputationType.id),
                                                                                                    amount: fiscalYearValidatedAmount,
                                                                                                    group: new global.ObjectID(_.get(context, 'group.id'))
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                        global.app.SE.BudgetFollowUp.collection.insertMany(budgetFollowUpToInsert, (e) => {
                                                                                            if (e) return callback(e)
                                                                                            //todo for a PC, don't modify EstimatedBudgetFollowUp, only modify budgetsfollowUP
                                                                                            // todo kmel hna insert budgets now
                                                                                            // for each budget prepare an array of budget follow up ids and estimated budget follow up ids
                                                                                            // budget = BU && analyticalCode
                                                                                            let budgetsToInsert = []
                                                                                            const uniqueBudgetsIdentifiersToInsert1 = _.uniqWith(budgetIdentifiersForInsertedBudgetFollowUp, _.isEqual)
                                                                                            const someVerifications = budgetIdentifiersForInsertedBudgetFollowUp.length === insertedBudgetFollowUpIDs.length
                                                                                            if (someVerifications) {
                                                                                                global.app.SE.Currency.find({
                                                                                                    ...basicContext(context),
                                                                                                    fieldPath: [],
                                                                                                    query: {
                                                                                                        nature: '1',
                                                                                                    }
                                                                                                }, (e, referenceCurrency) =>{
                                                                                                    if (e) return callback(e)
                                                                                                    if (!!referenceCurrency.length && referenceCurrency.length === 1){
                                                                                                        const budgetBulkWrites = [{ deleteOne: {filter: { _id: null }} }]
                                                                                                        uniqueBudgetsIdentifiersToInsert1.forEach( budgetIdentifiers => {
                                                                                                            const budgetFollowUpIDs = []
                                                                                                            budgetIdentifiersForInsertedBudgetFollowUp.forEach((budgetIDD, index) => {
                                                                                                                if ((budgetIDD[0].toString() === budgetIdentifiers[0].toString() && budgetIDD[1].toString() === budgetIdentifiers[1].toString())) {
                                                                                                                    budgetFollowUpIDs.push(insertedBudgetFollowUpIDs[index]);
                                                                                                                }
                                                                                                            })
                                                                                                            //todo if no ongoing fiscalYear abort and error
                                                                                                            //for estimated write a bulkWrite instead of insertMany
                                                                                                            budgetBulkWrites.push({
                                                                                                                updateOne: {
                                                                                                                    filter: {
                                                                                                                        mesh: global.ObjectID(budgetIdentifiers[0]),
                                                                                                                        analyticalMesh: global.ObjectID(budgetIdentifiers[1])
                                                                                                                    },
                                                                                                                    update: {
                                                                                                                        $setOnInsert: {
                                                                                                                            mesh: new global.ObjectID(budgetIdentifiers[0]),
                                                                                                                            analyticalMesh: new global.ObjectID(budgetIdentifiers[1]),
                                                                                                                            currency: new global.ObjectID(referenceCurrency[0].id),
                                                                                                                            budgetFollowUp: budgetFollowUpIDs,
                                                                                                                            estimatedBudgetFollowUp: [],
                                                                                                                            status: '1', //actif
                                                                                                                            group: new global.ObjectID(_.get(context, 'group.id'))
                                                                                                                        }
                                                                                                                    },
                                                                                                                    upsert: true // Only insert if a document matching the filter does not exist
                                                                                                                }
                                                                                                            })
                                                                                                        })
                                                                                                        global.app.SE.Budget.collection.bulkWrite(budgetBulkWrites,
                                                                                                            {},
                                                                                                            (e) =>{
                                                                                                                if (e) return callback(e)
                                                                                                            })
                                                                                                    }
                                                                                                    else{
                                                                                                        return callback(new Errors.ValidationError('There is no referenc currency'))
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                            else{
                                                                                                return callback(new Errors.ValidationError('Budget creation problem'))
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    else{
                                                                                        return callback(new Errors.ValidationError('There is no fiscal year with ongoing status'))
                                                                                    }
                                                                                })

                                                                            }
                                                                            else{
                                                                                return callback(new Errors.ValidationError('This porject analytical codes are not yet created'))
                                                                            }
                                                                        })
                                                                    }
                                                                )
                                                            }
                                                        }
                                                        else{
                                                            return callback(new Errors.ValidationError('This porject is not yet created in the analytical sub-axis'))
                                                        }
                                                    })
                                                }
                                            );
                                        }
                                        else{
                                            return callback(new Errors.ValidationError('You have to create a project analytical axis'))
                                        }
                                    }

                                    if (timeToAddEngagedAmountIntoRevisedTable){
                                        global.app.SE.Demand.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: ["revisedTable"],
                                                query: {
                                                    _id: global.ObjectID(newObject.relatedProject.id)
                                                }
                                            }, (e, projects) => {
                                                if (e) return callback(e)
                                                if (!!projects.length && projects.length === 1){
                                                    const demandImputations = newObject.imputations
                                                    const projectRevisedTable = projects[0].revisedTable
                                                    const bulkWritesForRevisedTable = []
                                                    demandImputations.forEach(imp =>{
                                                        const correspondentRevisedLine = projectRevisedTable.find(line => line.objet === imp.budget.analyticalMesh.code && line.entity.id.toString() === imp.budget.mesh.id.toString() && line.imputationType.id.toString() === imp.imputationType.id.toString())
                                                        const desiredMonthAndAmounts = imp.monthAndAmountList.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === currentFiscalYear.id.toString())
                                                        const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)
                                                        bulkWritesForRevisedTable.push({
                                                            updateOne: {
                                                                filter: { _id: global.ObjectID(correspondentRevisedLine.id) },
                                                                update: {
                                                                    $inc: {
                                                                        validatedEngagement: imp.totalAmountPerImputation,
                                                                        ongoingFiscalYearValidatedEngagement: fiscalYearValidatedAmount,
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    })
                                                    global.app.SE.RevisedTable.collection.bulkWrite(bulkWritesForRevisedTable, {}, (e)=>{
                                                        if (e) return callback(e)
                                                    })
                                                }
                                                else{
                                                    return callback(new Errors.ValidationError('the principale project cannot be updated'))
                                                }
                                            }
                                        )
                                    }

                                    if (nextStep !== 'approved' && nextStep !== 'closed' && !mustEndValidationProcess) {

                                        const nextConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                        let nextRolesIDs = nextConfigs.map(conf => global.ObjectID(conf.role.id))
                                        return global.app.SE.Habilitation.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: ["user.id"],
                                                query: {
                                                    "role": {$in: nextRolesIDs},
                                                    $or: [{
                                                        "grantedAccess": [],
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": [],
                                                        "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                    }],
                                                }
                                            }, (e, nextUsersList) => {
                                                if (!nextUsersList.length) {
                                                    return callback(new Errors.ValidationError('there is no person that can handle this demand on the next step'))
                                                } else {
                                                    const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'
                                                    let concernedNextUsers = _.cloneDeep(nextUsersList)
                                                    if (newObject.workflow[0] === "draft") {
                                                        concernedNextUsers = concernedNextUsers.filter(hab => hab.user.id !== context.user.id)
                                                    }
                                                    const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                    const mails = concernedNextUsers.map(hab => {
                                                        const userMail = hab.user.mail
                                                        const subject = `${choseSubjectStep(draftOrNextStep)} ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(nextStep)})`
                                                        const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                        const expectedAction = choseExpectedAction(nextStep, context.action)
                                                        const module = moduleName(nextStep)
                                                        return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                    })
                                                    global.mailer.sendMail(mails, (error) => {
                                                        if (error) return callback(error)
                                                        if (["draft", firstStep].includes(newObject.workflow[0]) && nextStep !== firstStep) {
                                                            global.app.SE.Habilitation.find(
                                                                {
                                                                    ...basicContext(context),
                                                                    fieldPath: ["user.id"],
                                                                    query: {
                                                                        "role": {$in: readersIDs},
                                                                        $or: [{
                                                                            "grantedAccess": [],
                                                                            "grantedMesh": []
                                                                        }, {
                                                                            "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                                            "grantedMesh": []
                                                                        }, {
                                                                            "grantedAccess": [],
                                                                            "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                                        }]
                                                                    }
                                                                }, (e, readers) => {
                                                                    if (!readers.length) {
                                                                        newObject.workflow = [nextStep, nextOrder]
                                                                        newObject.contributorsFunctions = nextRolesIDs
                                                                        newObject.status = getNextStepStatus(nextStep)
                                                                        return callback(error, newObject, oldObject)
                                                                    } else {
                                                                        const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                                        const mails = readers.map(hab => {
                                                                            const userMail = hab.user.mail
                                                                            const subject = `${choseSubjectStep(nextStep)} ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(nextStep)})`
                                                                            const module = moduleName(nextStep)
                                                                            return createReadersMail(context, userMail, subject, newObject, module)
                                                                        })
                                                                        global.mailer.sendMail(mails, (error) => {
                                                                            if (error) return callback(error)
                                                                            newObject.workflow = [nextStep, nextOrder]
                                                                            newObject.contributorsFunctions = nextRolesIDs
                                                                            newObject.status = getNextStepStatus(nextStep)
                                                                            callback(error, newObject, oldObject)
                                                                        })
                                                                    }
                                                                })
                                                        } else {
                                                            newObject.workflow = [nextStep, nextOrder]
                                                            newObject.contributorsFunctions = nextRolesIDs
                                                            newObject.status = getNextStepStatus(nextStep)
                                                            return callback(error, newObject, oldObject)
                                                        }
                                                    })
                                                }
                                            }
                                        )
                                    } else {
                                        const demandConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep)
                                        let demandRolesIDs = demandConfigs.map(conf => global.ObjectID(conf.role.id))
                                        demandRolesIDs = demandRolesIDs.concat(readersIDs)
                                        const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'

                                        if (mustEndValidationProcess){
                                            nextStep = 'approved'
                                            nextOrder = 1000000

                                            const projectOrDemandID = context?.module?.category?.path === 'Projet' ? newObject.relatedProject.id : newObject.relatedDemand.id
                                            return global.app.SE.Demand.find(
                                                {
                                                    ...basicContext(context),
                                                    fieldPath: ["imputations", "imputations.monthAndAmountList", "largeImputations", "revisedTable"],
                                                    query: {
                                                        _id: global.ObjectID(projectOrDemandID)
                                                    }
                                                }, (e, projects) => {
                                                    if (e) return callback(e)
                                                    // suppositions to verify with Sofiane : start month and end month should be the same as the principale project
                                                    // imputation are matched only by their objet, bu and impType
                                                    // it doesn't support currency yet
                                                    if (!!projects.length && projects.length === 1){
                                                        const principaleImputations = projects[0].imputations
                                                        const complementaryImputations = newObject.imputations
                                                        const principalInfos = projects[0].imputations.map(imputation => [imputation.objet, imputation.organizationalMesh.id.toString(), imputation.imputationType.id.toString()])
                                                        const complementaryInfos = newObject.imputations.map(imputation => [imputation.objet, imputation.organizationalMesh.id.toString(), imputation.imputationType.id.toString()])
                                                        let bulkWritesForMonthAndAmount = [{ deleteOne: {filter: { _id: null }} }]
                                                        let bulkWritesForImputations = [{ deleteOne: {filter: { _id: null }} }]
                                                        let newImputationsIDs = []
                                                        let addedAmountToPrincipaleProject = 0
                                                        let newMonthAndAmountIDs = []

                                                        const principaleLargeImputations = projects[0].largeImputations
                                                        const complementaryLargeImputations = newObject.largeImputations
                                                        const principaleLargeImpOrgs = !!projects[0].largeImputations && !!projects[0].largeImputations.length ? projects[0].largeImputations.map(imputation => imputation.organization.id.toString()) : []
                                                        const complementaryLargeImpOrgs = !!newObject.largeImputations && !!newObject.largeImputations.length ? newObject.largeImputations.map(imputation => imputation.organization.id.toString()) : []
                                                        let newLargeImputationsIDs = []
                                                        let addedLargeAmountToPrincipaleProject = 0
                                                        let bulkWritesForLargeImputations = [{ deleteOne: {filter: { _id: null }} }]

                                                        const principaleRevisedTable = projects[0].revisedTable
                                                        const principaleRevisedTableInfos = !!projects[0].revisedTable && !!projects[0].revisedTable.length ?
                                                            projects[0].revisedTable.map(imputation => [imputation.objet, imputation.entity.id.toString(), imputation.imputationType.id.toString()])
                                                            : []
                                                        const objetAndBUPrincipalInfos = principaleRevisedTableInfos.map( array => [array[0], array[1]])
                                                        let bulkWritesForRevisedTable = [{ deleteOne: {filter: { _id: null }} }]
                                                        let newRevisedTableIDs = []

                                                        const imputationsThatAlreadyHaveBudget = []
                                                        complementaryInfos.forEach( conditionsArray => {
                                                            //looking for imputations that they should already have budgets in the principale project
                                                            if (isArrayInArrayOfArrays( [conditionsArray[0], conditionsArray[1]], objetAndBUPrincipalInfos) ){
                                                                const correspondantComplementaryImputation = complementaryImputations.find( imputation => imputation.objet === conditionsArray[0] && imputation.organizationalMesh.id.toString() === conditionsArray[1] && imputation.imputationType.id.toString() === conditionsArray[2])
                                                                imputationsThatAlreadyHaveBudget.push(correspondantComplementaryImputation)
                                                            }
                                                            if ( isArrayInArrayOfArrays(conditionsArray, principalInfos) ){
                                                                const correspondantPrincipaleImputation = principaleImputations.find( imputation => imputation.objet === conditionsArray[0] && imputation.organizationalMesh.id.toString() === conditionsArray[1] && imputation.imputationType.id.toString() === conditionsArray[2])
                                                                const correspondantComplementaryImputation = complementaryImputations.find( imputation => imputation.objet === conditionsArray[0] && imputation.organizationalMesh.id.toString() === conditionsArray[1] && imputation.imputationType.id.toString() === conditionsArray[2])
                                                                const complementaryMonthAndAmountList = correspondantComplementaryImputation.monthAndAmountList
                                                                complementaryMonthAndAmountList.forEach( row => {
                                                                    const lineToModify = correspondantPrincipaleImputation.monthAndAmountList.find(line => line.fiscalYear.id.toString() === row.fiscalYear.id.toString() && line.month.substring(0, 7) === row.month.substring(0, 7))
                                                                    if (!!lineToModify){
                                                                        bulkWritesForMonthAndAmount.push({
                                                                            updateOne: {
                                                                                filter: { _id: global.ObjectID(lineToModify.id) },
                                                                                update: { $inc: { amount: row.amount } }
                                                                            }
                                                                        })
                                                                    }
                                                                    else{
                                                                        newMonthAndAmountIDs.push(global.ObjectID(row.id))
                                                                    }
                                                                })
                                                                let addedAmountToImputation = complementaryMonthAndAmountList.reduce((acc, row) => acc + row.amount, 0 )
                                                                bulkWritesForImputations.push({
                                                                    updateOne: {
                                                                        filter: { _id: global.ObjectID(correspondantPrincipaleImputation.id) },
                                                                        update: [
                                                                            {
                                                                                $set: {
                                                                                    description: {
                                                                                        $concat: ["$description", "\n- PC : ", !!correspondantComplementaryImputation.description.length ? correspondantComplementaryImputation.description : "aucune description"]
                                                                                    },
                                                                                    totalAmountPerImputation: { $add: ["$totalAmountPerImputation", addedAmountToImputation] }
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                })
                                                                bulkWritesForImputations.push({
                                                                    updateOne: {
                                                                        filter: { _id: global.ObjectID(correspondantPrincipaleImputation.id) },
                                                                        update: {
                                                                            $push: {
                                                                                monthAndAmountList: {$each: newMonthAndAmountIDs}
                                                                            }
                                                                        }
                                                                    }
                                                                })
                                                                addedAmountToPrincipaleProject += addedAmountToImputation

                                                                // in this case, the budget was already created with the principale project, so i'm gonna save those imputations to modify the already existing budgets
                                                            }
                                                            else{
                                                                const correspondantComplementaryImputation = complementaryImputations.find( imputation => imputation.objet === conditionsArray[0] && imputation.organizationalMesh.id.toString() === conditionsArray[1] && imputation.imputationType.id.toString() === conditionsArray[2])
                                                                newImputationsIDs.push(global.ObjectID(correspondantComplementaryImputation.id))
                                                                let addedAmountToImputation = correspondantComplementaryImputation.monthAndAmountList.reduce((acc, row) => acc + row.amount, 0 )
                                                                addedAmountToPrincipaleProject += addedAmountToImputation
                                                            }
                                                            const shouldUpdateRevisedTable = !!principaleRevisedTableInfos.length
                                                            if (shouldUpdateRevisedTable){
                                                                if ( isArrayInArrayOfArrays(conditionsArray, principaleRevisedTableInfos) ){
                                                                    const correspondantLine = principaleRevisedTable.find( line => line.objet === conditionsArray[0] && line.entity.id.toString() === conditionsArray[1] && line.imputationType.id.toString() === conditionsArray[2])
                                                                    const correspondantComplementaryImputation = complementaryImputations.find( imputation => imputation.objet === conditionsArray[0] && imputation.organizationalMesh.id.toString() === conditionsArray[1] && imputation.imputationType.id.toString() === conditionsArray[2])
                                                                    let addedAmountToImputation = correspondantComplementaryImputation.totalAmountPerImputation
                                                                    const desiredMonthAndAmounts = correspondantComplementaryImputation.monthAndAmountList.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === currentFiscalYear.id.toString())
                                                                    const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)
                                                                    bulkWritesForRevisedTable.push({
                                                                        updateOne: {
                                                                            filter: { _id: global.ObjectID(correspondantLine.id) },
                                                                            update: { $inc: {
                                                                                    validated: addedAmountToImputation,
                                                                                    budget: addedAmountToImputation,
                                                                                    ongoingFiscalYearValidated: fiscalYearValidatedAmount,
                                                                                    ongoingFiscalYearBudget: fiscalYearValidatedAmount
                                                                                    }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                else{
                                                                    const correspondantComplementaryImputation = complementaryImputations.find( imputation => imputation.objet === conditionsArray[0] && imputation.organizationalMesh.id.toString() === conditionsArray[1] && imputation.imputationType.id.toString() === conditionsArray[2])
                                                                    const desiredMonthAndAmounts = correspondantComplementaryImputation.monthAndAmountList.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === currentFiscalYear.id.toString())
                                                                    const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)
                                                                    //let dynamicAmountsObject = {}
                                                                    let list = []
                                                                    availableRealizationTypes.forEach(type =>{
                                                                        list.push({
                                                                            _id: new global.ObjectID(),
                                                                            realizationType: global.ObjectID(type.id), //zid
                                                                            budgetColumnType: '1',  //global
                                                                            amount: 0
                                                                        })
                                                                        list.push({
                                                                            _id: new global.ObjectID(),
                                                                            realizationType: global.ObjectID(type.id),
                                                                            budgetColumnType: '2', //yearly
                                                                            amount: 0
                                                                        })
                                                                    })
                                                                    availableImputationTypes.forEach(imputationType =>{
                                                                        const newID = new global.ObjectID()
                                                                        newRevisedTableIDs.push(newID)
                                                                        const isFilledByUser = correspondantComplementaryImputation.imputationType.id.toString() === imputationType.id.toString()

                                                                        const validated = !!isFilledByUser ? correspondantComplementaryImputation.totalAmountPerImputation : 0
                                                                        const budget = !!isFilledByUser ? correspondantComplementaryImputation.totalAmountPerImputation : 0
                                                                        const ongoingFiscalYearValidated = !!isFilledByUser ? fiscalYearValidatedAmount : 0
                                                                        const ongoingFiscalYearBudget = !!isFilledByUser ? fiscalYearValidatedAmount : 0
                                                                        bulkWritesForRevisedTable.push(
                                                                            {
                                                                                insertOne: {
                                                                                    document: {
                                                                                        _id: newID,
                                                                                        objet: correspondantComplementaryImputation.objet,
                                                                                        entity: global.ObjectID(correspondantComplementaryImputation.organizationalMesh.id),
                                                                                        imputationType: global.ObjectID(imputationType.id),
                                                                                        //imputationTriplet: correspondantComplementaryImputation.objet + '-' + correspondantComplementaryImputation.organizationalMesh.name + '-' + imputationType.name,
                                                                                        estimated: 0,
                                                                                        validated,
                                                                                        budget,
                                                                                        revised: 0,
                                                                                        validatedEngagement: 0,
                                                                                        ongoingFiscalYearValidatedEngagement: 0,
                                                                                        ongoingFiscalYearEstimated: 0,
                                                                                        ongoingFiscalYearValidated,
                                                                                        ongoingFiscalYearBudget,
                                                                                        ongoingFiscalYearRevised: 0,
                                                                                        amountByRealizationType: list,
                                                                                        updated: null,
                                                                                        user: null,
                                                                                        //demand: global.ObjectID(newObject.relatedProject.id),
                                                                                        group: new global.ObjectID(_.get(context, 'group.id'))
                                                                                    }
                                                                                }
                                                                            }
                                                                        )
                                                                    })
                                                                }
                                                            }
                                                        })
                                                        complementaryLargeImpOrgs.forEach( complementaryOrg => {
                                                            if ( principaleLargeImpOrgs.includes(complementaryOrg) ){
                                                                const correspondantPrincipaleLargeImp = principaleLargeImputations.find( largeImp => largeImp.organization.id.toString() === complementaryOrg)
                                                                const correspondantComplementaryLargeImp = complementaryLargeImputations.find( largeImp => largeImp.organization.id.toString() === complementaryOrg)
                                                                let addedAmountToLargeImputation = correspondantComplementaryLargeImp.amount
                                                                bulkWritesForLargeImputations.push({
                                                                    updateOne: {
                                                                        filter: { _id: global.ObjectID(correspondantPrincipaleLargeImp.id) },
                                                                        update: { $inc: { amount: addedAmountToLargeImputation } }
                                                                    }
                                                                })
                                                                addedLargeAmountToPrincipaleProject += addedAmountToLargeImputation
                                                            }
                                                            else{
                                                                const correspondantComplementaryLargeImputation = complementaryLargeImputations.find( largeImp => largeImp.organization.id.toString() === complementaryOrg)
                                                                newLargeImputationsIDs.push(global.ObjectID(correspondantComplementaryLargeImputation.id))
                                                                let addedAmountToLargeImputation = correspondantComplementaryLargeImputation.amount
                                                                addedLargeAmountToPrincipaleProject += addedAmountToLargeImputation
                                                            }
                                                        })

                                                        global.app.SE.LargeImputation.collection.bulkWrite(bulkWritesForLargeImputations, {}, (e)=>{
                                                            if (e) return callback(e)
                                                            global.app.SE.MonthAndAmountList.collection.bulkWrite(bulkWritesForMonthAndAmount, {}, (e)=>{
                                                                if (e) return callback(e)
                                                                global.app.SE.Imputation.collection.bulkWrite(bulkWritesForImputations, {}, (e)=>{
                                                                    if (e) return callback(e)
                                                                    global.app.SE.RevisedTable.collection.bulkWrite(bulkWritesForRevisedTable, {}, (e)=>{
                                                                        if (e) return callback(e)
                                                                            const principaleProjectOrDemandEndMonth = moment(projects[0].endMonth, 'YYYY-MM')
                                                                            const compelemntaryProjectOrDemandEndMonth = moment(newObject.endMonth, 'YYYY-MM')
                                                                            const shouldUpdateMonth = compelemntaryProjectOrDemandEndMonth.isAfter(principaleProjectOrDemandEndMonth)
                                                                            const monthQuery = !!shouldUpdateMonth ? {
                                                                                    $set: {
                                                                                        endMonth: newObject.endMonth,
                                                                                        endFiscalYear: global.ObjectID(demandEndFiscalYear.id)
                                                                                    }
                                                                                }
                                                                                : {}
                                                                            global.app.SE.Demand.collection.updateOne(
                                                                                {
                                                                                    _id: global.ObjectID(projectOrDemandID)
                                                                                },
                                                                                {
                                                                                    $push: {
                                                                                        imputations: {$each: newImputationsIDs },
                                                                                        largeImputations: {$each: newLargeImputationsIDs },
                                                                                        revisedTable: {$each: newRevisedTableIDs},
                                                                                    },
                                                                                    $inc: {
                                                                                        totalAmount: addedAmountToPrincipaleProject,
                                                                                        largeImputationsTotalAmount: addedLargeAmountToPrincipaleProject
                                                                                    },
                                                                                    ...monthQuery
                                                                                },
                                                                                e => {
                                                                                    if(e) {
                                                                                        console.log(`erreur d'update du projet principale: ${newObject.relatedProject.id}`, e)
                                                                                        return callback(e)
                                                                                    }
                                                                                    // proccess to create budgets
                                                                                    if (isItComplementaryProject){
                                                                                        global.app.SE.AnalyticalSubAxis.find({
                                                                                            ...basicContext(context),
                                                                                            fieldPath: [],
                                                                                            query: {
                                                                                                code: newObject.relatedProject.demandNumber
                                                                                            }
                                                                                        },(e, analyticalSubAxesForThisProject) =>{
                                                                                            if (e) return callback(e)
                                                                                            if (!!analyticalSubAxesForThisProject.length && analyticalSubAxesForThisProject.length === 1){
                                                                                                const imputationsObjets = newObject.imputations.map( imp => imp.objet )
                                                                                                const uniqueObjets = [...new Set(imputationsObjets)];
                                                                                                let bulkWrites = [{ deleteOne: {filter: { _id: null }} }]
                                                                                                uniqueObjets.forEach( objet => {
                                                                                                    bulkWrites.push({
                                                                                                        updateOne: {
                                                                                                            filter: {
                                                                                                                code: objet,
                                                                                                                attachment: {$elemMatch: {$eq: global.ObjectID(analyticalSubAxesForThisProject[0].id)} }
                                                                                                            },
                                                                                                            update: {
                                                                                                                $setOnInsert: {
                                                                                                                    code: objet,
                                                                                                                    name: objet,
                                                                                                                    attachment: [global.ObjectID(analyticalSubAxesForThisProject[0].id)],
                                                                                                                    isCreatedAutomatically: true,
                                                                                                                    group: new global.ObjectID(_.get(context, 'group.id'))
                                                                                                                }
                                                                                                            },
                                                                                                            upsert: true // Only insert if a document matching the filter does not exist
                                                                                                        }
                                                                                                    })
                                                                                                })
                                                                                                global.app.SE.AnalyticalMesh.collection.bulkWrite(bulkWrites,
                                                                                                    {},
                                                                                                    (e) =>{
                                                                                                        if (e) return callback(e)
                                                                                                        global.app.SE.AnalyticalMesh.find({
                                                                                                            ...basicContext(context),
                                                                                                            fieldPath: [],
                                                                                                            query: {
                                                                                                                attachment: {$elemMatch: {$eq: global.ObjectID(analyticalSubAxesForThisProject[0].id)}}
                                                                                                            }
                                                                                                        },(e, analyticalCodesForThisProject) => {
                                                                                                            if (e) return callback(e)
                                                                                                            if (!!analyticalCodesForThisProject.length){
                                                                                                                const startFiscalYear = newObject.startFiscalYear
                                                                                                                const endFiscalYear = newObject.endFiscalYear
                                                                                                                global.app.SE.FiscalYear.find({
                                                                                                                    ...basicContext(context),
                                                                                                                    fieldPath: [],
                                                                                                                    query: {
                                                                                                                        "fiscalYearRange.0": {$gte: startFiscalYear.fiscalYearRange[0]},
                                                                                                                        "fiscalYearRange.1": {$lte: endFiscalYear.fiscalYearRange[1]}
                                                                                                                    }
                                                                                                                }, (e, ongoingFiscalYears) =>{
                                                                                                                    if (e) return callback(e)
                                                                                                                    if (!!ongoingFiscalYears.length){
                                                                                                                        const imputationsThatAlreadyHaveBudgetIDs = imputationsThatAlreadyHaveBudget.map( imp => imp.id.toString() )
                                                                                                                        const imputationsThatDontHaveBudgetYet = newObject.imputations.filter( imputation => !imputationsThatAlreadyHaveBudgetIDs.includes(imputation.id.toString()))
                                                                                                                        const budgetFollowUpBulkWrites = [{ deleteOne: {filter: { _id: null }} }]
                                                                                                                        let budgetIdentifiersForInsertedBudgetFollowUp = []
                                                                                                                        let insertedBudgetFollowUpIDs = []
                                                                                                                        // todo you here
                                                                                                                        imputationsThatDontHaveBudgetYet.forEach( imputation =>{
                                                                                                                            ongoingFiscalYears.forEach( fiscalYear =>{
                                                                                                                                const newID = new global.ObjectID()
                                                                                                                                const desiredMonthAndAmounts = imputation.monthAndAmountList.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === fiscalYear.id.toString())
                                                                                                                                const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)
                                                                                                                                const correspondentAnalyticalCode = analyticalCodesForThisProject.find( analyticalCode => analyticalCode.code === imputation.objet)
                                                                                                                                if (!imputation.organizationalMesh.id || !correspondentAnalyticalCode.id) return callback(new Errors.ValidationError('Project analytical codes are not yet created'))
                                                                                                                                budgetIdentifiersForInsertedBudgetFollowUp.push([imputation.organizationalMesh.id, correspondentAnalyticalCode.id])
                                                                                                                                insertedBudgetFollowUpIDs.push(newID)
                                                                                                                                budgetFollowUpBulkWrites.push({
                                                                                                                                    insertOne: {
                                                                                                                                        "document": {
                                                                                                                                            _id: newID,
                                                                                                                                            fiscalYear: new global.ObjectID(fiscalYear.id),
                                                                                                                                            type: new global.ObjectID(imputation.imputationType.id),
                                                                                                                                            amount: fiscalYearValidatedAmount,
                                                                                                                                            group: new global.ObjectID(_.get(context, 'group.id'))
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                })
                                                                                                                            })
                                                                                                                        })
                                                                                                                        global.app.SE.BudgetFollowUp.collection.bulkWrite(budgetFollowUpBulkWrites, {}, (e) => {
                                                                                                                            if (e) return callback(e)
                                                                                                                            //todo for a PC, don't modify EstimatedBudgetFollowUp, only modify budgetsfollowUP
                                                                                                                            // todo kmel hna insert budgets now
                                                                                                                            // for each budget prepare an array of budget follow up ids and estimated budget follow up ids
                                                                                                                            // budget = BU && analyticalCode
                                                                                                                            let budgetsToInsert = []
                                                                                                                            const uniqueBudgetsIdentifiersToInsert1 = _.uniqWith(budgetIdentifiersForInsertedBudgetFollowUp, _.isEqual)
                                                                                                                            const someVerifications = budgetIdentifiersForInsertedBudgetFollowUp.length === insertedBudgetFollowUpIDs.length
                                                                                                                            if (someVerifications) {
                                                                                                                                global.app.SE.Currency.find({
                                                                                                                                    ...basicContext(context),
                                                                                                                                    fieldPath: [],
                                                                                                                                    query: {
                                                                                                                                        nature: '1',
                                                                                                                                    }
                                                                                                                                }, (e, referenceCurrency) =>{
                                                                                                                                    if (e) return callback(e)
                                                                                                                                    if (!!referenceCurrency.length && referenceCurrency.length === 1){
                                                                                                                                        const budgetBulkWrites = [{ deleteOne: {filter: { _id: null }} }]
                                                                                                                                        uniqueBudgetsIdentifiersToInsert1.forEach( budgetIdentifiers => {
                                                                                                                                            const budgetFollowUpIDs = []
                                                                                                                                            budgetIdentifiersForInsertedBudgetFollowUp.forEach((budgetIDD, index) => {
                                                                                                                                                if ((budgetIDD[0].toString() === budgetIdentifiers[0].toString() && budgetIDD[1].toString() === budgetIdentifiers[1].toString())) {
                                                                                                                                                    budgetFollowUpIDs.push(insertedBudgetFollowUpIDs[index]);
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                            //todo if no ongoing fiscalYear abort and error
                                                                                                                                            //for estimated write a bulkWrite instead of insertMany
                                                                                                                                            budgetBulkWrites.push({
                                                                                                                                                updateOne: {
                                                                                                                                                    filter: {
                                                                                                                                                        mesh: global.ObjectID(budgetIdentifiers[0]),
                                                                                                                                                        analyticalMesh: global.ObjectID(budgetIdentifiers[1])
                                                                                                                                                    },
                                                                                                                                                    update: {
                                                                                                                                                        $setOnInsert: {
                                                                                                                                                            mesh: new global.ObjectID(budgetIdentifiers[0]),
                                                                                                                                                            analyticalMesh: new global.ObjectID(budgetIdentifiers[1]),
                                                                                                                                                            currency: new global.ObjectID(referenceCurrency[0].id),
                                                                                                                                                            budgetFollowUp: budgetFollowUpIDs,
                                                                                                                                                            estimatedBudgetFollowUp: [],
                                                                                                                                                            status: '1', //actif
                                                                                                                                                            group: new global.ObjectID(_.get(context, 'group.id'))
                                                                                                                                                        }
                                                                                                                                                    },
                                                                                                                                                    upsert: true // Only insert if a document matching the filter does not exist
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        })
                                                                                                                                        global.app.SE.Budget.collection.bulkWrite(budgetBulkWrites,
                                                                                                                                            {},
                                                                                                                                            (e) => {
                                                                                                                                                if (e) return callback(e)
                                                                                                                                                // todo HERE we gonna modify already existing budgets
                                                                                                                                                const queries = [{_id: null}]
                                                                                                                                                imputationsThatAlreadyHaveBudget.forEach( imputation => {
                                                                                                                                                    const correspondentAnalyticalCode = analyticalCodesForThisProject.find( analyticalCode => analyticalCode.code === imputation.objet)
                                                                                                                                                    queries.push({
                                                                                                                                                        mesh: global.ObjectID(imputation.organizationalMesh.id),
                                                                                                                                                        analyticalMesh: global.ObjectID(correspondentAnalyticalCode.id)
                                                                                                                                                    })
                                                                                                                                                })
                                                                                                                                                global.app.SE.Budget.find({
                                                                                                                                                    ...basicContext(context),
                                                                                                                                                    fieldPath: ['analyticalMesh', 'mesh', 'budgetFollowUp'],
                                                                                                                                                    query: {
                                                                                                                                                        $or: queries
                                                                                                                                                    }
                                                                                                                                                }, (e, alreadyCreatedBudgets) =>{
                                                                                                                                                    if (e) return callback(e)
                                                                                                                                                    if (!!alreadyCreatedBudgets.length){ //if budgets was already created when creating the principale project, we want to modify them
                                                                                                                                                        const bulkWritesForBudgetFollowUp = []
                                                                                                                                                        const budgetsBulkWrites = [{ deleteOne: {filter: { _id: null }} }]
                                                                                                                                                        alreadyCreatedBudgets.forEach( budget =>{
                                                                                                                                                            const budgetFollowUpToAdd = []
                                                                                                                                                            const budgetFollowUpToModify = budget.budgetFollowUp
                                                                                                                                                            const budgetAnalyticalCode = budget.analyticalMesh
                                                                                                                                                            const budgetMesh = budget.mesh

                                                                                                                                                            const declaredFiscalYears = budget.budgetFollowUp.map( BF => BF.fiscalYear.id.toString())
                                                                                                                                                            const nonDeclaredFiscalYears = ongoingFiscalYears.filter( fiscalYear => !declaredFiscalYears.includes(fiscalYear.id.toString()))
                                                                                                                                                            const declaredImputationTypes = budget.budgetFollowUp.map( BF => BF.type.id.toString())
                                                                                                                                                            const nonDeclaredImputationTypesIDs = imputationsThatAlreadyHaveBudget.map(imp => imp.imputationType.id.toString()).filter( id => !declaredImputationTypes.includes(id))
                                                                                                                                                            budgetFollowUpToModify.forEach(budgetFollowUp => {
                                                                                                                                                                const correspondentImputation = imputationsThatAlreadyHaveBudget.find(imputation => imputation.objet === budgetAnalyticalCode.code && imputation.organizationalMesh.id.toString() === budgetMesh.id.toString() &&  imputation.imputationType.id.toString() === budgetFollowUp.type.id.toString())
                                                                                                                                                                if (!!correspondentImputation){
                                                                                                                                                                    const desiredMonthAndAmounts = correspondentImputation.monthAndAmountList.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === budgetFollowUp.fiscalYear.id.toString())
                                                                                                                                                                    const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)
                                                                                                                                                                    bulkWritesForBudgetFollowUp.push({
                                                                                                                                                                        updateOne: {
                                                                                                                                                                            filter: {
                                                                                                                                                                                _id: global.ObjectID(budgetFollowUp.id),
                                                                                                                                                                            },
                                                                                                                                                                            update: {
                                                                                                                                                                                $inc: { amount: fiscalYearValidatedAmount } // Update operations to be applied
                                                                                                                                                                            },
                                                                                                                                                                        }
                                                                                                                                                                    })
                                                                                                                                                                }
                                                                                                                                                            })
                                                                                                                                                            imputationsThatAlreadyHaveBudget.forEach( imputation =>{
                                                                                                                                                                ongoingFiscalYears.forEach( fiscalYear =>{
                                                                                                                                                                    const nonDeclaredFiscalYearsIDs = nonDeclaredFiscalYears.map(FY => FY.id.toString())
                                                                                                                                                                    if (nonDeclaredFiscalYearsIDs.includes(fiscalYear.id.toString()) || nonDeclaredImputationTypesIDs.includes(imputation.imputationType.id.toString())){
                                                                                                                                                                        const newID = new global.ObjectID()
                                                                                                                                                                        const desiredMonthAndAmounts = imputation.monthAndAmountList.filter(monthAndAmount => monthAndAmount.fiscalYear.id.toString() === fiscalYear.id.toString() )
                                                                                                                                                                        const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)
                                                                                                                                                                        budgetFollowUpToAdd.push(newID)
                                                                                                                                                                        bulkWritesForBudgetFollowUp.push({
                                                                                                                                                                            insertOne: {
                                                                                                                                                                                "document": {
                                                                                                                                                                                    _id: newID,
                                                                                                                                                                                    fiscalYear: new global.ObjectID(fiscalYear.id),
                                                                                                                                                                                    type: new global.ObjectID(imputation.imputationType.id),
                                                                                                                                                                                    amount: fiscalYearValidatedAmount,
                                                                                                                                                                                    group: new global.ObjectID(_.get(context, 'group.id'))
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                })
                                                                                                                                                            })

                                                                                                                                                            budgetsBulkWrites.push({
                                                                                                                                                                updateOne: {
                                                                                                                                                                    filter: { _id: global.ObjectID(budget.id) },
                                                                                                                                                                    update: {
                                                                                                                                                                        $push: { budgetFollowUp: { $each: budgetFollowUpToAdd } }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            })
                                                                                                                                                        })

                                                                                                                                                        //
                                                                                                                                                        global.app.SE.BudgetFollowUp.collection.bulkWrite(bulkWritesForBudgetFollowUp,
                                                                                                                                                            {},
                                                                                                                                                            (e) =>{
                                                                                                                                                                if (e) return callback(e)
                                                                                                                                                                global.app.SE.Budget.collection.bulkWrite(budgetsBulkWrites,
                                                                                                                                                                    {},
                                                                                                                                                                    (e) =>{
                                                                                                                                                                        if (e) return callback(e)
                                                                                                                                                                    })
                                                                                                                                                            }
                                                                                                                                                        )
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            })
                                                                                                                                    }
                                                                                                                                    else{
                                                                                                                                        return callback(new Errors.ValidationError('There is no reference currency'))
                                                                                                                                    }
                                                                                                                                })
                                                                                                                            }
                                                                                                                            else{
                                                                                                                                return callback(new Errors.ValidationError('Budget creation problem'))
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                    else{
                                                                                                                        return callback(new Errors.ValidationError('There is no fiscal year with ongoing status'))
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                            else{
                                                                                                                return callback(new Errors.ValidationError('This porject analytical codes are not yet created'))
                                                                                                            }
                                                                                                        })
                                                                                                    })
                                                                                            }
                                                                                            else{
                                                                                                return callback(new Errors.ValidationError('Analytical sub axis problem'))
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    return global.app.SE.Habilitation.find(
                                                                                        {
                                                                                            ...basicContext(context),
                                                                                            fieldPath: ["user.id"],
                                                                                            query: {
                                                                                                "role": {$in: demandRolesIDs},
                                                                                                $or: [{
                                                                                                    "grantedAccess": [],
                                                                                                    "grantedMesh": []
                                                                                                }, {
                                                                                                    "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                                                                    "grantedMesh": []
                                                                                                }, {
                                                                                                    "grantedAccess": [],
                                                                                                    "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                                                                }]
                                                                                            }
                                                                                        }, (e, nextUsersList) => {
                                                                                            if (e) return callback(e)
                                                                                            if (!!nextUsersList.length) {
                                                                                                const mails = nextUsersList.map(hab => {
                                                                                                    const userMail = hab.user.mail
                                                                                                    return createFinishedMail(context, userMail, newObject, nextStep)
                                                                                                })
                                                                                                global.mailer.sendMail(mails, (error) => {
                                                                                                    if (error) return callback(error)
                                                                                                    if (nextStep === 'approved') {
                                                                                                        newObject.workflow = ['approved', 1000000]
                                                                                                    } else if (nextStep === 'closed') {
                                                                                                        newObject.workflow = ['closed', 1000000]
                                                                                                    }
                                                                                                    newObject.contributorsFunctions = []
                                                                                                    newObject.status = getNextStepStatus(nextStep)
                                                                                                    callback(null, newObject, oldObject)
                                                                                                })
                                                                                            } else {
                                                                                                if (nextStep === 'approved') {
                                                                                                    newObject.workflow = ['approved', 1000000]
                                                                                                } else if (nextStep === 'closed') {
                                                                                                    newObject.workflow = ['closed', 1000000]
                                                                                                }
                                                                                                newObject.contributorsFunctions = []
                                                                                                newObject.status = getNextStepStatus(nextStep)
                                                                                                callback(null, newObject, oldObject)
                                                                                            }
                                                                                        }
                                                                                    )
                                                                                }
                                                                            )

                                                                    })
                                                                })
                                                            })
                                                        })
                                                    }
                                                    else{
                                                        return callback(new Errors.ValidationError('the principale project cannot be updated'))
                                                    }
                                                })
                                        }
                                        return global.app.SE.Habilitation.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: ["user.id"],
                                                query: {
                                                    "role": {$in: demandRolesIDs},
                                                    $or: [{
                                                        "grantedAccess": [],
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": [],
                                                        "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                    }]
                                                }
                                            }, (e, nextUsersList) => {
                                                if (e) return callback(e)
                                                if (!!nextUsersList.length) {
                                                    const mails = nextUsersList.map(hab => {
                                                        const userMail = hab.user.mail
                                                        return createFinishedMail(context, userMail, newObject, nextStep)
                                                    })
                                                    global.mailer.sendMail(mails, (error) => {
                                                        if (error) return callback(error)
                                                        if (nextStep === 'approved') {
                                                            newObject.workflow = ['approved', 1000000]
                                                        } else if (nextStep === 'closed') {
                                                            newObject.workflow = ['closed', 1000000]
                                                        }
                                                        newObject.contributorsFunctions = []
                                                        newObject.status = getNextStepStatus(nextStep)
                                                        callback(null, newObject, oldObject)
                                                    })
                                                } else {
                                                    if (nextStep === 'approved') {
                                                        newObject.workflow = ['approved', 1000000]
                                                    } else if (nextStep === 'closed') {
                                                        newObject.workflow = ['closed', 1000000]
                                                    }
                                                    newObject.contributorsFunctions = []
                                                    newObject.status = getNextStepStatus(nextStep)
                                                    callback(null, newObject, oldObject)
                                                }
                                            }
                                        )
                                    }
                                } else if (context.action === "transmit") {
                                    return callback(new Errors.ValidationError('you cannot do this action from this module'))
                                } else if (context.action === 'revise') {
                                    let [nextStep, nextOrder] = getPreviousStepPreviousOrder(newObject.workflow[0], parseInt(newObject.workflow[1]), _workflowConf.workFlowConfigs)
                                    //todo check in creation
                                    const nextConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                    const nextRolesIDs = nextConfigs.map(conf => global.ObjectID(conf.role.id))
                                    const currentStep = newObject.workflow[0]

                                    while (newObject.alreadyTreatedByFunctions[nextStep] && newObject.alreadyTreatedByFunctions[nextStep].length >= nextOrder) {
                                        newObject.alreadyTreatedByFunctions[nextStep].pop()
                                    }
                                    newObject.arbitratorsFunctions = []
                                    newObject.arbitrateText = ''
                                    if (!!newObject.delegateTo.length) {
                                        newObject.delegateTo.forEach(deleg => {
                                            newObject.delegationHistory.push(deleg)
                                        })
                                    }
                                    newObject.delegateTo = []
                                    const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'
                                    return global.app.SE.Habilitation.find(
                                        {
                                            ...basicContext(context),
                                            fieldPath: ["user.id"],
                                            query: {
                                                "role": {$in: nextRolesIDs.map(role => global.ObjectID(role.id))},
                                                $or: [{
                                                    "grantedAccess": [],
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}},
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": [],
                                                    "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs.map(org => global.ObjectID(org.id))}}
                                                }]
                                            }
                                        }, (e, nextUsersList) => {
                                            if (!nextUsersList.length) {
                                                return callback(new Errors.ValidationError('there is no person that can handle this demand on the next step'))
                                            } else {
                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                const mails = nextUsersList.map(hab => {
                                                    const userMail = hab.user.mail
                                                    const subject = `Révision ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : Demande d'information)`
                                                    const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                    const expectedAction = choseExpectedAction(nextStep, context.action)
                                                    const module = moduleName(nextStep)
                                                    return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                })
                                                global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    newObject.status = {id: '4'}
                                                    newObject.workflow = [nextStep, nextOrder]
                                                    newObject.contributorsFunctions = nextRolesIDs
                                                    callback(error, newObject, oldObject)
                                                })
                                            }
                                        }
                                    )
                                } else if (context.action === 'refuse') {
                                    const currentStep = newObject.workflow[0]
                                    if (!!newObject.contributorsFunctions.length) {
                                        if (newObject.alreadyTreatedByFunctions[currentStep]) {
                                            newObject.alreadyTreatedByFunctions[currentStep].push(newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString()))
                                        } else {
                                            newObject.alreadyTreatedByFunctions[currentStep] = [newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString())]
                                        }
                                    }

                                    const demandConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep)
                                    let demandRolesIDs = demandConfigs.map(conf => global.ObjectID(conf.role.id))
                                    demandRolesIDs = demandRolesIDs.concat(readersIDs)
                                    return global.app.SE.Habilitation.find(
                                        {
                                            ...basicContext(context),
                                            fieldPath: ["user.id"],
                                            query: {
                                                "role": {$in: demandRolesIDs},
                                                $or: [{
                                                    "grantedAccess": [],
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": [],
                                                    "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                }]
                                            }
                                        }, (e, nextUsersList) => {
                                            if (e) return callback(e)
                                            if (!!nextUsersList.length) {
                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'Demande refusée' : 'Projet refusé'
                                                const mails = nextUsersList.map(hab => {
                                                    const userMail = hab.user.mail
                                                    const subject = `${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : Refusé)`
                                                    const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                    return createAbondonMail(context, userMail, subject, newObject, stepAction)
                                                })
                                                global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    newObject.status = {id: '5'}
                                                    newObject.workflow = ['refused', 1000000]
                                                    newObject.contributorsFunctions = []
                                                    if (!!newObject.delegateTo.length) {
                                                        newObject.delegateTo.forEach(deleg => {
                                                            newObject.delegationHistory.push(deleg)
                                                        })
                                                    }
                                                    newObject.delegateTo = []
                                                    newObject.arbitrateText = ''
                                                    newObject.arbitratorsFunctions = []
                                                    callback(null, newObject, oldObject)
                                                })
                                            } else {
                                                newObject.status = {id: '5'}
                                                newObject.workflow = ['refused', 1000000]
                                                newObject.contributorsFunctions = []
                                                if (!!newObject.delegateTo.length) {
                                                    newObject.delegateTo.forEach(deleg => {
                                                        newObject.delegationHistory.push(deleg)
                                                    })
                                                }
                                                newObject.delegateTo = []
                                                newObject.arbitrateText = ''
                                                newObject.arbitratorsFunctions = []
                                                callback(null, newObject, oldObject)
                                            }
                                        }
                                    )
                                } else if (context.action === 'abandon') {
                                    //if module realizationProject
                                    /*
                                    * statut = abandonné
                                    * projet complémentaire = abandonné
                                    * demande d'engagement abandonné
                                    *
                                    * */
                                    if ( context.module.name === 'Realization'){
                                        global.app.SE.Demand.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: ['contributorsFunctions', 'delegateTo', ],
                                                query: {
                                                    relatedProject: global.ObjectID(newObject.id)
                                                }
                                            }, (e, relatedDemands) => {
                                                if (e) return callback(e)
                                                let bulkWritesForAlreadyTreatedByFunctions = [{ deleteOne: {filter: { _id: null }} }]
                                                relatedDemands.forEach(demand => {
                                                    bulkWritesForAlreadyTreatedByFunctions.push({
                                                        updateOne: {
                                                            filter: {
                                                                _id: global.ObjectID(demand.id),
                                                            },
                                                            update: {
                                                                $set: {
                                                                    workflow: ['abandoned', 1000000],
                                                                    status: demand.status === '6' ? '13' : '9',
                                                                    contributorsFunctions: [],
                                                                    delegateTo: [],
                                                                    arbitrateText: '',
                                                                    arbitratorsFunctions: []
                                                                },
                                                                $push: {
                                                                    [`alreadyTreatedByFunctions.${demand.workflow[0]}`] : {
                                                                            $each: demand.contributorsFunctions.map(func => global.ObjectID(func.id).toString())
                                                                        },
                                                                    delegationHistory: {$each: demand.delegateTo}
                                                                }
                                                            }
                                                        }
                                                    })
                                                })

                                                //checkpoint
                                                global.app.SE.Demand.collection.bulkWrite(bulkWritesForAlreadyTreatedByFunctions,
                                                    {}, (e)=>{
                                                        if (e) return callback(e)
                                                        global.app.SE.WorkFlow.find(
                                                            {
                                                                ...basicContext(context),
                                                                fieldPath: ["workFlowConfigs.step", "workFlowConfigs.order", "workFlowConfigs.role.id"],
                                                                query: {
                                                                    "organizationAndMesh": {$elemMatch: {$in: relatedDemands.map(object => global.ObjectID(object.organizationAndMesh.id))}}, //{$elemMatch: {$eq: global.ObjectID(newObject.organizationAndMesh.id)}},
                                                                    "demandCategory": {$in: relatedDemands.map(object => global.ObjectID(object.demandCategory.id))}, //{$eq: global.ObjectID(newObject.demandCategory.id)},
                                                                    "demandNature": {$elemMatch: {$in: relatedDemands.map(object => global.ObjectID(object.demandNature.id))}}, //{$elemMatch: {$in: global.ObjectID(newObject.demandNature.id)}},
                                                                    "minMaxAmount": {$elemMatch: {$in: relatedDemands.map(object => global.ObjectID(object.plafond.id))}}
                                                                }
                                                            }, (e, workflows) => {
                                                                if (e) return callback(e)
                                                                const demandConfigsInArrays = workflows.map(wf => {
                                                                    const orderOfSteps = generateOrderOfSteps(steps, wf.workFlowConfigs)
                                                                    const firstStep = Object.keys(orderOfSteps)[0]
                                                                    return wf.workFlowConfigs.filter(conf => conf.step === firstStep)
                                                                })
                                                                const demandConfigs = demandConfigsInArrays.flat()
                                                                let demandRolesIDs = demandConfigs.map(conf => global.ObjectID(conf.role.id))
                                                                const readersInArrays = workflows.map(wf => {
                                                                    return wf.readers.map(role => global.ObjectID(role.id))
                                                                })
                                                                const readersIDs = readersInArrays.flat()
                                                                demandRolesIDs = demandRolesIDs.concat(readersIDs)
                                                                global.app.SE.Habilitation.find(
                                                                    {
                                                                        ...basicContext(context),
                                                                        fieldPath: ["user.id"],
                                                                        query: {
                                                                            "role": {$in: demandRolesIDs},
                                                                            $or: [{
                                                                                "grantedAccess": [],
                                                                                "grantedMesh": []
                                                                            }, {
                                                                                "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                                                "grantedMesh": []
                                                                            }, {
                                                                                "grantedAccess": [],
                                                                                "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                                            }]
                                                                        }
                                                                    }, (e, abandonMailList) => {
                                                                        if (e) return callback(e)
                                                                        if (!!abandonMailList.length) {
                                                                            const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'Demande abandonnée' : 'Projet abandonné'
                                                                            const status = context.module.name === 'Realization'? 'Abandonné après validation' : 'Abandonné'
                                                                            const mails = abandonMailList.map(hab => {
                                                                                const userMail = hab.user.mail
                                                                                const subject = `${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${status})`
                                                                                const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                                                return createAbondonMail(context, userMail, subject, newObject, stepAction)
                                                                            })
                                                                            global.mailer.sendMail(mails, (error) => {
                                                                                if (error) return callback(error)
                                                                            })
                                                                        }
                                                                })
                                                        })
                                                })
                                            }
                                        )
                                    }

                                    const currentStep = newObject.workflow[0]
                                    if (!!newObject.contributorsFunctions.length) {
                                        if (newObject.alreadyTreatedByFunctions[currentStep]) {
                                            newObject.alreadyTreatedByFunctions[currentStep].push(newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString()))
                                        } else {
                                            newObject.alreadyTreatedByFunctions[currentStep] = [newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString())]
                                        }
                                    }

                                    const demandConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === firstStep)
                                    let demandRolesIDs = demandConfigs.map(conf => global.ObjectID(conf.role.id))
                                    demandRolesIDs = demandRolesIDs.concat(readersIDs)
                                    return global.app.SE.Habilitation.find(
                                        {
                                            ...basicContext(context),
                                            fieldPath: ["user.id"],
                                            query: {
                                                "role": {$in: demandRolesIDs},
                                                $or: [{
                                                    "grantedAccess": [],
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                    "grantedMesh": []
                                                }, {
                                                    "grantedAccess": [],
                                                    "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                }]
                                            }
                                        }, (e, nextUsersList) => {
                                            if (!!nextUsersList.length) {
                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'Demande abandonnée' : 'Projet abandonné'
                                                const status = context.module.name === 'Realization'? 'Abandonné après validation' : 'Abandonné'
                                                const mails = nextUsersList.map(hab => {
                                                    const userMail = hab.user.mail
                                                    const subject = `${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${status})`
                                                    const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                    return createAbondonMail(context, userMail, subject, newObject, stepAction)
                                                })
                                                global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    newObject.status = context.module.name === 'Realization'? {id: '13'} : {id: '9'}
                                                    newObject.workflow = ['abandoned', 1000000]
                                                    newObject.contributorsFunctions = []
                                                    if (!!newObject.delegateTo.length) {
                                                        newObject.delegateTo.forEach(deleg => {
                                                            newObject.delegationHistory.push(deleg)
                                                        })
                                                    }
                                                    newObject.delegateTo = []
                                                    newObject.arbitrateText = ''
                                                    newObject.arbitratorsFunctions = []
                                                    callback(error, newObject, oldObject)
                                                })
                                            } else {
                                                newObject.status = context.module.name === 'Realization'? {id: '13'} : {id: '9'}
                                                newObject.workflow = ['abandoned', 1000000]
                                                newObject.contributorsFunctions = []
                                                if (!!newObject.delegateTo.length) {
                                                    newObject.delegateTo.forEach(deleg => {
                                                        newObject.delegationHistory.push(deleg)
                                                    })
                                                }
                                                newObject.delegateTo = []
                                                newObject.arbitrateText = ''
                                                newObject.arbitratorsFunctions = []
                                                callback(error, newObject, oldObject)
                                            }
                                        }
                                    )

                                } else if (context.action === 'delegate') {
                                    // if userIsInDelegateTo && (habnotok || rolenotok) {
                                    // cannot delagate}
                                    const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'
                                    if (!!newObject.delegateTo.length) {
                                        if (!userIsInDelegateTo || (userAugmentedHabOk && userRoleOk)) {
                                            // le 2ème cas : si jamais on a ajouté par erreur un intervenant actuel au delegateTo
                                            // (makes sense only if we cannot delete users from delegateTo)
                                            const oldDelagation = !!oldObject ? oldObject.delegateTo.map(user => global.ObjectID(user.id)) : []
                                            const addedDelegation = substractedItems2(newObject.delegateTo, oldDelagation)
                                            if (!!addedDelegation.length) {
                                                const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                const mails = addedDelegation.map(user => {
                                                    const userMail = user.mail
                                                    const subject = `Délégation ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(newObject.workflow[0])})`
                                                    const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                    const expectedAction = choseExpectedAction(newObject.workflow[0], context.action)
                                                    const module = moduleName(newObject.workflow[0])
                                                    return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                })
                                                return global.mailer.sendMail(mails, (error) => {
                                                    if (error) return callback(error)
                                                    callback(error, newObject, oldObject)
                                                })
                                            } else {
                                                return callback(new Errors.ValidationError('you have to add at least one user to delegate to'))
                                            }
                                        } else {
                                            return callback(new Errors.ValidationError('you cannot delegate to yourself'))
                                        }
                                    } else {
                                        return callback(new Errors.ValidationError('you have to choose at least one user to delegate to'))
                                    }
                                } else if (context.action === 'arbitrate') {
                                    const currentStep = newObject.workflow[0]
                                    if (!!newObject.contributorsFunctions.length) {
                                        if (newObject.alreadyTreatedByFunctions[currentStep]) {
                                            newObject.alreadyTreatedByFunctions[currentStep].push(newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString()))
                                        } else {
                                            newObject.alreadyTreatedByFunctions[currentStep] = [newObject.contributorsFunctions.map(func => global.ObjectID(func.id).toString())]
                                        }
                                    }
                                    let [nextStep, nextOrder] = getAndValidateNextStep(newObject.workflow[0], parseInt(newObject.workflow[1]), _workflowConf.workFlowConfigs, newObject.alreadyTreatedByFunctions)
                                    newObject.arbitrateTextList.push({
                                        user: _.pick(context.user, ['id', 'name']),
                                        step: newObject.workflow[0],
                                        order: newObject.workflow[1],
                                        motif: newObject.arbitrateText,
                                        arbitrationType: {id: _workflowConf.arbitrationType},
                                        date: moment().format("YYYY-MM-DD HH:mm"),
                                    })
                                    if (!!newObject.delegateTo.length) {
                                        newObject.delegateTo.forEach(deleg => {
                                            newObject.delegationHistory.push(deleg)
                                        })
                                    }
                                    newObject.delegateTo = []
                                    if (!_workflowConf.arbitrationType) {  //sans arbitrage
                                        return callback(new Errors.ValidationError('this demand have no arbitrators'))
                                    } else if (_workflowConf.arbitrationType === "1") {  // arbitrage 1
                                        const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'
                                        const arbitratorsIDs = _workflowConf.arbitrators.map(id => global.ObjectID(id)) || []
                                        newObject.arbitorNotYetActive = false
                                        return global.app.SE.Habilitation.find(
                                            {
                                                ...basicContext(context),
                                                fieldPath: ["user.id"],
                                                query: {
                                                    "role": {$in: arbitratorsIDs},
                                                    $or: [{
                                                        "grantedAccess": [],
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                        "grantedMesh": []
                                                    }, {
                                                        "grantedAccess": [],
                                                        "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                    }]
                                                }
                                            }, (e, nextUsersList) => {
                                                if (!!nextUsersList.length) {
                                                    const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                    const mails = nextUsersList.map(hab => {
                                                        const userMail = hab.user.mail
                                                        const subject = `Demande d'arbitrage ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : Arbitrage)`
                                                        const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                        const expectedAction = choseExpectedAction(newObject.workflow[0], context.action)
                                                        const module = 'arbitrage'
                                                        return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                    })
                                                    global.mailer.sendMail(mails, (error) => {
                                                        if (error) return callback(error)
                                                        newObject.status = {id: '11'}
                                                        //arbitratorsIDs.forEach( id => {
                                                        //    newObject.contributorsFunctions.push({id})
                                                        //})
                                                        newObject.arbitratorsFunctions = arbitratorsIDs
                                                        newObject.contributorsFunctions = []
                                                        newObject.arbitrateText = ''
                                                        callback(error, newObject, oldObject)
                                                    })
                                                } else {
                                                    callback(new Errors.ValidationError('this demand have no arbitrators'))
                                                }
                                            }
                                        )
                                    } else if (_workflowConf.arbitrationType === "2") {  // arbitrage 2
                                        newObject.arbitrateText = ''
                                        newObject.arbitorNotYetActive = true
                                        const configsIfNextStep = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                        let rolesIDsIfNextStep = configsIfNextStep.map(conf => conf.role.id)
                                        let alreadyTreatedByFunctionsCopy = _.cloneDeep(newObject.alreadyTreatedByFunctions)
                                        if (alreadyTreatedByFunctionsCopy[nextStep]) {
                                            alreadyTreatedByFunctionsCopy[nextStep].push(rolesIDsIfNextStep.map(id => global.ObjectID(id).toString()))
                                        } else {
                                            alreadyTreatedByFunctionsCopy[nextStep] = [rolesIDsIfNextStep.map(id => global.ObjectID(id).toString())]
                                        }
                                        let [nextNextStep, nextNextOrder] = getAndValidateNextStep(nextStep, nextOrder, _workflowConf.workFlowConfigs, alreadyTreatedByFunctionsCopy)
                                        const arbitratorsIDs = _workflowConf.arbitrators.map(id => id) || []
                                        const isItLastLastValidation = nextStep === "validation" && nextNextStep !== "validation"
                                        const isItLastValidation = newObject.workflow[0] === "validation" && nextStep !== "validation"
                                        const validationConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                        const lastValidatorsRolesIDs = validationConfigs.map(conf => conf.role.id)
                                        const lastValidatorsAreArbitors = compareArrays(arbitratorsIDs.map(id => id.toString()), lastValidatorsRolesIDs)
                                        if ((isItLastLastValidation && lastValidatorsAreArbitors) || isItLastValidation) {
                                            if (isItLastLastValidation) {
                                                newObject.workflow = [nextStep, nextOrder]
                                                const arbitratorsIDs = _workflowConf.arbitrators.map(id => id) || []
                                                if (newObject.alreadyTreatedByFunctions[nextStep]) {
                                                    newObject.alreadyTreatedByFunctions[nextStep].push(arbitratorsIDs.map(id => global.ObjectID(id).toString()))
                                                } else {
                                                    newObject.alreadyTreatedByFunctions[nextStep] = [arbitratorsIDs.map(id => global.ObjectID(id).toString())]
                                                }
                                            }
                                            return global.app.SE.Habilitation.find(
                                                {
                                                    ...basicContext(context),
                                                    fieldPath: ["user.id"],
                                                    query: {
                                                        "role": {$in: arbitratorsIDs},
                                                        $or: [{
                                                            "grantedAccess": [],
                                                            "grantedMesh": []
                                                        }, {
                                                            "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                            "grantedMesh": []
                                                        }, {
                                                            "grantedAccess": [],
                                                            "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                        }]
                                                    }
                                                }, (e, nextUsersList) => {
                                                    if (!!nextUsersList.length) {
                                                        const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                        const mails = nextUsersList.map(hab => {
                                                            const userMail = hab.user.mail
                                                            const subject = `Demande d'arbitrage ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : Arbitrage)`
                                                            const stepAction = choseStepAction(newObject.workflow[0], context.action)
                                                            const expectedAction = choseExpectedAction(newObject.workflow[0], context.action)
                                                            const module = 'arbitrage'
                                                            return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                        })
                                                        global.mailer.sendMail(mails, (error) => {
                                                            if (error) return callback(error)
                                                            newObject.status = {id: '11'}
                                                            //arbitratorsIDs.forEach( id => {
                                                            //    newObject.contributorsFunctions.push({id})
                                                            //})
                                                            newObject.arbitratorsFunctions = arbitratorsIDs
                                                            newObject.contributorsFunctions = []
                                                            newObject.arbitrateText = ''
                                                            newObject.arbitorNotYetActive = false
                                                            callback(error, newObject, oldObject)
                                                        })
                                                    } else {
                                                        callback(new Errors.ValidationError('this demand have no arbitrators'))
                                                    }
                                                }
                                            )
                                        } else { //
                                            newObject.arbitratorsFunctions = []
                                            newObject.arbitrateText = ''
                                            const nextConfigs = _workflowConf.workFlowConfigs.filter(conf => conf.step === nextStep && conf.order === nextOrder)
                                            let nextRolesIDs = nextConfigs.map(conf => global.ObjectID(conf.role.id))
                                            const withOrWithoutComments = '' //!lastComments.length ? '' : 'WithComments'
                                            return global.app.SE.Habilitation.find(
                                                {
                                                    ...basicContext(context),
                                                    fieldPath: ["user.id"],
                                                    query: {
                                                        "role": {$in: nextRolesIDs},
                                                        $or: [{
                                                            "grantedAccess": [],
                                                            "grantedMesh": []
                                                        }, {
                                                            "grantedAccess": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}},
                                                            "grantedMesh": []
                                                        }, {
                                                            "grantedAccess": [],
                                                            "grantedMesh": {$elemMatch: {$in: demandAugmentedOrgsAndMeshesIDs}}
                                                        }]
                                                    }
                                                }, (e, nextUsersList) => {
                                                    if (!nextUsersList.length) {
                                                        return callback(new Errors.ValidationError('there is no person that can handle this demand on the next step'))
                                                    } else {
                                                        const projectOrDemandTitle = context?.module?.category?.path === 'demande' ? 'de la demande' : 'du projet'
                                                        const mails = nextUsersList.map(hab => {
                                                            const userMail = hab.user.mail
                                                            const subject = `${choseSubjectStep(nextStep)} ${projectOrDemandTitle} N° : ${newObject.demandNumber} (Statut : ${choseStepStatus(nextStep)})`
                                                            const stepAction = choseStepAction(newObject.workflow[0], "validate")
                                                            const expectedAction = choseExpectedAction(nextStep, "validate")
                                                            const module = moduleName(nextStep)
                                                            return createValidationMail(context, userMail, subject, newObject, stepAction, expectedAction, module)
                                                        })
                                                        global.mailer.sendMail(mails, (error) => {
                                                            if (error) return callback(error)
                                                            newObject.status = getNextStepStatus(nextStep)
                                                            newObject.workflow = [nextStep, nextOrder]
                                                            newObject.contributorsFunctions = nextRolesIDs
                                                            callback(error, newObject, oldObject)
                                                        })
                                                    }
                                                }
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })
        }
    }
}
