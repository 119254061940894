const _ = require("lodash");
const { translateName } = require("../../../utils/i18n");

export const entity = require("./../../utils/entityJoin").joinEntities({
    name: "ThemeJoin",
    fields: [
        "code",
        "name",
        {
            path: "stream",
            type: "InputCorrespondenceByImportType",
            nullable: true
        },
        {
            type: "ThemeConfig",
            link: "MTO",
            nullable: true
        },
        {
            type: "SubTheme",
            link: "MTO",
            nullable: true
        },
        {
            path: "completeCode",
            lazy: true,
            fieldPath: [
                "themeConfig.completeCode",
                "subTheme.completeCode",
                "subTheme.themeConfig.completeCode",
                "name"
            ],
            $f: function(object, context, callback) {
                if (object.joinedEntity === "ThemeConfig") {
                    return callback(null, `theme-${object.code}`)
                }
                else if (object.joinedEntity === "SubTheme") {
                    return callback(null, `${object.themeConfig.completeCode}.${object.code}`)
                }
                else if (object.joinedEntity === "SubThemeElement") {
                    return callback(null, `${object.subTheme.completeCode}.${object.code}`)
                }
                else {
                    return callback(null, `theme-${object.code}`)
                }
            }
        },
        {
            path: "completeCodeWithoutPrefix",
            fieldPath: ["completeCode"],
            f: function() {
                return this.completeCode.replace("theme-", "");
            }
        },
        {
            path: "streamId",
            fieldPath: [
                "stream.id",
                "themeConfig.stream.id",
                "subTheme.themeConfig.stream.id",
            ],
            $f: function(themeJoin, context, callback) {

                switch (themeJoin.joinedEntity) {
                    case "ThemeConfig":
                        return callback(null, _.get(themeJoin, "stream.id"))
                    case "SubTheme":
                        return callback(null, _.get(themeJoin, "themeConfig.stream.id"))
                    case "SubThemeElement":
                        return callback(null, _.get(themeJoin, "subTheme.themeConfig.stream.id"))
                    default:
                        return null
                }
            }
        },
        {
            path: "completeName",
            lazy: true,
            fieldPath: [
                "subTheme.themeConfig.name",
                "themeConfig.name",
                "name"
            ],
            $f: function(themeJoin, context, callback) {
                const translate = name => context.translateName
                    ? context.translateName(name)
                    : translateName(name, _.get(context, 'language.id'))

                const objects = themeJoin => {
                    switch (themeJoin.joinedEntity) {
                        case "ThemeConfig":
                            return [themeJoin];
                        case "SubTheme":
                            return [themeJoin.themeConfig, themeJoin];
                        case "SubThemeElement":
                            return [themeJoin.subTheme.themeConfig, themeJoin.subTheme, themeJoin];
                        default:
                            return [];
                    }
                };

                const completeName = _(themeJoin)
                    .thru(objects)
                    .map("name")
                    .map(translate)
                    .value()
                    .join(" > ");

                callback(null, completeName);
            }
        },
        {
            path: "mtKeyName",
            fieldPath: ["completeCode"],
            f: function() {
                return this.completeCode + ".Mt";
            }
        },
        {
            path: "nbKeyName",
            fieldPath: ["completeCode"],
            f: function() {
                return this.completeCode + ".Nb";
            }
        }
    ]
}, ["ThemeConfig", "SubTheme", "SubThemeElement"])
