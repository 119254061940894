import { translateName } from '../../../../utils/i18n'
import trans from "react-i18next/dist/commonjs/Trans";
const _ = require('lodash')
const moment = require('moment')

const defaultMail = {
    from: '"PilotFlow" <pilotflow@keenpoint.com>',
    replyTo: 'support@keenpoint.com',
    verbose: {
        general: true
    }
}

const PENDING = 'pending'
const REVIEW = 'review'
const ONGOING = 'inProgress'
const RISK = 'risk'
const NO_RISK = 'noRisk'

const { newContext } = require('./../../../utils/contextUtils')

export const job = {
    name: 'adminReportTreatedAlerts',
    title: 'Rapport analyse des alertes traitées (ADMIN)',
    execute: function(context, callback) {
        generateTreatedAlertReport(context)
            .then(() => {
                console.log('finished treated admin alert report generation')
                callback(null, {message: 'Job ended !'})
            })
            .catch(error => callback(error))
    }
}

async function generateTreatedAlertReport(context) {
    const periodEnd = moment().add(1, 'days')

    /*
    const configs = await app.S.FiscalYear.find({...context, fieldPath: ['fiscalYearStart']})

    const config = configs[0]

    const fiscalYearStart = config
        ? config.fiscalYearStart
        : '01-01'


    const periodStart = periodEnd.isSameOrAfter(moment(fiscalYearStart, 'MM-DD'))
        ? moment(fiscalYearStart, 'MM-DD')
        : moment(fiscalYearStart, 'MM-DD').subtract(1, 'years')

    const period = {
        start: periodStart.format('YYYY-MM-DD'),
        end: periodEnd.format('YYYY-MM-DD')
    }
     */

    const customPeriod = {
        start: moment("2024-09-09", 'YYYY-MM-DD').format('YYYY-MM-DD'),
        end: periodEnd.format('YYYY-MM-DD')
    }

    const alertsContext = newContext(context, { data: customPeriod })

    const [alerts, reasons] = await Promise.all([
        loadAlerts(alertsContext),
        app.S.Reason.find(context)
    ])

    const data = structureTreatedAlertsData({ alerts, reasons })

    return await new Promise((resolve, reject) => {
        sendMail(data, reasons, context, error => {
            if (error) reject(error)
            else resolve()
        })
    })
}

export const structureTreatedAlertsData = ({alerts, reasons}) => {
    console.log('--> Structure the Data')
    const groupedByAlertConf = _.groupBy(alerts, alert => alert.alertConfiguration.id)

    const sortedAlertModelIds = _.sortBy(Object.keys(groupedByAlertConf), alertConfId => {
        const alertConfiguration = groupedByAlertConf[alertConfId][0].alertConfiguration
        return translateName(alertConfiguration.name)
    })

    const results = _.flatMap(sortedAlertModelIds, alertConfId => {
        const alertConfiguration = groupedByAlertConf[alertConfId][0].alertConfiguration
        const alerts = groupedByAlertConf[alertConfId]
        const groupedByStore = _.groupBy(alerts, alert => alert.store.id)

        const sortedStoreIds = _.sortBy(Object.keys(groupedByStore), storeId => {
            const store = groupedByStore[storeId][0].store
            return translateName(store.area?.name)
        })

        return sortedStoreIds.map(storeId => {
            const store = groupedByStore[storeId][0].store

            const sortedAlerts = _.sortBy(groupedByStore[storeId], alert => alert.store?.code)

            return sortedAlerts.reduce((acc, alert) => {
                acc.detected++
                const status = alert.workflow.step
                if ([PENDING, REVIEW, ONGOING].includes(status)) {
                    acc[status]++
                } else if (status === RISK && alert.reason) {
                    acc[RISK][alert.reason.code]++
                    acc[RISK]['total']++
                } else if (status === NO_RISK && alert.reason) {
                    acc[NO_RISK][alert.reason.code]++
                    acc[NO_RISK]['total']++
                }
                return acc
            }, {
                alertConfiguration,
                area: store.area,
                store,
                detected: 0,
                [PENDING]: 0,
                [REVIEW]: 0,
                [ONGOING]: 0,
                [RISK]: reasons.reduce(
                    (o, reason) => {
                        return Object.assign({}, o, { [reason.code]: 0 })
                    },
                    { total: 0 }
                ),
                [NO_RISK]: reasons.reduce(
                    (o, reason) => {
                        return Object.assign({}, o, { [reason.code]: 0 })
                    },
                    { total: 0 }
                )
            })
        })
    })

    const totals = results.reduce(
        (acc, line) => {
            acc.detected += line.detected
            acc[PENDING] += line[PENDING]
            acc[REVIEW] += line[REVIEW]
            acc[ONGOING] += line[ONGOING]
            reasons.forEach(reason => {
                acc[RISK][reason.code] +=
                    line[RISK][reason.code]
            })
            acc[RISK].total += line[RISK].total
            reasons.forEach(reason => {
                acc[NO_RISK][reason.code] +=
                    line[NO_RISK][reason.code]
            })
            acc[NO_RISK].total += line[NO_RISK].total
            return acc
        },
        {
            detected: 0,
            [PENDING]: 0,
            [REVIEW]: 0,
            [ONGOING]: 0,
            [RISK]: reasons.reduce(
                (o, reason) => {
                    return Object.assign({}, o, { [reason.code]: 0 })
                },
                { total: 0 }
            ),
            [NO_RISK]: reasons.reduce(
                (o, reason) => {
                    return Object.assign({}, o, { [reason.code]: 0 })
                },
                { total: 0 }
            )
        }
    )
    return {results, totals}
}

export const configureTreatedAlertsWorkbook = ({results, totals}, reasons) => workbook => {
    const dataColumns = [
        {path: "detected", tKey: "", getter: data => data.detected},
        {path: "pendingStore", tKey: "Manager", getter: data => data[PENDING] + data[REVIEW]},
        {path: "pendingArea", tKey: "Validator", getter: data => data[ONGOING]},
        ...reasons.map(reason => ({
            path: `${reason.code}Risk`, tKey: translateName(reason.name), getter: data => data[RISK][reason.code]
        })),
        {path: 'totalRisk', tKey: "Total", getter: data => data[RISK].total},
        ...reasons.map(reason => ({
            path: `${reason.code}NoRisk`, tKey: translateName(reason.name), getter: data => data[NO_RISK][reason.code]
        })),
        {path: 'totalNoRisk', tKey: "Total", getter: data => data[NO_RISK].total},
    ]

    const columns = [
        {path: "alertConfiguration", tKey: "", getter: data => translateName(data.alertConfiguration.name)},
        {path: "area", tKey: "", getter: data => data.area?.fullName},
        {path: "store", tKey: "", getter: data => data.store?.fullName},
        ...dataColumns
    ]
    workbook.creator = 'Keenpoint'
    workbook.lastModifiedBy = 'Keenpoint'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ]

    let dataSheet = workbook.addWorksheet('Results', {defaultColWidth: 300})

    const darkGrayBG = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E0E0E0' },
        bgColor: { argb: 'E0E0E0' }
    }
    let row = 1
    let col = 1

    dataSheet.getRow(row).getCell(1).value = 'Alert Model'
    dataSheet.getRow(row).getCell(2).value = 'Area'
    dataSheet.getRow(row).getCell(3).value = 'Store'
    dataSheet.getRow(row).getCell(4).value = 'Detected incidents'
    dataSheet.getRow(row).getCell(5).value = 'Pending incidents'
    dataSheet.getRow(row).getCell(7).value = 'RISK'
    dataSheet.getRow(row).getCell(7 + reasons.length + 1).value = 'NO RISK'

    columns.forEach((column, index) => {
        const cell = dataSheet.getRow(row).getCell(index + 1)
        cell.fill = darkGrayBG
        cell.font = {
            size: 12,
            bold: true
        }
        /*
        cell.border = {
            bottom: {style:'thin'}
        }
         */
    })


    row = 2
    col = 1
    const header = dataSheet.getRow(row)
    columns.forEach(column => {
        header.getCell(col).value = column.tKey
        header.getCell(col).fill = darkGrayBG
        header.getCell(col).font = {
            size: 12,
            bold: true
        }
        header.getCell(col).border = {
            bottom: {style:'thin'}
        }
        col++
    })
    header.getCell(col).border = {left: {style:'thin'}}

    row = 3
    results.forEach(line => {
        col = 1
        columns.forEach(column => {
            dataSheet.getRow(row).getCell(col).value = column.getter(line)
            dataSheet.getRow(row).getCell(col).font = {
                size: 12,
            }
            col++
        })
        dataSheet.getRow(row).getCell(col).border = {
            left: {style:'thin'},
        }
        row++
    })

    col = 1
    const totalColumns = [
        {getter: () => "Total"},
        {getter: () => ""},
        {getter: () => ""},
        ...dataColumns
    ]
    totalColumns.forEach(column => {
        dataSheet.getRow(row).getCell(col).value = column.getter(totals)
        dataSheet.getRow(row).getCell(col).font = {
            size: 12,
            bold: true
        }
        dataSheet.getRow(row).getCell(col).border = {
            top: {style:'thin'},
            bottom: {style:'thin'}
        }
        col++
        dataSheet.getRow(row).getCell(col).border = {
            left: {style:'thin'}
        }
    })

    /*
    dataSheet.mergeCells(1, 1, 2, 1)
    dataSheet.mergeCells(1, 2, 2, 2)
    dataSheet.mergeCells(1, 3, 2, 3)
    dataSheet.mergeCells(1, 4, 2, 4)
     */

    dataSheet.mergeCells(1, 5, 1, 6)
    dataSheet.mergeCells(1, 7, 1, 7 + reasons.length)
    dataSheet.mergeCells(1, 7 + reasons.length + 1, 1, 7 + reasons.length + 1 + reasons.length )

    columns.forEach((column, index) => {
        dataSheet.getColumn(index + 1).width = 15
    })
}

const sendMail = function(data, reasons, context, callback) {
    global.excel.generateExcel(
        configureTreatedAlertsWorkbook(data, reasons, context),
        `Rapport ${moment().format("DD/MM/YYYY")}.xlsx`,
        (err, file) => {
            const mail = _.defaults(
                {
                    to: "support@keenpoint.com",
                    subject: { template: "Rapport analyse des alertes traitées (ADMIN)"},
                    content: {html: "<div><p>Bonjour,</p></div><div><p>Veuillez trouver ci-joint le Rapport analyse des alertes traitées (ADMIN).</p></div><div><p>Cordialement,</p><p>Keenpoint Consulting</p></div>"},
                    attachments: [file],
                },
                defaultMail
            )

            mailer.sendMail(mail, callback)
        })
}

export function loadAlerts(context) {
    console.log('--> Load Data treated alerts')

    const startDate = moment.utc(context.data.start, 'YYYY-MM-DD').toDate()
    const endDate = moment.utc(context.data.end, 'YYYY-MM-DD').toDate()

    const datesQuery = {
        baseDay: {
            $gte: startDate,
            $lt: endDate
        }
    };

    const alertContext = newContext(context, {
        fieldPath: [
            'alertConfiguration.id',
            'alertConfiguration.name',
            'reason.id',
            'reason.code',
            'reason.name',
            'store.id',
            'store.fullName',
            'store.area'
        ],
        query: {
            ...datesQuery,
            'workflow.step': { $in: ['pending', 'review', 'inProgress', RISK, NO_RISK] },
            workflow: { $ne: null }
        }
    })
    return app.S.Alert.find(alertContext)
}
