const _ = require("lodash")
const fs = require('fs')
const util = require('util')
const moment = require("moment")
const async = require("async")
const { v4: uuid } = require('uuid')
const {decrypt} = require("../../utils/crypto")
const Errors = require("../../utils/Errors").default
const path = require("path")
const { translateName } = require("../../../utils/i18n")
const {setFormButtons, changeFieldDisabled, setFieldVisibility, setFieldListOptions, setFieldEdition, setDataList} = require("../../../apps/KpModule/actions")
const {generateFetchFieldListAction, GET_OBJECT_SUCCESS} = require("../../../apps/KpModule/actions/api")
const {generateSurveyReport, generateAnalysis} = require('./utils/surveyUtils')
const {basicContext} = require("../../utils/contextUtils")
const {fieldsByModel} = require("./messaging")

const mailerPath = path.join(global.appRoot, global.isProd ? "buildServer/server/KpMailer" : "src/server/KpMailer")

const returnButton = {
    tKey: 'return',
    bsStyle: 'default',
    type: 'return'
}

const exportButton = {
    tKey: 'export',
    bsStyle: 'success',
    type: 'save'
}
const saveButton = {
    tKey: 'save',
    bsStyle: 'success',
    type: 'save'
}

const relaunchButton = {
    tKey: 'toRelaunch',
    action: 'relaunch',
    bsStyle: 'primary',
    getUserConfirmation: true,
    type: 'action'
}

const saveActionButton = {
    tKey: 'save',
    action: 'save',
    bsStyle: 'success',
    bypassFormValidation: true,
    type: 'action'
}

const validationButton = {
    tKey: 'send',
    action: 'validate',
    bsStyle: 'primary',
    getUserConfirmation: true,
    type: 'action'
}

const diffuseButton = {
    tKey: 'diffuse',
    action: 'diffuse',
    bsStyle: 'primary',
    getUserConfirmation: true,
    type: 'action'
}

function emitFetchEditObjectFailure(error, context) {
    global.ioSocket.emit(
        `fetchEditObject-failure-${context.user.id}${context.clientContext.accessId}`,
        { error: error.message }
    );
}

function emitFetchEditObjectSuccess(object, context) {
    global.ioSocket.emit(
        `fetchEditObject-success-${context.user.id}${context.clientContext.accessId}`,
        transformObjectForClient(object)
    );
}

function transformObjectForClient(object) {
    return {
        id: object.id,
        code: object.code,
        surveyTitle: object.surveyTitle,
        status: object.status,
        start: moment(object.start).format('YYYY-MM-DD'),
        end: moment(object.end).format('YYYY-MM-DD'),
        numberOfResponses: object.numberOfResponses,
        responsesRate: object.responsesRate,
        diffusionDate: object.diffusionDate && moment(object.diffusionDate).format('YYYY-MM-DD'),
        relaunchDate: object.relaunchDate && moment(object.relaunchDate).format('YYYY-MM-DD'),
        noDeleteButtonAccess: object.noDeleteButtonAccess
    }
}

export const entities = () =>[
    {
        name: 'SurveyEvolution',
        fields: [
            'survey',
            'user',
            'results'
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) callback(error)
                else
                    generateAnalysis(context)
                        .then(objects => callback(null, objects))
                        .catch(error => callback(error))
            })
        },
    },
    {
        name: 'SurveyPrototype',
        fields: [
            'title',
            'description',
            'validationMessage'
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) callback(error)
                else global.app[context.model.id].SurveyConf.find({
                    ...basicContext(context),
                    fieldPath: [
                        'title'
                    ],
                    query: {}
                }, callback)
            })
        },
        get: function(id, context, callback) {
            this.prepareContext(context, 'R', (error, context) => {
                if (error) callback(error)
                else {
                    global.app[context.model.id].SurveyConf.get( id, {
                            ...basicContext(context),
                            fieldPath: [
                                'title',
                                'accompanyingMessage.language.id',
                                'accompanyingMessage.body',
                                'accompanyingMessage.validationMessage',
                                'questions.id',
                                'questions.wording',
                                'questions.subtitle',
                                'questions.width.id',
                                'questions.minWidth',
                                'questions.minValue',
                                'questions.required',
                                'questions.maxValue',
                                'questions.surveyType',
                                'questions.responseSet.responseElements',
                                'questions.dependentOnQuestion.id',
                                'questions.dependantOnResponseElement.id'
                            ]
                        }, (e, surveyConf) => {
                            if(e) return callback(e)
                            let accMessage
                            accMessage = surveyConf.accompanyingMessage.find(accMess => accMess.language.id === context.language.id)
                            if(!accMessage) accMessage = surveyConf.accompanyingMessage[0]
                            callback(null, {
                                id: surveyConf.id,
                                title: surveyConf.title,
                                description: accMessage?.body,
                                validationMessage: accMessage?.validationMessage,
                                questions: surveyConf.questions.reduce((acc, surveyQuestion) => {

                                    const surveyType = _.get(surveyQuestion, 'surveyType.id')

                                    let value = null
                                    let options = null
                                    switch(surveyType){
                                        case 'labeledCheckbox':
                                            value = false
                                            break
                                        case 'cursor':
                                            options = _.get(surveyQuestion, 'responseSet.responseElements')
                                            break
                                        case 'radioButton':
                                            value = null
                                            options = _.get(surveyQuestion, 'responseSet.responseElements')
                                            break
                                        case 'multiCheckbox':
                                            value = []
                                            options = _.get(surveyQuestion, 'responseSet.responseElements')
                                            break
                                        case 'rankOptions':
                                            value = []
                                            options = _.get(surveyQuestion, 'responseSet.responseElements')
                                            break
                                        case 'boundedIntegerInDropdown':
                                            const minValue = parseInt(surveyQuestion.minValue)
                                            const maxValue = parseInt(surveyQuestion.maxValue)

                                            options = _.range(minValue, maxValue + 1).map(value => {
                                                return {
                                                    id: value,
                                                    name: value
                                                }
                                            })
                                            break
                                    }

                                    const field = {
                                        path: surveyQuestion.id,
                                        responseId: surveyQuestion.id,
                                        label: surveyQuestion.wording,
                                        subtitle: surveyQuestion.subtitle,
                                        width: _.get(surveyQuestion, 'width.id', "12"),
                                        minWidth: surveyQuestion.minWidth,
                                        type: _.get(surveyQuestion, 'surveyType.id'),
                                        required: surveyQuestion.required,
                                        dependentOnQuestion: _.get(surveyQuestion, 'dependentOnQuestion.id'),
                                        dependantOnResponseElement: surveyQuestion.dependantOnResponseElement,
                                        default: value,
                                        options,
                                        writable: true, editable: true, disabled: false,
                                    }
                                    if(['boundedInteger', 'boundedNumber'].includes(surveyType)) {
                                        field.minValue = surveyQuestion.minValue
                                        field.maxValue = surveyQuestion.maxValue
                                    }

                                    if('boundedInteger' === surveyType) {
                                        field.wholeNumber = true
                                    }

                                    return {
                                        ...acc,
                                        [surveyQuestion.id]: field
                                    }
                                }, {}),
                                buttons: [returnButton]
                            })
                        }
                    )
                }
            })
        }
    },
    {
        name: 'ResponseElement',
        fields: [
            'name',
            {path: 'hasDetailedResponse', type: 'boolean'},
            {path: 'detailedResponsePlaceholder'},
            {path: 'coefficient', type: 'integer'},
            {
                path: 'tName',
                fieldPath: ['name'],
                $f: (object, context, callback) => {
                    callback(null, context.translateName ? context.translateName(object.name) : translateName(object.name, 'fr'));
                }

            },
            {
                path: 'noDeleteButtonAccess',
                fieldPath: ['responseSet.id'],
                $f: (object, context, callback) => {
                    if(!context.model?.id) return callback(null, false)

                    global.app[context.model.id].SurveyQuestion.get(
                        { responseSet: global.ObjectID(object.responseSet.id) },
                        {
                            fieldPath: ['id'],
                            group: context.group
                        },
                        (error, question) => {
                            if(!question) return callback(null, false)
                            global.app[context.model.id].SurveyResponse.get(
                                { surveyQuestion: global.ObjectID(question.id) },
                                {
                                    fieldPath: ['id'],
                                    group: context.group
                                },
                                (error, response) => {
                                    callback(null, !!response);
                                }

                            )
                        }

                    )
                }

            },
        ]
    },
    {
        name: 'ResponseSet',
        fields: [
            'name',
            {path: 'responseElements', type: 'ResponseElement', link: 'OTM'},
            {
                path: 'noDeleteButtonAccess',
                fieldPath: ['responseSet.id'],
                $f: (object, context, callback) => {
                    if(!context.model?.id) return callback(null, false)

                    global.app[context.model.id].SurveyQuestion.get(
                        { responseSet: global.ObjectID(object.id) },
                        {
                            fieldPath: ['id'],
                            group: context.group
                        },
                        (error, question) => {
                            if(!question) return callback(null, false)
                            global.app[context.model.id].SurveyResponse.get(
                                { surveyQuestion: global.ObjectID(question.id) },
                                {
                                    fieldPath: ['id'],
                                    group: context.group
                                },
                                (error, response) => {
                                    callback(null, !!response);
                                }

                            )
                        }

                    )
                }

            },
        ]
    },
    {
        name: 'Section',
        type: 'static',
        fields: [
            {path: 'id', type: 'string'},
            'name'
        ],
        objects: [
            {id: 'title', name: 'title'},
            {id: 'question', name: 'question'}
        ]
    },
    {
        name: 'SurveyType',
        type: 'static',
        fields: [
            {path: 'id', type: 'string'},
            'name',
            'type'
        ],
        objects: [
            {id: 'radioButton', name: 'Choix unique', type: 'question'},
            {id: 'multiCheckbox', name: 'Choix multiple', type: 'question'},
            {id: 'cursor', name: 'Curseur', type: 'question'},
            {id: 'rankOptions', name: 'Question à classement', type: 'question'},
            //{id: 'labeledCheckbox', name: 'Case à cocher', type: 'question'},
            {id: 'labeledText', name: 'Texte', type: 'question'},
            {id: 'boundedInteger', name: 'Entié borné', type: 'question'},
            {id: 'boundedIntegerInDropdown', name: 'Liste entier bornée', type: 'question'},
            {id: 'boundedNumber', name: 'Réel borné', type: 'question'},
            {id: 'title1', name: 'Titre 1', type: 'title'},
            {id: 'title2', name: 'Titre 2', type: 'title'},
            {id: 'title3', name: 'Titre 3', type: 'title'}
        ],
        filters: [
            {
                name: 'bySectionType',
                isDefault: false,
                match: (object, context) => {
                    const sectionType = _.get(context, 'data.type.id')
                    return !sectionType || object.type === sectionType
                }
            }
        ]
    },
    {
        name: 'ColumnWidth',
        type: 'static',
        fields: [{path: 'id', type: 'string'}, {path: 'name', type: 'string'}],
        objects: [
            {id: "3", name: '1/4'},
            {id: "4", name: '1/3'},
            {id: "6", name: '1/2'},
            {id: "12", name: '1/1'}
        ]
    },
    {
        name: 'AccompanyingMessage',
        fields: [
            'Language',
            'body',
            'validationMessage',
            'messageForLatecomers'
        ]
    },
    {
        name: 'SurveyQuestion',
        fields: [
            'Section',
            'wording',
            'subtitle',
            {path: 'width', type: 'ColumnWidth', nullable: true},
            'minWidth',
            {path: 'dependentOnQuestion', type: 'SurveyQuestion', nullable: true},
            {path: 'dependantOnResponseElement', type: 'ResponseElement', link: 'MTM', nullable: true},
            {
                path: 'tWording',
                fieldPath: ['wording'],
                $f: (object, context, callback) => {
                    callback(null, translateName(object.wording, _.get(context, "language.id")));
                }

            },
            {path: 'minValue', type: 'integer', nullable: true},
            {path: 'maxValue', type: 'integer', nullable: true},
            {path: 'required', type: 'boolean'},
            {path: 'coefficient', type: 'integer'},
            'SurveyType',
            {type: 'ResponseSet', nullable: true},
            {
                path: 'noDeleteButtonAccess',
                fieldPath: ['id'],
                $f: (object, context, callback) => {
                    if(!context.model?.id) return callback(null, false)

                    global.app[context.model.id].SurveyResponse.get(
                        { surveyQuestion: global.ObjectID(object.id)},
                        {
                            fieldPath: ['id'],
                            group: context.group
                        },
                        (error, survey) => {
                            callback(null, !!survey);
                        }

                    )
                }

            },
        ]
    },
    {
        name: 'SurveyConf',
        fields: [
            'title',
            'description',
            'validationMessage',
            {
                path: 'tTitle',
                fieldPath: ['title'],
                $f: (object, context, callback) => {
                    callback(null, translateName(object.title, _.get(context, "language.id")));
                }

            },
            {path: 'accompanyingMessage', type: 'AccompanyingMessage', link: 'OTM'},
            {path: 'questions', type: 'SurveyQuestion', link: {type: 'OTM', onParent: true, onChild: true, deleteType: 'cascade'}},
            {
                path: 'usedInDiffusedSurvey',
                fieldPath: ['id'],
                $f: (object, context, callback) => {
                    if(!context.model?.id) return callback(null, false)

                    global.app[context.model.id].Survey.get(
                        { surveyConf: global.ObjectID(object.id), status: 'diffused' },
                        {
                            fieldPath: ['id'],
                            group: context.group
                        },
                        (error, survey) => {
                            callback(null, !!survey);
                        }

                    )
                }

            },
        ],
        save: function(newObject, context, callback) {
            this.prepareContext(context, 'S', (error) => {
                if (error) callback(error)
                else {
                    const surveyConfObjectID = newObject.id
                        ? global.ObjectID(newObject.id)
                        : new global.ObjectID()
                    async.parallel([
                        callback => async.series(newObject.questions.map(question => callback => {
                            global.app[context.model.id].SurveyQuestion.collection.update(
                                {_id: global.ObjectID(question.id)},
                                {
                                    section : question.section.id,
                                    wording : question.wording,
                                    subtitle : question.subtitle,
                                    width : question.width.id,
                                    dependentOnQuestion : question.dependentOnQuestion && global.ObjectID(question.dependentOnQuestion.id),
                                    dependantOnResponseElement : question.dependentOnQuestion && question.dependantOnResponseElement.map(elem => global.ObjectID(elem.id)),
                                    minValue : question.minValue,
                                    maxValue : question.maxValue,
                                    required : question.required,
                                    coefficient : question.coefficient,
                                    surveyType : question.surveyType.id,
                                    responseSet : question.responseSet && global.ObjectID(question.responseSet.id),
                                    group : global.ObjectID(context.group.id)
                                },
                                {upsert: true},
                                (error) => {
                                    if(error) return callback(error)
                                    callback()
                                }
                            )
                        }), callback),
                        callback => async.parallel(newObject.accompanyingMessage.map(message => callback => {
                            global.app[context.model.id].AccompanyingMessage.collection.update(
                                {_id: global.ObjectID(message.id)},
                                {
                                    language : message.language.id,
                                    body : message.body,
                                    validationMessage : message.validationMessage,
                                    surveyConf : surveyConfObjectID,
                                    group : global.ObjectID(context.group.id)
                                },
                                {upsert: true},
                                (error) => {
                                    if(error) return callback(error)
                                    callback()
                                }
                            )
                        }), callback)

                    ], (error) => {
                        if(error) return callback(error)
                        global.app[context.model.id].SurveyConf.collection.update(
                            {_id: surveyConfObjectID},
                            {
                                title : newObject.title,
                                questions : newObject.questions.map(question => global.ObjectID(question.id)),
                                group : global.ObjectID(context.group.id)
                            },
                            {upsert: true},
                            (error) => {
                                if(error) return callback(error)
                                callback(null, {
                                    ...newObject,
                                    id: surveyConfObjectID
                                })
                        })

                    })
                }
            })
        }
    },
    {
        name: 'Survey',
        fields: [
            {path: 'start', type: 'date'},
            {path: 'end', type: 'date'},
            {path: 'diffusionDate', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true},
            {path: 'relaunchDate', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true},
            'SurveyConf',
            {path: 'target', type: 'User', link: 'MTM'},
            {path: 'removable', type: 'boolean'},
            {
                path: 'remainingUsers',
                fieldPath: [
                    'id',
                    'target.id',
                    'target.mail',
                    'target.firstname',
                    'target.lastname',
                    'target.civility.id',
                    'target.language.id'
                ],
                $f: function (survey, context, callback) {
                    if(!context.model?.id) return callback(null, survey.target)

                    global.app[context.model.id].SurveyResult.find(
                        {
                            query: { survey: global.ObjectID(survey.id), status: 'ongoing' },
                            fieldPath: ['id', 'user.id'],
                            group: context.group
                        },
                        (error, surveyResults) => {
                            callback(null, survey.target.filter(user => {
                                return surveyResults.find(surveyResult => surveyResult.user.id === user.id)
                            }))
                        }
                    )
                }
            },
            {
                path: 'noDeleteButtonAccess',
                fieldPath: ['id', 'target.id', 'removable'],
                $f: (survey, context, callback) => {
                    if(!context.model?.id) return callback(null, false)

                    if(survey.removable) return callback(null, false)

                    global.app[context.model.id].SurveyResult.find(
                        {
                            query: { survey: global.ObjectID(survey.id), status: 'validated' },
                            fieldPath: ['id', 'user.id'],
                            group: context.group
                        },
                        (error, surveyResults) => {
                            callback(null, !!surveyResults.length)
                        }
                    )
                }

            },
            "status",
            {path: "closed", type: "boolean"},
            {path: "broadcastLists", type: "BroadcastListPlatform", link: "MTM", nullable: true},
            {path: "mails", list: true},
            {
                path: "sent",
                fieldPath: ['mails.id', 'recipients.mail', 'recipients.name', 'mails.messageId', 'status'],
                $f: function (message, context, callback) {
                    global.db.collection("mailEvent").find({
                        "message-id": {$in: message.mails.map(messageId => `<${messageId}>`)},
                        "event": "request"
                    }).toArray((e, results) => callback(e, results.length))
                }
            },
            {
                path: 'delivered',
                fieldPath: ['mails.id', 'recipients.mail', 'recipients.name', 'mails.messageId', 'status'],
                $f: function (message, context, callback) {
                    global.db.collection("mailEvent").find({
                        'message-id': {$in: message.mails.map(messageId => `<${messageId}>`)},
                        'event': 'request'
                    }).toArray((e, results) => callback(e, results.length))
                }
            },
            {
                path: 'opened',
                fieldPath: ['mails.id', 'recipients.mail', 'recipients.name', 'mails.messageId', 'status'],
                $f: function (message, context, callback) {
                    global.db.collection("mailEvent").aggregate([
                        {
                            $match: {
                                "message-id": {$in: message.mails.map(messageId => `<${messageId}>`)},
                                'event': {$in: ['unique_opened', 'unique_proxy_open']}
                            }
                        },
                        {
                            $group: {
                                _id: "$message-id"
                            }
                        }
                    ]).toArray((e, results) => {
                        callback(e, results.length)
                    })
                }
            },
            {
                path: 'clicked',
                fieldPath: ['mails.id', 'recipients.mail', 'recipients.name', 'mails.messageId', 'status'],
                $f: function (message, context, callback) {
                    global.db.collection("mailEvent").aggregate([
                        {
                            $match: {
                                "message-id": {$in: message.mails.map(messageId => `<${messageId}>`)},
                                'event': 'click'
                            }
                        },
                        {
                            $group: {
                                _id: "$message-id"
                            }
                        }
                    ]).toArray((e, results) => {
                        callback(e, results.length)
                    })
                }
            },
            {
                path: "mailsFollowup",
                fieldPath: ['mails.id', 'mails.messageId', 'target.mail', 'target.name', 'status'],
                $f: function (survey, context, callback) {
                    if(survey.status === 'draft') return callback(null, [])

                    global.db.collection("mailEvent").aggregate([
                        {
                            $match: {"message-id": {$in: _.compact(survey.mails).map(messageId => `<${messageId}>`)}}
                        },
                        {
                            $sort: { "event_date": 1 }
                        },
                        {
                            $group: {
                                _id: "$message-id",
                                messageId: { $first: "$message-id" },
                                email: { $first: "$email" },
                                events: {
                                    $push: { event: "$event", date: "$event_date" }
                                },
                                firstEventDate: { $first: "$event_date" } // Capture the first event's date
                            }
                        }
                    ]).toArray((e, results) => {
                        return callback(e, survey.target.map(user => {
                            const userResults = results.filter(result => decrypt(user.mail) === result.email)
                            if(!userResults.length) return {
                                id: decrypt(user.mail),
                                recipient: user.name,
                            }

                            const sortedResults = _.sortBy(userResults, 'firstEventDate')
                            const lastMail = _.last(sortedResults)
                            const sendEvent = lastMail.events.find(object => object.event === 'request')
                            const deliveredEvent = lastMail.events.find(object => object.event === 'delivered')
                            const opened = lastMail.events.find(object => object.event === 'unique_opened')
                            const proxyOpen = lastMail.events.find(object => object.event === 'unique_proxy_open')
                            const clickedEvent = lastMail.events.find(object => object.event === 'click')
                            const blockedEvent = lastMail.events.find(object => object.event === 'blocked')
                            const hardBounceEvent = lastMail.events.find(object => object.event === 'hard_bounce')
                            const numberOfLaunches =  userResults.length - 1
                            return {
                                id: lastMail.messageId,
                                recipient: user.name,
                                sent: sendEvent && moment(sendEvent.date).format('YYYY-MM-DD HH:mm:ss'),
                                delivered: deliveredEvent && moment(deliveredEvent.date).format('YYYY-MM-DD HH:mm:ss'),
                                opened: opened
                                    ? moment(opened.date).format('YYYY-MM-DD HH:mm:ss')
                                    : proxyOpen
                                        ? moment(proxyOpen.date).format('YYYY-MM-DD HH:mm:ss')
                                        :'',
                                clicked: clickedEvent && moment(clickedEvent.date).format('YYYY-MM-DD HH:mm:ss'),
                                blocked: !!blockedEvent || !!hardBounceEvent,
                                relaunches: numberOfLaunches === 0 ? '' : numberOfLaunches
                            }
                        }))
                    })
                }
            },
            {
                path: "buttons",
                fieldPath: ['status'],
                $f: function(object, context, callback) {
                    callback(
                        null,
                        object.status === 'draft'
                            ? [diffuseButton, saveButton, returnButton]
                            :  object.status === 'diffused'
                                ? [relaunchButton, saveButton, returnButton]
                                : [returnButton]
                    )
                }
            },
            {
                path: "code", unique: true, ps: {
                    object: [{
                        type: "nextSequence",
                        sequenceId: "f.surveySeq",
                        formatResult: result => `${result}`
                    }]
                }
            },
            {
                path: 'surveyTitle',
                tKey: 'title',
                fieldPath: ['surveyConf.tTitle'],
                f: function(){
                    return _.get(this, 'surveyConf.tTitle')
                }
            },
            {
                path: 'numberOfResponses',
                $f: function (survey, context, callback){
                    global.app[context.model.id].SurveyResult.collection.find({
                        survey: new global.ObjectID(survey.id)
                    }).toArray((e, surveyResults) => {
                        if(e) return callback(e)
                        let results = 0
                        const validated = surveyResults.reduce((acc, result) => {
                            results++
                            return result.status === 'validated'
                                ? acc + 1
                                : acc
                        }, 0)

                        callback(null, `${validated}/${results}`)
                    })
                }
            },
            {
                path: 'responsesRate',
                fieldPath: ['numberOfResponses'],
                $f: function (survey, context, callback){
                    if(!survey.numberOfResponses) return callback(null, '0%')
                    const [validated, total] = survey.numberOfResponses.split('/').map(elem => Number.parseInt(elem))
                    const rate = total !== 0 ? _.round(validated * 100 / total): 0
                    return callback(null, rate + '%')
                }
            },
            {
                path: 'analysisTable',
                $f: function (survey, context, callback){
                    global.app[context.model.id].SurveyResult.find({
                        ...basicContext(context),
                        fieldPath: [
                            'title',
                            'responses'
                        ],
                        query: {
                            survey: new global.ObjectID(survey.id)
                        }
                    }, (e, surveyResults) => {
                        if(e) return callback(e)
                        const analysisTable = survey.surveyConf.questions
                            .filter(question => ['radioButton', 'cursor', 'multiCheckbox'].includes(_.get(question, 'surveyType.id')))
                            .map(question => ({
                                responses: surveyResults.reduce((acc, surveyResult) => {
                                    if(surveyResult.status === 'ongoing') return acc
                                    const response = surveyResult.responses.find(response => {
                                        return _.get(response, 'surveyQuestion.id') === question.id
                                    })

                                    switch (_.get(question, 'surveyType.id')){
                                        case 'radioButton':
                                        case 'cursor':
                                            acc[response.responseElement.id].result++
                                            break
                                        case 'multiCheckbox':
                                            response.responseElements.forEach(element => {
                                                acc[element.id].result++
                                            })
                                            break;
                                    }
                                    return acc

                                }, question.responseSet.responseElements.reduce((acc, element) => ({...acc, [element.id]: {name: element.name, result: 0} }) , {})),
                                question: question.wording
                            }))
                        callback(null, analysisTable)
                    })
                }
            }
        ],
        ps: {
            context: [{
                $$u: function (context, callback) {
                    if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                        context.internalFieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "code"
                            ])
                        ]
                    }
                    callback(null, context)
                }
            }]
        },
        handleSurveyResults: async function(newObject, context) {
            console.log('creating survey results')
            const surveyResults = newObject.target.map(user => ({
                user: new global.ObjectID(user.id),
                survey: new global.ObjectID(newObject.id),
                status: 'ongoing',
                group: new global.ObjectID(context.group.id)
            }))

            const result = await global.app[context.model.id].SurveyResult.collection.insertMany(surveyResults)
            return result.insertedIds
        },
        handleSurveyResponses: async function(surveyResultIds, newObject, context) {
            console.log('creating survey responses')
            const surveyResponses = _.flatMap(surveyResultIds, id => {
                return newObject.surveyConf.questions.map(question => ({
                    surveyResult: id,
                    surveyQuestion: new global.ObjectID(question.id),
                    response: null,
                    group: new global.ObjectID(context.group.id)
                }))
            })

            return await global.app[context.model.id].SurveyResponse.collection.insertMany(surveyResponses);
        },
        getClientSignature: async function() {
            const readFile = util.promisify(fs.readFile)
            return await readFile(path.join(mailerPath, `/templates/signatures/${global.config.client}.html`), "utf8")

        },
        addSurveyRequestEmailsToQueue: async function(newObject, context, callback) {
            console.log('sending survey emails')
            let clientSignature

            try {
                clientSignature = await this.getClientSignature()
            } catch (e) {
                console.log(e)
            }

            const usersList = context.action === 'relaunch'
                ? newObject.remainingUsers
                : newObject.target

            if(!usersList.length) return callback(null, [])

            const mails = usersList.map( user => {

                const userLanguage = _.get(user, 'language.id', 'fr')

                let correspondingAccompanyingMessage =  newObject.surveyConf.accompanyingMessage.find(accMessage => accMessage.language.id === userLanguage)

                if(!correspondingAccompanyingMessage) correspondingAccompanyingMessage = newObject.surveyConf.accompanyingMessage[0]

                return {
                    to: user.mail && decrypt(user.mail),
                    content: `${userLanguage}_surveyRequest.html`,
                    context: {
                        firstname : user.firstname,
                        lastname : user.lastname,
                        civility : context.tl(_.get(user, 'civility.id'), userLanguage),
                        title: context.translateName(newObject.surveyConf.title, userLanguage),
                        description: correspondingAccompanyingMessage?.body,
                        link: global.isProd
                            ? `https://${context.host}/business/${context.groupModel.id}`
                            : `http://localhost:3000/business/${context.groupModel.id}`,
                        moduleId: `m-${context.model.id}-surveyResult`,
                        clientSignature
                    },
                    subject: context.translateName(newObject.surveyConf.title, userLanguage)
                }
            })

            let messageIds = []
            return global.app[context.model.id].EmailsQueue.collection.insertMany(
                mails.map(mail => {
                    const messageId = uuid()
                    messageIds.push(messageId)
                    return {
                        ...mail,
                        from: `"${context.group.alias}" <${context.group.noReply || "support@keenpoint.com"}>`,
                        replyTo: context.group.noReply || "support@keenpoint.com",
                        contentType: "template",
                        templateDir: path.join(global.appRoot, global.isProd ? 'buildServer/server/KpMailer' : 'src/server/KpMailer' , 'templates/survey'),
                        signature: false,
                        attachments: [],
                        messageId,
                        delayedSending: true,
                        date: moment().add(2, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                        group: global.ObjectID(context.group.id),
                        creationDate: new Date(),
                        status: "waiting"
                    }
                }),
                (e) => callback(e, messageIds)
            )
        },
        updateSurvey: async function(newObject, context, query) {
            return await global.app[context.model.id].Survey.collection.updateOne(
                { _id: global.ObjectID(newObject.id) },
                {$set: query},
            )
        },
        getUpdatedSurveyStatus: async function(newObject, context) {
            const survey = await global.app[context.model.id].Survey.collection.findOne({
                _id: global.ObjectID(newObject.id) }
            )
            return survey.status
        },
        beforeSave: async function (newObject, oldObject, context, callback) {
            const crudType  = context.restAction && context.restAction.crudType
            const action = context.action

            if(crudType === 'C') newObject.status = 'draft'
            if(newObject.closed) newObject.status = 'closed'

            if (action === "save") return callback(null, newObject, oldObject)
            if (action === 'relaunch') {
                const relaunchDate = new Date()
                return this.addSurveyRequestEmailsToQueue(newObject, context, (error, messageIds) => {
                    if(error) {
                        console.log("error sending survey notification emails", error)
                        return callback(error)

                    }
                    newObject.mails = newObject.mails.concat(messageIds)
                    newObject.relaunchDate = relaunchDate
                    return callback(null, newObject, oldObject)
                })
            }

            if (action === 'diffuse' && newObject.status === 'draft') {
                const surveyResultIds = await this.handleSurveyResults(newObject, context)
                await this.handleSurveyResponses(surveyResultIds, newObject, context)
                return this.addSurveyRequestEmailsToQueue(newObject, context, (error, messageIds) => {
                    if(error) {
                        console.log("error sending survey notification emails", error)
                        return callback(error)
                    }
                    newObject.mails = messageIds
                    newObject.status = "diffused"
                    newObject.diffusionDate = new Date()

                    return callback(null, newObject, oldObject)

                    /*
                    return emitFetchEditObjectSuccess({
                        ...newObject,
                        status: updatedSurveyStatus,
                        diffusionDate: currentDate,
                        responsesRate: '0%',
                        numberOfResponses:  `0/${newObject.target.length}`
                    }, context)
                     */
                })
            }


            return callback(null, newObject, oldObject)
        }
    },
    {
        name: 'SurveyReport',
        facets: [{name: "files", path: "file", linkType: "OTO"}],
        fields: [
            'title',
            'Survey',
            {path: 'anonymize', type: 'boolean'},
            {path: 'date', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true}
        ],
        beforeSave: function (newObject, oldObject, context, callback) {
            const action  = context.restAction && context.restAction.crudType

            if(action !== 'C') return callback(null, newObject, oldObject)

            newObject.date = new Date()

            generateSurveyReport(newObject, context)
                .then(file => {
                    newObject.file = file
                    return callback(null, newObject, oldObject)
                })
                .catch(e => callback(e))
        }
    },
    {
        name: 'SurveyResult',
        fields: [
            'User',
            {type: 'Survey', link: { oppositeField: { link: { deleteType: 'cascade' } } }},
            {path: 'responses',type: "SurveyResponse", link: 'OTM'},
            'status',
            {
                path: "title",
                fieldPath: ['survey.surveyConf.title'],
                $f: function(object, context, callback) {
                    callback(null, _.get(object, 'survey.surveyConf.title'))
                }
            }
        ],
        filters: [
            {
                name: 'byUser',
                isDefault: false,
                query: context => {
                    return {user: new global.ObjectID(context.user.id)}
                }
            },
            {
                name: 'isAvailable',
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    const now = new Date();
                    const normalizedDateUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
                    global.app[context.model.id].Survey.find({
                        ...basicContext(context),
                        fieldPath: ['id'],
                        query: {
                            start: {$lte: normalizedDateUTC},
                            end: {$gte: normalizedDateUTC},

                        }
                    }, (e, surveys) => {
                        if(e) return callback(e)
                        callback(null, {
                            survey: {
                                $in: surveys.map(
                                    survey => new global.ObjectID(survey.id)
                                )
                            }
                        })
                    })
                }
            }
        ],
        get: function(id, context, callback) {
            this.prepareContext(context, 'R', (error, context) => {
                if (error) callback(error)
                else {
                    global.app[context.model.id].SurveyResult.find({
                            ...basicContext(context),
                            fieldPath: [
                                'title',
                                'responses',
                                'responses.responseElement',
                                'survey.start',
                                'survey.end',
                                'survey.surveyConf.accompanyingMessage.language.id',
                                'survey.surveyConf.accompanyingMessage.body',
                                'survey.surveyConf.accompanyingMessage.validationMessage',
                                'survey.surveyConf.accompanyingMessage.messageForLatecomers',
                                'survey.surveyConf.questions.id',
                                'survey.surveyConf.questions.wording',
                                'survey.surveyConf.questions.subtitle',
                                'survey.surveyConf.questions.width.id',
                                'survey.surveyConf.questions.minWidth',
                                'survey.surveyConf.questions.minValue',
                                'survey.surveyConf.questions.required',
                                'survey.surveyConf.questions.maxValue',
                                'survey.surveyConf.questions.surveyType',
                                'survey.surveyConf.questions.responseSet.responseElements',
                                'survey.surveyConf.questions.dependentOnQuestion.id',
                                'survey.surveyConf.questions.dependantOnResponseElement.id'
                            ],
                            query: {
                                _id: new global.ObjectID(id)
                            }
                        }, (e, surveyResults) => {
                            if(e) return callback(e)
                            const surveyResult = surveyResults[0]
                            let accMessage
                            accMessage = surveyResult.survey.surveyConf.accompanyingMessage.find(accMess => accMess.language.id === context.language.id)
                            if(!accMessage) accMessage = surveyResult.survey.surveyConf.accompanyingMessage[0]

                            const toDay = new Date()
                            toDay.setUTCHours(0,0,0,0)
                            if(surveyResult.status !== 'validated' && (toDay < surveyResult.survey.start || toDay > surveyResult.survey.end)) {
                                return callback(new Errors.ValidationError(accMessage?.messageForLatecomers || 'surveyIsClosed'))
                            }

                            callback(null, {
                                id: surveyResult.id,
                                title: surveyResult.title,
                                description: accMessage?.body,
                                validationMessage: accMessage?.validationMessage,
                                status: surveyResult.status,
                                survey: surveyResult.survey,
                                questions: surveyResult.survey.surveyConf.questions.reduce((acc, surveyQuestion) => {
                                    const response = surveyResult.responses.find(
                                        response => surveyQuestion.id === _.get(response, 'surveyQuestion.id')
                                    )
                                    if(!response) return acc
                                    const surveyType = _.get(surveyQuestion, 'surveyType.id')
                                    let value = null
                                    let options = null
                                    switch(surveyType){
                                        case 'labeledText':
                                        case 'boundedInteger':
                                        case 'boundedNumber':
                                            value = response.response
                                            break
                                        case 'labeledCheckbox':
                                            value = response.responseBoolean
                                            break
                                        case 'cursor':
                                            value = _.get(response, 'responseElement.id')
                                            options = _.get(surveyQuestion, 'responseSet.responseElements')
                                            break
                                        case 'radioButton':
                                            value = {
                                                id: _.get(response, 'responseElement.id'),
                                                detailedResponse: response.detailedResponse
                                            }
                                            options = _.get(surveyQuestion, 'responseSet.responseElements')
                                            break
                                        case 'multiCheckbox':
                                            const detailedOption = response.detailedOption && response.responseElements.find(elem => elem.id === response.detailedOption)
                                            value = response.responseElements.map(elem => {
                                                if(elem.id === detailedOption?.id) return {id: elem.id, detailedResponse: response.detailedResponse}
                                                return elem
                                                }) || []
                                            options = _.get(surveyQuestion, 'responseSet.responseElements')
                                            break
                                        case 'rankOptions':
                                            value = response.responseElements.map(el => el.id)
                                            options = _.get(surveyQuestion, 'responseSet.responseElements')
                                            break
                                        case 'boundedIntegerInDropdown':
                                            value = response.response
                                            const minValue = parseInt(surveyQuestion.minValue)
                                            const maxValue = parseInt(surveyQuestion.maxValue)

                                            options = _.range(minValue, maxValue + 1).map(value => {
                                                return {
                                                    id: value,
                                                    name: value
                                                }
                                            })
                                            break
                                    }

                                    const field = {
                                        path: surveyQuestion.id,
                                        responseId: response.id,
                                        label: surveyQuestion.wording,
                                        subtitle: surveyQuestion.subtitle,
                                        width: _.get(surveyQuestion, 'width.id', "12"),
                                        minWidth: surveyQuestion.minWidth,
                                        type: _.get(surveyQuestion, 'surveyType.id'),
                                        required: surveyQuestion.required,
                                        dependentOnQuestion: _.get(surveyQuestion, 'dependentOnQuestion.id'),
                                        dependantOnResponseElement: surveyQuestion.dependantOnResponseElement,
                                        default: value,
                                        options,
                                        writable: true, editable: true, disabled: false,
                                    }
                                    if(['boundedInteger', 'boundedNumber'].includes(surveyType)) {
                                        field.minValue = surveyQuestion.minValue
                                        field.maxValue = surveyQuestion.maxValue
                                    }

                                    if('boundedInteger' === surveyType) {
                                        field.wholeNumber = true
                                    }

                                    return {
                                        ...acc,
                                        [surveyQuestion.id]: field
                                    }
                                }, {}),
                                buttons: surveyResult.status
                                    ? [{...validationButton, tKey: 'validateAndSend'}, returnButton]
                                    : [returnButton]
                            })
                        }
                    )
                }
            })
        },
        save: function(newObject, context, callback) {
            this.prepareContext(context, 'S', (error) => {
                if (error) callback(error)
                else {
                    async.parallel(
                        [
                            callback => global.app[context.model.id].SurveyResult.collection.updateOne(
                                {_id: new global.ObjectID(newObject.id)},
                                {
                                    $set: {
                                        status: context.action === 'validate'
                                            ? 'validated'
                                            : newObject.status
                                    }
                                },
                                callback
                            ),
                            ...Object.keys(newObject.questions).map(key => newObject.questions[key]).map(question => callback => {
                                let query = {}
                                switch(question.type){
                                    case 'labeledText':
                                    case 'boundedInteger':
                                    case 'boundedNumber':
                                    case 'boundedIntegerInDropdown':
                                        query = {response: question.value}
                                        break
                                    case 'labeledCheckbox':
                                        query = {responseBoolean: question.value}
                                        break
                                    case 'radioButton':
                                        const responseId = _.get(question, 'value.id')
                                        if(responseId) {
                                            const detailedResponse = _.get(question, 'value.detailedResponse')
                                            query = {
                                                responseElement: new global.ObjectID(responseId),
                                                detailedResponse
                                            }
                                        }
                                        break;
                                    case 'cursor':
                                        query = {responseElement: new global.ObjectID(question.value)}
                                        break
                                    case 'multiCheckbox':
                                        const detailedValue = question.value.find(value => value.detailedResponse)
                                        query = {
                                            responseElements: question.value.map(el => new global.ObjectID(el.id)),
                                            detailedOption:  detailedValue?.id,
                                            detailedResponse: detailedValue?.detailedResponse
                                        }
                                        break
                                    case 'rankOptions':
                                        query = {responseElements: question.value.map(el => new global.ObjectID(el))}
                                        break

                                }
                                global.app[context.model.id].SurveyResponse.collection.updateOne(
                                    {_id: new global.ObjectID(question.responseId)},
                                    {$set: query},
                                    callback
                                )
                            })
                        ],
                        () => {
                            callback(null, {
                                ...newObject,
                                status: context.action === 'validate'
                                    ? 'validated'
                                    : newObject.status,
                                title: newObject.survey.surveyConf.title
                            })
                            if(newObject.validationMessage && context.action === 'validate' ) {
                                global.ioSocket.emit(
                                    `toastrMessage-${context.user.id}`,
                                    newObject.validationMessage
                                )
                            }
                        }
                    )
                }
            })
        }
    },
    {
        name: 'SurveyResponse',
        fields: [
            'SurveyQuestion',
            {path: 'responseElement', type: 'ResponseElement'},
            {path: 'responseElements', type: 'ResponseElement', link: 'MTM'},
            {path: 'responseBoolean', type: 'boolean'},
            {path: 'responseText'},
            {path: 'detailedResponse'}
        ]
    }
]

export const modules = modelId =>[
    {
        tKey: 'm-T-ResponseSet',
        object: 'ResponseSet',
        displayLog: false,
        defaultSortBy: 'name',
        defaultSortDirection: 'ASC',
        category: {
            path: 'survey',
            icon: 'list'
        },
        viewMap: {
            dt: ['name', {path: 'noDeleteButtonAccess', hidden: true}],
            form: [
                'name',
                {
                    path: 'responseElements',
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: 'name', tKey: 'responseElement', type: 'translatedText'},
                            'coefficient',
                            {path: 'noDeleteButtonAccess', hidden: true}
                        ],
                        form: [
                            {path: 'name', tKey: 'responseElement', type: 'textarea', placeholder: 'fr:: name...', required: true},
                            {
                                path: 'hasDetailedResponse',
                                subscriptions: {
                                    onChange: (newValue, oldValue, {store}) =>
                                        store.dispatch(setFieldVisibility('e_responseElements.e_detailedResponsePlaceholder', !!newValue))
                                }
                            },
                            {path: 'detailedResponsePlaceholder'},
                            {path: 'coefficient', default: 1, required: true},
                        ]
                    }
                }
            ]
        },
        actionSubscriptions: [
            {
                actionType: GET_OBJECT_SUCCESS,
                subscription: ({ store }) => {
                    const state = store.getState()

                    const noDeleteButtonAccess = _.get(state, 'edit.object.data.noDeleteButtonAccess')


                    store.dispatch(setFieldEdition('e_responseElements.e_name', !noDeleteButtonAccess))
                    store.dispatch(setFieldEdition('e_responseElements.e_hasDetailedResponse', !noDeleteButtonAccess))
                    store.dispatch(setFieldEdition('e_responseElements.e_detailedResponsePlaceholder', !noDeleteButtonAccess))
                }
            }
        ],
    },
    {
        tKey: 'm-T-SurveyConf',
        object: 'SurveyConf',
        displayLog: false,
        defaultSortBy: 'title',
        defaultSortDirection: 'ASC',
        category: {
            path: 'survey',
            icon: 'list'
        },
        viewMap: {
            dt: [{path: 'title', type: 'translatedText'}],
            form: [
                {path: 'title', type: 'textarea', placeholder: 'fr:: title...'},
                {
                    path: 'accompanyingMessage',
                    required: true,
                    removable: true,
                    defaultSortBy: 'language',
                    defaultSortDirection: 'ASC',
                    viewMap: {
                        dt: [
                            {path: 'language'}
                        ],
                        form: [
                            {path: 'language'},
                            {path: 'body', tKey: 'messageBody', type: 'richTextEditor'},
                            {path: 'validationMessage'},
                            {path: 'messageForLatecomers'}
                        ]
                    }

                },
                {
                    path: 'questions',
                    tKey: 'survey',
                    removable: true,
                    sortableTable: true,
                    viewMap: {
                        dt: ['section', {path: 'wording', type: 'translatedText'}, {path: 'surveyType', tKey: 'style'}, {path: 'noDeleteButtonAccess', hidden: true}],
                        form: [
                            {
                                path: 'section',
                                required: true,
                                default: {id: 'question'},
                                subscriptions: {
                                    onChange: (newValue, oldValue, { module, store, formInitialize, getObjectSuccessAction }) => {
                                        const selectionIsQuestion = newValue && newValue.id === 'question'
                                        store.dispatch(setFieldVisibility('e_questions.e_responseSet', selectionIsQuestion))
                                        store.dispatch(setFieldVisibility('e_questions.e_minValue', selectionIsQuestion))
                                        store.dispatch(setFieldVisibility('e_questions.e_maxValue', selectionIsQuestion))
                                        store.dispatch(setFieldVisibility('e_questions.e_required', selectionIsQuestion))
                                        store.dispatch(setFieldVisibility('e_questions.e_coefficient', selectionIsQuestion))
                                        store.dispatch(setFieldVisibility('e_questions.e_width', selectionIsQuestion))
                                        store.dispatch(setFieldVisibility('e_questions.e_dependentOnQuestion', selectionIsQuestion))
                                        store.dispatch(
                                            generateFetchFieldListAction(
                                                `m-${module.model}-surveyConf.SurveyQuestion_surveyType`,
                                                store.getState,
                                                'form',
                                                { data: {type: newValue} }
                                            )
                                        )
                                    }
                                }
                            },
                            { path: 'wording', type: 'textarea', placeholder: 'fr:: wording...', required: true },
                            { path: 'subtitle', tKey: 'subtitle2', type: 'textarea', placeholder: 'fr:: wording...' },
                            {
                                path: 'surveyType',
                                tKey: 'style',
                                filters: ['bySectionType'],
                                subscriptions: {
                                    onChange: (newValue, oldValue, { store }) => {
                                        const visible = newValue && ['radioButton', 'cursor', 'multiCheckbox', 'rankOptions'].includes(newValue.id)
                                        store.dispatch(setFieldVisibility('e_questions.e_responseSet', visible))
                                        store.dispatch(setFieldVisibility('e_questions.e_coefficient', visible))

                                        const visible2 = newValue && ['boundedInteger', 'boundedNumber', 'boundedIntegerInDropdown'].includes(newValue.id)
                                        store.dispatch(setFieldVisibility('e_questions.e_minValue', visible2))
                                        store.dispatch(setFieldVisibility('e_questions.e_maxValue', visible2))
                                    }
                                }
                            },
                            {path: 'responseSet', fieldPath: ['id', 'name', 'responseElements.id'], required: true},
                            {path: 'minValue', tKey: "lowerLimit", default: 0, required: true},
                            {path: 'maxValue', tKey: 'upperLimit', default: 0, required: true},
                            {path: 'required', default: false},
                            {path: 'coefficient', default: 1, required: true},
                            {path: 'width', tKey: 'usedWidth', default: {id: "12"}, required: true},
                            //'minWidth',
                            {path: 'dependentOnQuestion', sortList: false, display: 'wording', fieldPath: ['id', 'wording', 'responseSet.responseElements.id'], nullable: true, translateName: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, { store }) => {
                                        const options = _.get(newValue, 'responseSet.responseElements')
                                            ? newValue.responseSet.responseElements.map(responseElement => responseElement.id)
                                            : []
                                        store.dispatch(setFieldVisibility('e_questions.e_dependantOnResponseElement', !!newValue))
                                        store.dispatch(setFieldListOptions('e_questions.e_dependantOnResponseElement', options))
                                    }
                                }

                            },
                            {path: 'dependantOnResponseElement', sortList: false, translateName: true, hidden: true}
                        ]
                    },
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            if(newValue) {
                                store.dispatch(
                                    setDataList(
                                        `m-${module.model}-surveyConf.SurveyQuestion_dependentOnQuestion`,
                                        newValue.filter(question => _.get(question, 'section.id') === 'question').map(o => _.pick(o, ['id', 'wording', 'responseSet']))
                                    )
                                )
                            }
                        }
                    }
                },
                {path: 'usedInDiffusedSurvey', hidden: true}
            ]
        },
        actionSubscriptions: [
            {
                actionType: GET_OBJECT_SUCCESS,
                subscription: ({ store, module }) => {
                    const state = store.getState()

                    const questions = _.get(state, 'edit.object.data.questions')
                    store.dispatch(
                        setDataList(
                            `m-${module.model}-surveyConf.SurveyQuestion_dependentOnQuestion`,
                            questions
                                .filter(question => {
                                    return _.get(question, 'section.id') === 'question' && ['radioButton', 'multiCheckbox', 'cursor'].includes(_.get(question, 'surveyType.id'))
                                })
                                .map(o => _.pick(o, ['id', 'wording', 'responseSet']))
                        )
                    )
                    const usedInDiffusedSurvey = _.get(state, 'edit.object.data.usedInDiffusedSurvey')
                    store.dispatch(setFieldEdition('e_questions.e_section', !usedInDiffusedSurvey))
                    store.dispatch(setFieldEdition('e_questions.e_wording', !usedInDiffusedSurvey))
                    store.dispatch(setFieldEdition('e_questions.e_subtitle', !usedInDiffusedSurvey))
                    store.dispatch(setFieldEdition('e_questions.e_surveyType', !usedInDiffusedSurvey))
                    store.dispatch(setFieldEdition('e_questions.e_responseSet', !usedInDiffusedSurvey))
                    store.dispatch(setFieldEdition('e_questions.e_minValue', !usedInDiffusedSurvey))
                    store.dispatch(setFieldEdition('e_questions.e_maxValue', !usedInDiffusedSurvey))
                    store.dispatch(setFieldEdition('e_questions.e_coefficient', !usedInDiffusedSurvey))
                    store.dispatch(setFieldEdition('e_questions.e_dependentOnQuestion', !usedInDiffusedSurvey))
                    store.dispatch(setFieldEdition('e_questions.e_dependantOnResponseElement', !usedInDiffusedSurvey))
                }
            }
        ],
    },
    {
        object: "SurveyPrototype",
        tKey: 'm-T-SurveyPrototype',
        category: {
            path: 'survey',
            icon: 'list'
        },
        newable: false,
        removable: false,
        displayLog: false,
        viewMap: {
            dt: [
                {path: 'title', type: 'translatedText'}
            ],
            form: {
                fields: [
                    {path: 'title', type: 'heading', fullWidth: true},
                    {path: 'description', tKey: 'surveyGoal', type: 'richTextEditor', readOnly: true, style: {backgroundColor: "#e7f7fe7d", padding: '10px'}},
                    {path: 'questions', tKey: 'Questionnaire', type: 'survey', required: true, fullWidth: true},
                    {path: 'buttons', hidden: true}
                ]
            }
        }
    },
    {
        object: 'Survey',
        name: "generateSurvey",
        tKey: "m-T-GenerateSurvey",
        defaultSortBy: 'code',
        defaultSortDirection: 'ASC',
        displayLog: false,
        category: {
            path: 'survey',
            icon: 'list'
        },
        viewMap: {
            dt: [
                'code',
                {path: 'surveyTitle', tKey: 'title'},
                {path: 'status', translate: true},
                'start', 'end',
                {path: 'responsesRate', tKey: 'rate'},
                {path: 'numberOfResponses'},
                {path: 'diffusionDate', tKey: 'diffusion'},
                {path: 'relaunchDate', tKey: 'relaunch'},
                {path: 'noDeleteButtonAccess', hidden: true}
            ],
            form: {
                fields: [
                    {
                        path: "broadcastLists",
                        fieldPath: ['id', ...fieldsByModel(modelId).entityFields.map(field => field.path)],
                        subscriptions: {
                            onChange: (newValue, oldValue, { module, store, formInitialize, getObjectSuccessAction }) => {
                                const state = store.getState()
                                const objectMode = state.ui.objectMode
                                if(!formInitialize && !getObjectSuccessAction && objectMode === 'newObject') {
                                    const recipientsField = module.viewMap.form.fields.find(
                                        field => field.path === 'target'
                                    )

                                    const list = state.dataLists.byId[`m-${module.model}-generateSurvey.Survey_target`].list
                                    recipientsField.setValue(
                                        newValue && !!newValue.length
                                            ? list.allIds
                                                .filter(id => {
                                                    const kpUser = list.byId[id]
                                                    return newValue.some(broadcastList => broadcastList.users.some(user => {
                                                        return kpUser.id === user.kpUser.toString()
                                                    }))
                                                })
                                                .map(id => list.byId[id])
                                            : []
                                    )
                                }
                            }
                        }
                    },
                    {
                        path: 'target',
                        tKey: 'participants',
                        type: 'dualBoxList',
                        fieldPath: ['id', 'language.id', 'civility.id', 'mail', 'name', 'groupModelProfiles'],
                        filters: ['isActive', 'isInGroupModel', 'userKNotAllowed']
                    },
                    {
                        path: "mailsFollowup",
                        type: 'dtObjects',
                        fields: [
                            {path: 'recipient'},
                            {path: 'sent'},
                            {path: 'delivered'},
                            {path: 'opened'},
                            {path: 'clicked'},
                            {path: 'blocked', type: 'failureIndicator'},
                            {path: 'relaunches', width: 100, type : 'number', style: {textAlign: 'right'}}
                        ]
                    },
                    'start',
                    'end',
                    {path: 'removable', tKey: "isRemovable"},
                    {path: 'surveyConf', tKey: 'survey', display: 'tTitle', fieldPath: ['id', 'title', 'tTitle', 'accompanyingMessage.language.id', 'accompanyingMessage.body', 'questions']},
                    {path: 'mails', hidden: true},
                    {path: 'remainingUsers', hidden: true},
                    {path: 'buttons', hidden: true}
                ],
                onOpen: ({state, store}) => {
                    const objectMode = state.ui.objectMode
                    const status = _.get(state, 'edit.object.data.status')

                    if(objectMode === 'newObject') {
                        store.dispatch(
                            setFormButtons([diffuseButton, saveButton, returnButton])
                        )
                    }

                    const fields = ['e_surveyConf']

                    store.dispatch(setFieldVisibility('e_broadcastLists', objectMode === 'newObject' || status === 'draft'))
                    store.dispatch(setFieldVisibility('e_target', objectMode === 'newObject' || status === 'draft'))
                    store.dispatch(setFieldVisibility('e_mailsFollowup', objectMode !== 'newObject' && status !== 'draft'))

                    fields.forEach(
                        field => store.dispatch(
                            changeFieldDisabled(field, objectMode !== 'newObject' && status !== 'draft')
                        )
                    )
                }
            }
        }
    },
    {
        object: "SurveyResult",
        tKey: 'm-T-Survey',
        category: {
            path: 'survey',
            icon: 'list'
        },
        newable: false,
        removable: false,
        displayLog: false,
        viewMap: {
            dt: [
                {path: 'title', type: 'translatedText'},
                {path: 'status', translate: true}
            ],
            form: {
                fields: [
                    {path: 'title', type: 'heading', fullWidth: true},
                    {path: 'description', tKey: 'surveyGoal', type: 'richTextEditor', readOnly: true, style: {backgroundColor: "#e7f7fe7d", padding: '10px'}},
                    {path: 'questions', tKey: 'Questionnaire', type: 'survey', required: true, fullWidth: true},
                    {path: 'survey', fieldPath: ['id', 'surveyConf.title'], hidden : true},
                    {path: 'validationMessage', hidden: true},
                    {path: 'status', hidden: true}
                ],
            }
        },
        filters: ['byUser', 'isAvailable'],
        actionSubscriptions: [
            {
                actionType: GET_OBJECT_SUCCESS,
                subscription: ({ store }) => {
                    const state = store.getState()
                    const surveyStatus = _.get(state, 'edit.object.data.status')
                    console.log('surveyStatus', surveyStatus)
                    store.dispatch(changeFieldDisabled('e_questions', surveyStatus !== 'ongoing'))
                    store.dispatch(
                        setFormButtons(
                            surveyStatus !== 'ongoing'
                                ? [returnButton]
                                : [{...validationButton, tKey: 'validateAndSend'},  saveActionButton, returnButton]
                        )
                    )
                }
            }
        ]
    },
    {
        object: 'Survey',
        name: "AnalysisModule",
        tKey: "m-T-AnalysisModule",
        newable: false,
        removable: false,
        displayLog: false,
        category: {
            path: 'survey',
            icon: 'list'
        },
        viewMap: {
            dt: [
                'code',
                {path: 'surveyTitle', tKey: 'title'},
                {path: 'sent', width: 80},
                {path: 'delivered', width: 80},
                {path: 'opened', width: 80},
                {path: 'clicked', width: 80},
                'numberOfResponses'
            ],
            form: [
                {path: 'surveyTitle', tKey: 'title', type: 'readOnly'},
                {path: 'numberOfResponses', type: 'readOnly'},
                {
                    path: "mailsFollowup",
                    type: 'dtObjects',
                    fields: [
                        {path: 'recipients'},
                        {path: 'sent'},
                        {path: 'delivered'},
                        {path: 'opened'},
                        {path: 'clicked'}
                    ]
                },
                {path: 'résumés par question', type: 'separator'},
                {
                    path: 'analysisTable',
                    type: 'surveyResponse',
                    fullWidth: true,
                    fields: [
                        { path: 'question', tKey: 'questionWording', width: 500 },
                        { path: 'responses', dynamic: true }
                    ]
                },
                {path: 'surveyConf', fieldPath: ['questions'], hidden: true}
            ]
        }
    },
    {
        object: 'SurveyEvolution',
        tKey: 'm-T-evolutionModule',
        newable: false,
        removable: false,
        displayLog: false,
        category: {
            path: 'survey',
            icon: 'list'
        },
        viewMap: {
            dt: [
                {path: 'survey'},
                {path: 'user'},
                {path: 'results', width: 200, dynamic: true}
            ]
        },
        filters: [
            {
                path: 'surveyQuestion',
                object: 'SurveyQuestion',
                display: 'tWording',
                fieldPath: ['tWording'],
                client: true,
                clearable: true,
                filters: [
                    {
                        path: "surveyConf",
                        object: "SurveyConf",
                        tKey: 'survey',
                        display: "tTitle",
                        client: true,
                        clearable: true,
                        default: {id: null},
                        fieldPath: [
                            'questions.id',
                            'questions.tWording',
                            'questions.surveyType',
                            'questions.coefficient'
                        ],
                        query: context => {
                            const questions = _.get(context, 'clientContext.data.surveyConf.questions')

                            return {
                                _id: {
                                    $in: questions
                                        ? questions.map(question => global.ObjectID(question.id))
                                        : []
                                },
                                surveyType: {$in: ['radioButton', 'cursor', 'multiCheckbox', 'rankOptions']}
                            }
                        }
                    }
                ]

            },
        ]
    },
    {
        object: 'SurveyReport',
        name: 'm-T-reportModule',
        removable: true,
        defaultSortBy: 'date',
        defaultSortDirection: 'DESC',
        defaultFormButtons: [exportButton, returnButton],
        category: {
            path: 'survey',
            icon: 'list'
        },
        viewMap: {
            dt: [
                {path: 'title'},
                {path: 'survey', display: 'surveyTitle'},
                {path: 'date'},
                {path: 'file', fieldPath: ['id', 'filename', 'date', 'password']}
            ],
            form: [
                {path: 'title', editable: false},
                {path: 'survey', display: 'surveyTitle', fieldPath: ['id', 'surveyTitle', 'surveyConf.id'], editable: false},
                {path: 'anonymize', default: true}
            ]
        }
    }
]

export const jobs = () => []
