import _ from 'lodash'
import {setDataList, setFieldListOptions} from "../../../apps/KpModule/actions"
import {getDataListList} from "../../../apps/KpModule/selectors"
import Errors from "../../utils/Errors";

async function findDynamicDashboardElements(context) {
    const elements = await global.app[context.model.id].DynamicDashboardElement.collection.find({
        group: new global.ObjectID(context.group.id)
    }).toArray()
    const alertConfigurations = await global.app[context.model.id].AlertConfiguration.collection.find({
        _id: {$in: elements.map(element => _.get(element, 'params.alertConfiguration'))}
    }).toArray()
    const groupAxes = await global.app[context.model.id].GroupAxis.find({
        fieldPath: ['id', 'code', 'name'],
        group: context.group,
        query: {
            _id: {$in: elements.map(element => _.get(element, 'params.groupAxis'))},
        }
    })
    const timeWindows = await global.app[context.model.id].TimeWindow.objects
    const dashboardElementWidths = await global.app[context.model.id].DashboardElementWidth.objects
    return elements.map(element => ({
        id: element._id.toString(),
        name: element.name,
        width: dashboardElementWidths.find(widthObject => widthObject.id === element.width),
        alertConfiguration: alertConfigurations.find(alertConfiguration => alertConfiguration._id.toString() === element.params?.alertConfiguration.toString()),
        groupAxis: groupAxes.find(groupAxis => groupAxis.id === element.params?.groupAxis.toString()),
        timeWindow: timeWindows.find(timeWindow => timeWindow.id === element.params?.timeWindow)
    }))
}

async function deleteDynamicDashboardElement(id, context) {
    const element = await global.app[context.model.id].DynamicDashboardElement.collection.findOne({_id: new global.ObjectID(id)})
    await global.app[context.model.id].DynamicDashboardElement.collection.deleteOne({_id: new global.ObjectID(id)})
    return element
}

async function getDynamicDashboardElement(id, context) {
    const element = await global.app[context.model.id].DynamicDashboardElement.collection.findOne({_id: new global.ObjectID(id)})
    const alertConfId = _.get(element, 'params.alertConfiguration')
    const groupAxisId = _.get(element, 'params.groupAxis')
    const timeWindowId = _.get(element, 'params.timeWindow')
    const widthId = _.get(element, 'width')

    let alertConfiguration, groupAxis, timeWindow, width

    if(alertConfId) {
        alertConfiguration = await global.app[context.model.id].AlertConfiguration.get(alertConfId, {
            group: context.group,
            fieldPath: ['id', 'name', 'groupAxes.id', 'dashboardThemeKeysObjects']
        })
    }

    if(groupAxisId) {
        groupAxis = await global.app[context.model.id].GroupAxis.get(groupAxisId, {
            group: context.group,
            fieldPath: ['id', 'code', 'name']
        })
    }

    if(timeWindowId) {
        timeWindow = global.app[context.model.id].TimeWindow.objects.find(timeWindow => timeWindow.id === timeWindowId)
    }


    if(widthId) {
        width = global.app[context.model.id].DashboardElementWidth.objects.find(widthObject => widthObject.id === widthId)
    }

    return {
        id,
        name: element.name,
        alertConfiguration,
        timeWindow,
        groupAxis,
        width,
        indicators: element.keys.slice(1).map(key => ({
            themeKey: {id: key.path, name: key.name},
            type: {id: key.type},
            axis: {id: key.axis},
            label: key.tKey,
        }))
    }
}

async function saveDynamicDashboardElement(element, context) {
    const elemObjectId = new global.ObjectID(element.id)

    const seen = new Set()
    const hasDuplicates = element.indicators.some(indicator => {
        const themeId = _.get(indicator, 'themeKey.id')
        if (seen.has(themeId)) {
            return true
        }
        seen.add(themeId);
        return false
    })

    if (hasDuplicates) {
        throw new Errors.ValidationError(context.tc('duplicateElementsDetected'))
    }

    await global.app[context.model.id].DynamicDashboardElement.collection.updateOne(
        {_id: elemObjectId},
        {
            $set: {
                name: element.name,
                url: 'CustomDashboardElement',
                type: 'multiBarChart',
                width: element.width?.id,
                sum: false,
                keys: [
                    element.groupAxis.code,
                    ...element.indicators.map(indicator => ({
                        path: _.get(indicator, 'themeKey.id'),
                        name: _.get(indicator, 'themeKey.name'),
                        tKey: indicator.label,
                        axis: _.get(indicator, 'axis.id'),
                        type: _.get(indicator, 'type.id')
                    }))
                ],
                params: {
                    elementId: elemObjectId,
                    alertConfiguration: new global.ObjectID(element.alertConfiguration.id),
                    timeWindow: element.timeWindow.id,
                    groupAxis: new global.ObjectID(element.groupAxis.id)
                },
                group: new global.ObjectID(context.group.id),
            }
        },
        {upsert: true}
    )
    return {
        ...element,
        id: elemObjectId.toString()
    }
}
export const dependencies = [
    {
        name: "CustomThemeKey",
        fields: [
            "name"
        ]
    },
    {
        name: 'LeftRightAxis',
        type: 'static',
        fields: [
            {path: "id", type: 'string'},
            'name'
        ],
        objects: [
            {id: 'left', name: "left"},
            {id: 'right', name: "right"}
        ]
    },
    {
        name: 'RepresentationType',
        type: 'static',
        fields: [
            {path: "id", type: 'string'},
            'name'
        ],
        objects: [
            {id: 'line', name: "lineChart"},
            {id: 'bar', name: "barChart"}
        ]
    },
    {
        name: 'IndicatorRepresentation',
        fields: [
            {path: 'themeKey', type: 'CustomThemeKey'},
            {path: 'axis', type: 'LeftRightAxis'},
            {path: 'type', type: 'RepresentationType'},
            'label'
        ]
    },
    {
        name: 'DashboardElementWidth',
        type: 'static',
        fields: [
            {path: "id", type: 'integer'},
            'name'
        ],
        objects: [
            {id: 12, name: "1/1"},
            {id: 6, name: "1/2"},
            {id: 4, name: "1/3"},
            {id: 3, name: "1/4"}
        ]
    }
]

export const entity = {
    name: 'DashboardCreation',
    type: null,
    fields: [
        'name',
        {type: 'AlertConfiguration'},
        'TimeWindow',
        'GroupAxis',
        {path: 'width', type: 'DashboardElementWidth'},
        {path: "indicators", type: 'IndicatorRepresentation', link: 'OTM'}
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L')
            .then(context => findDynamicDashboardElements(context))
            .then(objects => callback(null, objects))
            .catch(error => callback(error))
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R')
            .then(context => getDynamicDashboardElement(id, context))
            .then(object => callback(null, object))
            .catch(error => callback(error))
    },
    save: function(newObject, context, callback) {
        this.prepareContext(context, 'S')
            .then(context => saveDynamicDashboardElement(newObject, context))
            .then(object => callback(null, object))
            .catch(error => callback(error))
    },
    delete: function(id, context, callback) {
        this.prepareContext(context, 'D')
            .then(context => deleteDynamicDashboardElement(id, context))
            .then(object => callback(null, object))
            .catch(error => callback(error))
    }
}


export const module_ = {
    object: 'DashboardCreation',
    tKey: 'mTitle_Dashboard',
    category: {
        path: 'setting',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            { path: 'name', tKey: 'title', translateName: true},
            { path: 'alertConfiguration', translateName: true },
            { path: "timeWindow", tKey: "period", display: "tKey", translate: true },
            { path: "groupAxis", tKey: "analysisMesh", display: "name", translateName: true },
            { path: 'width', display: 'name' }
        ],
        form: [
            {
                path: 'alertConfiguration', translateName: true, editable: false, fieldPath: ['id', 'name', 'groupAxes.id', 'dashboardThemeKeysObjects'],
                subscriptions: {
                    onChange: (newValue, oldValue, {store}) => {
                        if(!!newValue) {
                            const state = store.getState()
                            const dataList = getDataListList(state, "m-S-dashboardCreation.DashboardCreation_groupAxis")
                            //
                            const dataListIds = dataList.map(object => object.id)
                            const groupAxesIds = newValue.groupAxes.map(groupAxis => groupAxis.id)
                            store.dispatch(setFieldListOptions("e_groupAxis", dataListIds?.filter(id => groupAxesIds.includes(id))))
                            store.dispatch(setDataList(
                                "m-S-dashboardCreation.IndicatorRepresentation_themeKey",
                                [
                                    {id: 'number', name: 'numberOfAlerts'},
                                    ...newValue.dashboardThemeKeysObjects
                                ]
                            ))
                        }
                    }
                }
            },
            {path: 'name', tKey: 'title', type: 'textarea', placeholder: 'fr:: titre\nen:: title', required: true},
            { path: "timeWindow", tKey: "period", display: "tKey", translate: true, clearable: false, sortList: false, default: {id: "00000000000000000000000a"} },
            { path: "groupAxis", tKey: "analysisMesh", display: "name", fieldPath: ['id', 'code', 'name'], translateName: true, required: true, filters: ["alertAnalysisAxes"] },
            {
                path: "indicators",
                tKey: "elements",
                removable: true,
                required: true,
                viewMap: {
                    dt: [
                        {path: 'themeKey', tKey: "element", type: 'object', display: 'name'},
                        {path: 'label', tKey: 'legend', translateName: true},
                        {path: 'axis', tKey: 'axisPosition'},
                        'type'
                    ],
                    form: [
                        {path: 'themeKey', tKey: 'element', type: 'dropdownObject', translate: true},
                        {path: 'label', tKey: 'legend', type: 'textarea', placeholder: 'fr:: légende\nen:: legend', required: true},
                        {path: 'axis', tKey: 'axisPosition'},
                        'type'
                    ]
                }
            },
            { path: 'width', display: 'name' }
        ]
    }
}
